<template>
	<div class="add-beauty-form">
		<v-ons-toolbar modifier="noshadow transparent cover-content">
			<div class="left"></div>
			<div class="center">{{ $t("Beauty Wanted") }}</div>
			<div class="right"></div>
		</v-ons-toolbar>

		<div class="form-wrap">
			<div class="form-item">
				{{ $t("Do you want to report a place? Tell us where it is and what it is about!") }}
			</div>

			<div class="form-item">
				<button class="photo-btn" @click="launchFilePicker()" aria-label="add-photo">
					<img src="images/placeholder-photo.png" v-if="!image" alt="beauty-photo" />
					<img :src="image" v-if="image" alt="beauty-photo" />
				</button>
				<input
					type="file"
					accept="image/*"
					ref="file"
					:name="uploadFieldName"
					@change="onFileChange($event.target.name, $event.target.files)"
					style="display: none"
				/>
			</div>
			<div class="form-item copy-box">
				<div class="field">
					<label
						><input type="checkbox" name="checkbox" v-model="privacyCheck" />{{
							$t("I authorize Stendhapp for the treatment of this picture")
						}}</label
					>
				</div>
			</div>
			<div class="form-item">
				<div class="label">
					{{
						$t(
							"You just have to digit the name of the place you want to add on Stendhapp. Our staff will add information online after verification. Any information must follow our policy and mission."
						)
					}}
				</div>
				<div class="field">
					<input :placeholder="$t('Type the Name of the beauty') + '*'" v-model="name" />
				</div>
			</div>
			<div class="form-item cat-wrap">
				<div class="label">{{ $t("Choose a category") }}*</div>
				<div class="field cat-list-wrap">
					<div class="cat-list">
						<div class="btn-box" :class="{ active: c.key === catSelected }" v-for="c in catList" :key="c.key">
							<button @click="catSelected = c.key" aria-label="category">
								<img :src="'map-icons/' + c.key + '.png'" v-if="c.key === catSelected" alt="cat-image" />
								<img :src="'map-icons/' + c.key + '-bw.png'" v-if="c.key !== catSelected" alt="cat-image" />
							</button>
							<div class="btn-label">{{ $t(c.label) }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="form-item">
				<div class="label">
					{{ $t("Add description") }}
				</div>
				<div class="field">
					<textarea :placeholder="$t('Add a small description here')" v-model="description"></textarea>
				</div>
			</div>
			<div class="form-item address-box">
				<div class="label">{{ $t("Address") }}*</div>
				<div class="field flex">
					<input :placeholder="$t('Tab the address or pin location')" v-model="address" class="box grow" />
					<button @click="getCurrentPosition" aria-label="get current position" v-if="canGeolocation">
						<i class="mdi mdi-map-marker-radius"></i>
					</button>
				</div>
			</div>
			<div class="form-item">
				<div class="label">{{ $t("Is it accessible?") }}*</div>
				<div class="field accessible-box">
					<div class="image-box">
						<img src="images/accessible-icon.png" alt="accessible-icon" />
					</div>
					<button :class="{ active: accessible === true }" @click="accessible = true" aria-label="accessible yes">
						{{ $t("Yes") }}
					</button>
					<button :class="{ active: accessible === false }" @click="accessible = false" aria-label="accessible no">
						{{ $t("No") }}
					</button>
				</div>
			</div>
			<div class="form-item">
				<div class="label">{{ $t("Phone Number") }} ({{ $t("optional") }})</div>
				<div class="field">
					<input :placeholder="$t('Enter the related number')" v-model="phoneNumber" />
				</div>
			</div>
			<div class="form-item time-box">
				<div class="label">{{ $t("Insert Timings") }} ({{ $t("optional") }})</div>
				<div class="field">
					<span>{{ $t("From_h") }}</span>
					<select v-model="timeFrom">
						<option></option>
						<option v-for="(t, index) in timesList" :key="index" :disabled="timeTo && t >= timeTo">{{ t }}</option>
					</select>
					<span>{{ $t("To_h") }}</span>
					<select v-model="timeTo">
						<option></option>
						<option v-for="(t, index) in timesList" :key="index" :disabled="t <= timeFrom">{{ t }}</option>
					</select>
				</div>
			</div>
			<div class="form-item ticket-box">
				<div class="label">{{ $t("Ticket charge") }} ({{ $t("optional") }})</div>
				<div class="field flex">
					<input :placeholder="$t('Enter the amount')" class="box grow" v-model="ticketCost" />
					<button class="currency-btn" aria-label="currency-btn">
						<i class="mdi mdi-currency-eur"></i>
					</button>
				</div>
			</div>
			<div class="form-item">
				<div class="label">{{ $t("Choose an aka for this content") }}*</div>
				<div class="field">
					<input :placeholder="$t('Enter your name (User name)')" v-model.trim="fullName" />
				</div>
			</div>
			<div class="form-item">
				<div class="label">{{ $t("Source") }}*</div>
				<div class="field">
					<input :placeholder="$t('Specify the origin of the cited information')" v-model="source" />
				</div>
			</div>
			<!--<div class="form-item copy-box">
				<div class="field">
					<label><input type="checkbox" name="checkbox" v-model="privacyCheck">{{$t("Allow to use photographs of me add to Stendhapp")}}</label>
				</div>
			</div>-->
			<div class="form-item">
				<div class="label">*{{ $t("Required Fields") }}</div>
			</div>
			<div class="form-item submit-box flex">
				<div class="box grow">
					<button @click="send" :disabled="!isValid || !isPhotoValid" aria-label="send">{{ $t("Send") }}</button>
				</div>
				<div class="divider"></div>
				<div class="box grow">
					<button @click="addDraft" :disabled="!isDraftValid" aria-label="add draft">{{ $t("Save draft") }}</button>
				</div>
				<div class="divider"></div>
				<div class="box">
					<button @click="resetForm" class="alt not-grow" aria-label="reset form">{{ $t("Reset") }}</button>
				</div>
			</div>

			<div class="draft-wrapper">
				<div class="header flex justify-space-between align-center" @click="draftListOpen = !draftListOpen">
					<div class="title">{{ $t("Draft list") }}</div>
					<div class="icon">
						<i class="mdi mdi-chevron-up" v-if="draftListOpen"></i>
						<i class="mdi mdi-chevron-down" v-if="!draftListOpen"></i>
					</div>
				</div>
				<div class="draft-list" v-if="draftListOpen">
					<div v-for="item in draftList" :key="item.it" class="item flex">
						<div class="left box grow flex" @click="setForm(item)">
							<div class="box grow flex column" @click="setForm(item)">
								<div class="name">{{ item.name }}</div>
								<div class="datetime">{{ item.dateInsert | moment("DD/MM/YYYY HH:mm") }}</div>
							</div>
							<button aria-label="edit draft"><i class="mdi mdi-pencil"></i></button>
						</div>
						<div class="right box flex align-center">
							<button @click="delDraft(item.id)" aria-label="delete draft"><i class="mdi mdi-delete"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import api from "../api"
import undefsafe from "undefsafe"
import { Const } from "../const"
import moment from "moment-timezone"
import _ from "lodash"
import { EventBus } from "@/event-bus.js"

export default {
	name: "add-beauty-form",
	data() {
		const timesList = []
		for (let i = 0; i < 24; i++) {
			timesList.push((i < 10 ? "0" + i : i) + ":00")
			timesList.push((i < 10 ? "0" + i : i) + ":30")
		}

		return {
			draftListOpen: false,
			catList: [
				{
					key: "museums",
					label: "Museums"
				},
				{
					key: "church",
					label: "Church"
				},
				{
					key: "monuments",
					label: "Monuments"
				},
				{
					key: "buildings",
					label: "Buildings"
				},
				{
					key: "archeological",
					label: "Arch. Area"
				},
				{
					key: "art",
					label: "Art"
				},
				{
					key: "music",
					label: "Music"
				},
				{
					key: "theatre",
					label: "Theatre"
				},
				{
					key: "unesco",
					label: "Unesco"
				},
				{
					key: "literature",
					label: "Literature"
				},
				{
					key: "history",
					label: "History"
				},
				{
					key: "nature",
					label: "Nature"
				},
				{
					key: "food",
					label: "Food"
				},
				{
					key: "wine",
					label: "Wine"
				},
				{
					key: "festivals",
					label: "Festivals"
				},
				{
					key: "cinema",
					label: "Cinema"
				},
				{
					key: "villages",
					label: "Villages"
				},
				{
					key: "stores",
					label: "Stores"
				},
				{
					key: "design",
					label: "Design"
				}
			],
			uploadFieldName: "file",
			maxWidth: 800,
			maxHeight: 800,
			timesList,
			photodir: Const.photodir,
			tempdir: Const.tempdir,
			BASE_URL: api.getBaseUrl(),
			DOMAIN_URL: api.getDomainUrl(),
			navigator: navigator,

			image: "",
			name: "",
			catSelected: null,
			description: "",
			address: "",
			latitude: "",
			longitude: "",
			accessible: null,
			phoneNumber: "",
			timeFrom: null,
			timeTo: null,
			ticketCost: "",
			source: "",
			privacyCheck: false,
			fullName: ""
		}
	},
	computed: {
		draftList() {
			return this.$store.getters.draftList
		},
		isPhotoValid() {
			if (this.image) {
				if (this.privacyCheck) {
					return true
				} else {
					return false
				}
			} else {
				return true
			}
		},
		isValid() {
			const patt = new RegExp(/([a-zA-Z]{2,})/gim)
			const res = patt.test(this.fullName)

			if (!res) {
				return false
			}

			if (
				this.name &&
				this.catSelected &&
				//this.description &&
				this.address &&
				this.source &&
				this.fullName &&
				this.accessible !== null
				//this.privacyCheck
			) {
				return true
			} else {
				return false
			}
		},
		isDraftValid() {
			if (this.name && this.address && this.accessible !== null) {
				return true
			} else {
				return false
			}
		},
		canGeolocation() {
			return this.$store.getters.canGeolocation
		}
	},
	methods: {
		launchFilePicker() {
			this.$refs.file.click()
		},
		onFileChange(fieldName, file) {
			//const { maxSize } = this
			let imageFile = file[0]

			//check if user actually selected a file
			if (file.length > 0) {
				/*let size = imageFile.size / maxSize / maxSize
				if (!imageFile.type.match('image.*')) {
					// check whether the upload is an image
					this.errorDialog = true
					this.errorText = 'Please choose an image file'
				} else if (size>1) {
					// check whether the size is greater than the size limit
					this.errorDialog = true
					this.errorText = 'Your file is too big! Please select an image under 1MB'
				} else {
					// Append file into FormData & turn file into image URL
					let imageURL = URL.createObjectURL(imageFile)
					// Emit FormData & image URL to the parent component
				}*/

				const reader = new FileReader()
				reader.readAsDataURL(imageFile)
				reader.onload = () => {
					//console.log(this.image);
					const img = new Image()

					img.onload = () => {
						const canvas = document.createElement("canvas")
						let ctx = canvas.getContext("2d")
						ctx.drawImage(img, 0, 0)

						const MAX_WIDTH = this.maxWidth
						const MAX_HEIGHT = this.maxHeight
						let width = img.width
						let height = img.height

						if (width > height) {
							if (width > MAX_WIDTH) {
								height *= MAX_WIDTH / width
								width = MAX_WIDTH
							}
						} else {
							if (height > MAX_HEIGHT) {
								width *= MAX_HEIGHT / height
								height = MAX_HEIGHT
							}
						}
						canvas.width = width
						canvas.height = height
						ctx = canvas.getContext("2d")
						ctx.drawImage(img, 0, 0, width, height)

						//console.log(canvas)

						const dataurl = canvas.toDataURL("image/png")

						canvas.toBlob(binary => {
							api.common.uploadfile(binary).then(res => {
								console.log(res)
								this.image = this.DOMAIN_URL + this.tempdir + "/" + res.body.filename
							})
						})

						//this.image = dataurl;
					}
					img.src = reader.result
				}
			}
		},
		getCurrentPosition() {
			this.navigator.geolocation.getCurrentPosition(
				({ coords }) => {
					this.latitude = coords.latitude
					this.longitude = coords.longitude

					this.getAddress(coords.latitude, coords.longitude).then(res => {
						if (res && res.status === 200 && undefsafe(res, "body.address")) this.address = res.body.address
					})
				},
				error => {
					console.error(error)
				},
				{ maximumAge: 3000, timeout: 10000, enableHighAccuracy: true }
			)
		},
		getAddress(lat, lon) {
			return api.common.reverseGeocoding(lat, lon)
		},
		resetForm() {
			this.image = ""
			this.name = ""
			this.catSelected = null
			this.description = ""
			this.address = ""
			this.latitude = ""
			this.longitude = ""
			this.accessible = null
			this.phoneNumber = ""
			this.timeFrom = null
			this.timeTo = null
			this.ticketCost = ""
			this.source = ""
			this.privacyCheck = false
		},
		send() {
			if (this.isValid) {
				const point = {
					source: this.source,
					category: this.catSelected,
					name: this.name,
					description: this.description,
					hours: this.timeFrom ? this.timeFrom + (this.timeTo ? " - " + this.timeTo : "") : "",
					phone: this.phoneNumber,
					ticketCost: this.ticketCost ? this.ticketCost + " €" : "",
					addressText: this.address,
					latitude: _.toString(this.latitude),
					longitude: _.toString(this.longitude),
					photo: this.image,
					accessibility: this.accessible ? "Si" : "No",
					visibleCopy: this.fullName
				}

				api.pdi
					.savePointUser(point)
					.then(data => {
						console.log(data)
						this.resetForm()

						this.$ons.notification.alert(this.$t("Thank you for sharing new beauty"), {
							title: ""
						})
					})
					.catch(err => {
						console.error(err)
						this.$ons.notification.alert(this.$t("Error reporting beauty"))
					})
			}
		},
		addDraft() {
			this.$store.commit("addDraft", {
				id: moment().format("x"),
				dateInsert: moment().toDate(),
				image: this.image,
				name: this.name,
				catSelected: this.catSelected,
				description: this.description,
				address: this.address,
				latitude: this.latitude,
				longitude: this.longitude,
				accessible: this.accessible,
				phoneNumber: this.phoneNumber,
				timeFrom: this.timeFrom,
				timeTo: this.timeTo,
				ticketCost: this.ticketCost,
				source: this.source
			})
			this.resetForm()
		},
		delDraft(id) {
			this.$store.commit("delDraft", { id })
		},
		setForm(payload) {
			this.resetForm()
			this.image = payload.image
			this.name = payload.name
			this.catSelected = payload.catSelected
			this.description = payload.description
			this.address = payload.address
			this.latitude = payload.latitude
			this.longitude = payload.longitude
			this.accessible = payload.accessible
			this.phoneNumber = payload.phoneNumber
			this.timeFrom = payload.timeFrom
			this.timeTo = payload.timeTo
			this.ticketCost = payload.ticketCost
			this.source = payload.source
		}
	},
	mounted() {
		this.fullName = this.$store.getters.fullName
	},
	components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.add-beauty-form {
	background: white;
	padding-top: 40px;
	box-sizing: border-box;

	.toolbar {
		background: white;

		.toolbar__title {
			color: #000;
			text-align: center;
		}
		.toolbar-button {
			color: #000;
			opacity: 0.5;
		}
	}

	.form-wrap {
		box-sizing: border-box;
		max-width: 400px;
		margin: 0 auto;

		.form-item {
			margin: 24px 14px;
			box-sizing: border-box;
			display: block;
			clear: both;

			.label {
				font-size: 14px;
				opacity: 0.7;
				margin-bottom: 12px;
			}
			.field {
				input,
				textarea,
				select {
					border-radius: 4px;
					background: #fff;
					box-shadow: 0px 0px 3px 0px rgba(26, 101, 255, 0.2);
					border: 1px solid #d4e2ff;
					outline: none;
					padding: 7px 10px;
					width: 100%;
					box-sizing: border-box;
				}

				textarea {
					resize: vertical;
					min-height: 100px;
				}

				select {
					width: auto;
				}

				[type="checkbox"] {
					width: auto;
				}

				&.accessible-box {
					overflow: hidden;
					padding: 5px 0;

					.image-box {
						background: #f2f2f2;
						width: 40px;
						height: 40px;
						padding: 10px;
						box-sizing: border-box;
						border-radius: 4px;
						display: inline-block;
						float: left;

						img {
							width: 90%;
							margin-left: 5%;
						}
					}

					button {
						border-radius: 4px;
						padding: 10px;
						background: #fff;
						box-shadow: 0px 0px 3px 0px rgba(26, 101, 255, 0.2);
						border: none;
						outline: none;
						float: left;
						height: 40px;
						min-width: 40px;
						margin-left: 10px;
						cursor: pointer;

						&.active {
							background: #1a65ff;
							background: -moz-linear-gradient(top, #1a65ff 0%, #34aefc 100%);
							background: -webkit-linear-gradient(top, #1a65ff 0%, #34aefc 100%);
							background: linear-gradient(to bottom, #1a65ff 0%, #34aefc 100%);
							filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a65ff', endColorstr='#34aefc',GradientType=1 );
							color: #fff;
						}
					}
				}
			}

			.photo-btn {
				padding: 0;
				margin: 0;
				border: 1px solid #e8e8e8;
				background: #fff;
				outline: none;
				border-radius: 4px;
				cursor: pointer;
				overflow: hidden;
				width: 100%;
				text-align: center;

				img {
					max-width: 150px;
				}
			}

			&.cat-wrap {
				margin-left: 0;
				margin-right: 0;

				.label {
					font-size: 14px;
					opacity: 0.7;
					margin-bottom: 12px;
					margin-left: 14px;
					margin-right: 14px;
				}

				.cat-list-wrap {
					overflow: hidden;
					overflow-x: scroll;

					.cat-list {
						background: #f2f2f2;
						border-radius: 4px;
						padding: 5px 10px;
						height: 100px;
						width: 1374px;
						margin-left: 14px;
						margin-right: 14px;
						overflow: hidden;

						.btn-box {
							display: inline-block;
							text-align: center;
							margin: 10px;

							button {
								width: 60px;
								height: 60px;
								box-sizing: border-box;
								background: #fff;
								padding: 5px;
								border-radius: 4px;
								border: none;
								outline: none;
								margin-bottom: 5px;
								cursor: pointer;

								img {
									width: 70%;
								}
							}
							.btn-label {
								opacity: 0.4;
								font-size: 12px;
							}

							&.active {
								button {
									box-shadow: 0px 3px 10px 0px rgba(26, 101, 255, 0.2);
								}

								.btn-label {
									opacity: 1;
								}
							}
						}
					}
				}
			}

			&.address-box,
			&.ticket-box {
				.field {
					button {
						border-radius: 4px;
						line-height: 10px;
						font-size: 15px;
						border: none;
						outline: none;
						padding: 0;
						height: 28px;
						width: 28px;
						margin-left: 10px;
						cursor: pointer;
						text-align: center;

						background: #1a65ff;
						background: -moz-linear-gradient(top, #1a65ff 0%, #34aefc 100%);
						background: -webkit-linear-gradient(top, #1a65ff 0%, #34aefc 100%);
						background: linear-gradient(to bottom, #1a65ff 0%, #34aefc 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a65ff', endColorstr='#34aefc',GradientType=1 );
						color: #fff;

						&.currency-btn {
							color: #969696;
							background: #eeeeee;
						}
					}
				}
			}

			&.time-box {
				.field {
					span {
						font-size: 12px;
						margin-right: 10px;
					}
					select {
						margin-right: 10px;
					}
				}
			}

			&.copy-box {
				input {
					margin-right: 10px;
				}
				label {
					font-size: 10px;
					opacity: 0.5;
				}
			}

			&.submit-box {
				display: flex;

				.divider {
					width: 10px;
				}

				button {
					background: #1d84ff;
					padding: 10px 0;
					width: 100%;
					margin: 0 auto;
					display: block;
					border: none;
					outline: none;
					border-radius: 4px;
					color: #fff;
					cursor: pointer;
					font-weight: 600;

					&.alt {
						background: #f2f2f2;
						color: #a8a8ac;
					}

					&.not-grow {
						min-width: 50px;
					}

					&[disabled] {
						cursor: not-allowed;
						opacity: 0.5;
					}
				}
			}
		}

		.draft-wrapper {
			margin: 24px 14px;

			.header {
				height: 25px;
				font-size: 18px;
				cursor: pointer;
			}

			.draft-list {
				.item {
					margin: 8px 0;

					.left {
						background: #f2f2f2;
						margin-right: 10px;
						border-radius: 6px;
						cursor: pointer;

						.name {
							font-weight: bold;
							margin: 6px 12px;
							margin-bottom: 2px;
						}
						.datetime {
							font-size: 12px;
							opacity: 0.5;
							margin: 2px 12px;
							margin-bottom: 8px;
						}

						button {
							cursor: pointer;
							background: transparent;
							border: none;
							outline: none;
							color: #1d84ff;
							font-size: 24px;
						}
					}
					.right {
						background: #f2dddd;
						border-radius: 6px;
						cursor: pointer;

						button {
							cursor: pointer;
							background: transparent;
							border: none;
							outline: none;
							color: #de6161;
							font-size: 24px;
						}
					}
				}
			}
		}
	}
}
</style>
