<template>
	<div class="share-page flex column">
		<v-ons-toolbar>
			<div class="left">
				<v-ons-toolbar-button icon="md-close" @click="closeModal"></v-ons-toolbar-button>
			</div>
			<div class="center">{{$t("Share it")}}</div>
			<div class="right">
				<v-ons-toolbar-button @click="share" v-show="!thankYouScreen">{{$t("Done")}}</v-ons-toolbar-button>
			</div>
		</v-ons-toolbar>

		<div class="page-wrapper box grow">
			<div class="page-content">
				
				<div class="share-form" v-show="!thankYouScreen">
					<div class="label">{{$t('Tell your friends where you have been thanks to Stendhapp')}}!</div>
					<textarea :placeholder="$t('Describe this place')"></textarea>
					<button class="photo-btn" @click="launchFilePicker()" aria-label="add photo">
						<img src="images/placeholder-photo.png" v-if="!image" alt="beauty-photo">
						<img :src="image" v-if="image" alt="beauty-photo">
					</button>
					<input type="file" accept='image/*' ref="file" :name="uploadFieldName" @change="onFileChange( $event.target.name, $event.target.files)" style="display:none" capture>
				</div> 
				<div class="infobar flex" v-show="!thankYouScreen">
					<div class="place box grow flex align-center">
						<div class="image-box" v-if="itemDetail && itemDetail.category">
							<img :src="'map-icons/'+itemDetail.category+'.png'" alt="category-icon">
						</div>
						<div class="location box grow" v-if="currentPos && currentPos.location">
							{{currentPos.location}}
						</div>
					</div>
					<!--<div class="social-select">
						<button @click="actionSheetVisible = true">
							<i class="mdi mdi-facebook" v-if="social === 'facebook'"></i>
							<i class="mdi mdi-twitter" v-if="social === 'twitter'"></i>
							<i class="mdi mdi-telegram" v-if="social === 'telegram'"></i>
							<i class="mdi mdi-whatsapp" v-if="social === 'whatsapp'"></i>
						</button>
					</div>-->
					
				</div>

				<p class="share-buttons-label" v-show="!thankYouScreen">Condividi su:</p>
				<div class="social-buttons flex" v-show="!thankYouScreen">
					<div class="social-button box grow" :class="{'active': social === 'facebook'}" @click="social = 'facebook'">
						<div class="social-icon">
							<i class="mdi mdi-facebook"></i>
						</div>
						<div class="social-label">Facebook</div>
					</div>
					<div class="social-button box grow" :class="{'active': social === 'twitter'}" @click="social = 'twitter'">
						<div class="social-icon">
							<i class="mdi mdi-twitter"></i>
						</div>
						<div class="social-label">Twitter</div>
					</div>
					<div class="social-button box grow" :class="{'active': social === 'telegram'}" @click="social = 'telegram'">
						<div class="social-icon">
							<i class="mdi mdi-telegram"></i>
						</div>
						<div class="social-label">Telegram</div>
					</div>
					<div class="social-button box grow" :class="{'active': social === 'whatsapp'}" @click="social = 'whatsapp'">
						<div class="social-icon">
							<i class="mdi mdi-whatsapp"></i>
						</div>
						<div class="social-label">Whatsapp</div>
					</div>
				</div>
				<p v-show="!thankYouScreen" style="text-align: center;">
					<small>{{$t("Stendhapp will not use this material")}}</small>
				</p>

				<div class="thank-you-screen" v-show="thankYouScreen">
					<div class="title">{{$t("Thank you for sharing")}}!</div>
					<!--<div class="subtitle">{{$t("You post looks amazing")}}!</div>-->
					<div class="image-box">
						<img src="images/thank-you-screen.png" alt="thank-you-screen">
					</div>
					<div class="add-btn-box">
						<button @click="resetPage" aria-label="close">{{$t("Do you want to add anything else?")}}</button>
					</div>
					<div class="done-btn-box">
						<button @click="closeModal" aria-label="close">{{$t("Done")}}</button>
					</div>
				</div>

				<v-ons-action-sheet
					:visible.sync="actionSheetVisible"
					cancelable
					:title="$t('Where do you want to share')+' ?'"
				>
					<v-ons-action-sheet-button @click="social = 'facebook'; actionSheetVisible = false">Facebook</v-ons-action-sheet-button>
					<v-ons-action-sheet-button @click="social = 'twitter'; actionSheetVisible = false">Twitter</v-ons-action-sheet-button>
					<v-ons-action-sheet-button @click="social = 'telegram'; actionSheetVisible = false">Telegram</v-ons-action-sheet-button>
					<v-ons-action-sheet-button @click="social = 'whatsapp'; actionSheetVisible = false">Whatsapp</v-ons-action-sheet-button>
					<v-ons-action-sheet-button @click="actionSheetVisible = false">{{$t("Close")}}</v-ons-action-sheet-button>
				</v-ons-action-sheet>

			</div>
		</div>
	</div>
</template>

<script>
import watermark from "watermarkjs"

export default {
	name: "share-page",
	props: ['item'],
	data() {
		return {
			actionSheetVisible: false,
			/*errorDialog: null,
			errorText: '',
			maxSize: 1024,*/
			uploadFieldName: 'file',
			image: "",
			maxWidth: 400,
			maxHeight: 300,
			thankYouScreen: false,
			social: 'facebook',
			bkpImg : null
		};
	},
	computed: {
		currentPos() {
			return this.$store.state.currentPos
		},
		items() {
			return this.$store.getters.pdiList
		},
		itemDetail() {
			return this.item ? this.items.find(obj => obj._id === this.item._id) : {}
		}
	},
	methods: {
		closeModal() {
			this.resetPage()
			this.$emit('close')
		},
		launchFilePicker(){
			this.$refs.file.click();
		},
		onFileChange(fieldName, file) {
			//const { maxSize } = this
			let imageFile = file[0] 
	
			//check if user actually selected a file
			if (file.length > 0) {
				/*let size = imageFile.size / maxSize / maxSize
				if (!imageFile.type.match('image.*')) {
					// check whether the upload is an image
					this.errorDialog = true
					this.errorText = 'Please choose an image file'
				} else if (size>1) {
					// check whether the size is greater than the size limit
					this.errorDialog = true
					this.errorText = 'Your file is too big! Please select an image under 1MB'
				} else {
					// Append file into FormData & turn file into image URL
					let imageURL = URL.createObjectURL(imageFile)
					// Emit FormData & image URL to the parent component
				}*/

				const reader = new FileReader();
				reader.readAsDataURL(imageFile);
				reader.onload = () => {
					//console.log(this.image);
					const img = new Image();

					img.onload = () => {

						this.bkpImg = img

						const dataurl = this.setImageSize(img)
						//this.image = dataurl;

						this.setWatermark(dataurl)
					}
					img.src = reader.result;
				};

			}
		},
		setImageSize(img) {
			const canvas = document.createElement("canvas");

			const MAX_WIDTH = this.maxWidth;
			const MAX_HEIGHT = this.maxHeight;
			let width = img.width;
			let height = img.height;

			if (width > height) {
				if (width > MAX_WIDTH) {
					height *= MAX_WIDTH / width;
					width = MAX_WIDTH;
				}
			} else {
				if (height > MAX_HEIGHT) {
					width *= MAX_HEIGHT / height;
					height = MAX_HEIGHT;
				}
			}
			
			canvas.width = width;
			canvas.height = height;

			const ctx = canvas.getContext("2d");
			
			ctx.drawImage(img, 0, 0, width, height);
			
			const dataurl = canvas.toDataURL("image/png");
			return dataurl
		},
		setWatermark(dataurl) {
			watermark([dataurl, 'images/watermark.svg'])
			.dataUrl(watermark.image.lowerLeft(1)) //0.7 alpha
			.then(img => {
				this.image = img
			});
		},
		share() {
			this.thankYouScreen = true
		},
		resetPage() {
			this.thankYouScreen = false
		}
	},
	components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.share-page {
	height: 100%;
	width: 100%;
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	box-sizing: border-box;
	overflow: hidden;
	background: #fff;
	max-width: 640px;
	margin: 0 auto;
	color: #000;

	.toolbar {
		.toolbar__title {
			color: #000;
		}
		.toolbar-button {
			color: #000;
			opacity: .5;
		}
	}

	.page__background {
		background: #fff;
	}

	.page-wrapper {
		padding: 20px;
		text-align: left;
		box-sizing: border-box;
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		padding-top: 65px;
	}

	.share-form {
		padding: 20px;
		text-align: left;

		.label {
			font-size: 14px;
			font-weight: 500;
			margin-bottom: 12px;
		}

		textarea {
			resize: vertical;
			font-size: 14px;
			opacity: .7;
			border: none;
			outline: none;
			width: 100% !important;
			min-height: 100px;
		}

		.photo-btn {
			padding: 0;
			margin: 0;
			border: 1px solid #e8e8e8;
			background: #fff;
			outline: none;
			border-radius: 4px;
			cursor: pointer;
			overflow: hidden;

			img {
				max-width: 150px;
				float: left;
			}
		}
	}

	.infobar {
		border-top: 1px solid #d3d7e5;

		.place {
			text-align: left;

			.image-box {
				width: 40px;
				height: 40px;
				box-sizing: border-box;
				background: #fff;
				padding: 5px;
				border-radius: 4px;
				line-height: 40px;
				text-align: center;
				margin: 14px;
				box-shadow: 0px 3px 10px 0px #c6d1f0;

				img {
					width: 85%;
				}
			}
			.location {
				opacity: .6;
				font-size: 14px;
				padding-right: 14px;
			}
		}
		.social-select {
			button {
				width: 40px;
				height: 40px;
				box-sizing: border-box;
				background: #fff;
				border-radius: 4px;
				text-align: center;
				margin: 14px;
				box-shadow: 0px 3px 10px 0px #c6d1f0;
				border: none;
				outline: none;
				cursor: pointer;
				font-size: 20px;

				.mdi-facebook {
					color: #3b5998;
				}
				.mdi-twitter {
					color: #1da1f2;
				}
				.mdi-telegram {
					color: #0088cc;
				}
				.mdi-whatsapp {
					color: #25D366;
				}
			}
		}
	}

	.social-buttons {
		box-sizing: border-box;
		background: #fff;
		border-radius: 4px;
		text-align: center;
		margin: 14px;
		box-shadow: 0px 3px 10px 0px #c6d1f0;
		border: none;
		outline: none;
		cursor: pointer;
		font-size: 20px;

		.social-button {
			border-right: 1px solid #e8e8e8;
			box-sizing: border-box;
			padding: 8px 0;

			&:last-child {
				border: none;
			}

			.social-label {
				font-size: 12px;
				margin-top: 4px;
				color: #c3c3c3;
			}

			.mdi {
				color: #c3c3c3;
			}

			&.active {
				.social-label {
					color: #000;
				}
				
				.mdi-facebook {
					color: #3b5998;
				}
				.mdi-twitter {
					color: #1da1f2;
				}
				.mdi-telegram {
					color: #0088cc;
				}
				.mdi-whatsapp {
					color: #25D366;
				} 
			}

		}
	}

	.share-buttons-label {
		font-size: 14px;
		text-align: left;
		opacity: .5;
		margin-left: 16px;
		margin-bottom: -8px;
	}

	.thank-you-screen {
		padding: 20px;
		text-align: center;

		.title {
			color: #5463F4;
			font-size: 20px;
			text-transform: uppercase;
			margin: 15px auto;
			font-weight: 500;
		}
		.subtitle {
			font-size: 14px;
			opacity: .5;
		}

		.image-box {
			width: 100%;
			text-align: center;
			margin-top: 30px;
			
			img {
				width: 90%;
				max-width: 300px;
			}
		}

		.add-btn-box {
			margin-top: 30px;

			button {
				box-sizing: border-box;
				background: #fff;
				border-radius: 4px;
				text-align: center;
				margin: 14px;
				box-shadow: 0px 3px 10px 0px #c6d1f0;
				border: none;
				outline: none;
				cursor: pointer;
				font-size: 16px;
				color: #5463F4;
				padding: 10px 50px;
				font-weight: 500;
			}
		}

		.done-btn-box {
			button {
				background: #1D84FF;
				padding: 10px 0;
				width: 100%;
				margin: 0 auto;
				display: block;
				border: none;
				border-radius: 4px;
				color: #fff;
				margin-top: 20px;
				cursor: pointer;
				font-size: 14px;
			}
		}
	}
}
</style>
