<template>
	<div class="map-small-component-static" :style="'background-image:url('+getImage()+')'">
		<div class="map-overlay" @click="openMap"></div>
	</div>
</template>

<script>
import api from '../api'

export default {
	name: 'map-small-component-static',
	data () {
		return {
			navigator: navigator,
			lat: 0,
			lon: 0,
			watchPositionIntervalTime: 30000,
			BASE_URL: api.getBaseUrl(),
			DOMAIN_URL: api.getDomainUrl(),
		}
	},
	computed: {
		filtersParsed() {
			return this.$store.getters.filtersParsed
		},
		isLogged() {
			return this.$store.state.isLogged;
		},	
		canGeolocation() {
			return this.$store.getters.canGeolocation
		}
	},
	methods: {
		openMap() {
			this.$emit('openMap')
		},
		getCurrentPosition() {
			return new Promise((resolve, reject) => {
				if (this.canGeolocation) {
					this.navigator.geolocation.getCurrentPosition(
						(position) => { resolve(position) },
						(error) => { reject(error) },
						{ maximumAge: 3000, timeout: 10000, enableHighAccuracy: true }
					)
				} else {
					reject({ack:false});
				}
			})
		},
		watchPosition() {
			this.getCurrentPosition().then(({coords}) => {
				let goToSetCurrentPos = true
				
				if(
					parseFloat(coords.latitude).toFixed(5) === parseFloat(this.lat).toFixed(5) && 
					parseFloat(coords.longitude).toFixed(5) === parseFloat(this.lon).toFixed(5) 
				) {
					goToSetCurrentPos = false
				}

				if(goToSetCurrentPos) {					
					this.setCurrentPos(coords)
				}

				setTimeout(()=>{this.watchPosition()}, this.watchPositionIntervalTime)
			}).catch(err => {
				setTimeout(()=>{this.watchPosition()}, this.watchPositionIntervalTime)
			})
		},
		setCurrentPos(coords) {
			if (coords.latitude && coords.longitude) {
				this.lat = coords.latitude
				this.lon = coords.longitude
			}
		},
		getImage() {
			const filters = this.filtersParsed.filter(f => f.active).map(f => f.name).join(',')

			if (this.lat && this.lon && this.isLogged)
				return this.BASE_URL+`/static-map?lat=${this.lat}&lon=${this.lon}&filters=${filters || ''}`
			else
				return `images/placeholder.jpg`
		}
	},
	mounted(){
		this.watchPosition()
	},
	components: {
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.map-small-component-static {
	width: 100%;
	height: 100%;
	position: relative;
	background-color: #f6f6f4;
	background-image: url(../../public/images/placeholder.jpg);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	.map-overlay {
		width: 100%;
		position: absolute;
		height: 100%;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 999;
		cursor: pointer;
	}
}
</style>
