import { Http } from 'vue-resource'
import usafe from 'undefsafe'
import _ from 'lodash'
import moment from 'moment-timezone'
import utils from '../../utils'

Http.options.credentials = true
Http.options.xhr = {withCredentials: true}

export default {
	deviceDetector() {
		return Http.get(`${utils.getUrl('device-detector')}`)
	},	
	forwardGeocoding(address) {
		return Http.get(`${utils.getUrl('geocoding')}?address=${address}`)
	},	
	reverseGeocoding(lat, lon) {
		return Http.get(`${utils.getUrl('geocoding')}?lat=${lat}&lon=${lon}`)
	},	
	directions(routeType, startLon, startLat, endLon, endLat) {
		return Http.get(`${utils.getUrl('directions')}?routeType=${routeType}&startLon=${startLon}&startLat=${startLat}&endLon=${endLon}&endLat=${endLat}`)
		//return this.$http.get(`https://api.mapbox.com/directions/v5/mapbox/${routeType}/${startLon}%2C${startLat}%3B${endLon}%2C${endLat}.json?access_token=${this.mapboxKey}&overview=full&geometries=geojson`)
	},	
	uploadfile(binary) {
		var formData = new FormData();
		formData.append('file', binary);

		return Http.post(`${utils.getUrl('uploadfile')}`, formData)
	},	
}
