const config = process.env;
const auth = require(`./v${config.VUE_APP_API_VERSION}/auth.js`).default;
const common = require(`./v${config.VUE_APP_API_VERSION}/common.js`).default;
const pdi = require(`./v${config.VUE_APP_API_VERSION}/pdi.js`).default;
const event = require(`./v${config.VUE_APP_API_VERSION}/event.js`).default;

import store from "../store/store"
import { Http } from 'vue-resource'

//let DOMAIN_URL = "http://localhost:3012";
//if (config.NODE_ENV !== "development") DOMAIN_URL = "http://195.231.1.153";
//DOMAIN_URL = location.origin

const DOMAIN_URL = config.VUE_APP_API_URL
const API_VERSION = config.VUE_APP_API_VERSION

const BASE_URL = DOMAIN_URL + "/api/v" + API_VERSION;

Http.interceptors.push(function(request) {
	// return response callback
	return function(response) {
		//console.log('response: ', response)
		if (response.status === 401) {
			if (store.state.isLogged) {
				store.commit('logout', null)
				window.location.reload()
			}
		}
	}
})

export default {
	getBaseUrl() {
		return BASE_URL;
	},
	getDomainUrl() {
		return DOMAIN_URL;
	},

	auth,
	common,
	pdi,
	event
};
