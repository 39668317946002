<template>
	<div class="edit-user flex column">
		<v-ons-toolbar>
			<div class="left"></div>
			<div class="center">{{ $t("Edit your data") }}</div>
			<div class="right">
				<v-ons-toolbar-button icon="md-close" @click="closeModal"></v-ons-toolbar-button>
			</div>
		</v-ons-toolbar>

		<div class="page-wrapper box grow">
			<div class="page-content">
				<div class="form-wrap">
					<div class="form-item">
						<div class="label">
							{{ $t("First name") }}
						</div>
						<div class="field">
							<input id="first-name" type="text" :placeholder="$t('First name')" v-model="form.firstName" />
						</div>
					</div>
					<div class="form-item">
						<div class="label">
							{{ $t("Last name") }}
						</div>
						<div class="field">
							<input id="last-name" type="text" :placeholder="$t('Last name')" v-model="form.lastName" />
						</div>
					</div>
					<div class="form-item">
						<div class="label">
							{{ $t("Gender") }}
						</div>
						<div class="field select-field">
							<select v-model="form.gender" :class="{ 'not-selected': !form.gender }">
								<option value="">{{ $t("Gender") }}</option>
								<option v-for="item in genderValues" :key="item.value" :value="item.value">{{ $t(item.label) }}</option>
							</select>
						</div>
					</div>
					<div class="form-item">
						<div class="label">
							{{ $t("City") }}
						</div>
						<div class="field">
							<input id="city" type="text" :placeholder="$t('City')" v-model="form.city" />
						</div>
					</div>
					<div class="form-item">
						<div class="label">
							{{ $t("State") }}
						</div>
						<div class="field">
							<input id="state" type="text" :placeholder="$t('State')" v-model="form.state" />
						</div>
					</div>
					<div class="form-item">
						<div class="label">
							{{ $t("Age") }}
						</div>
						<div class="field select-field">
							<select v-model="form.age" :class="{ 'not-selected': !form.age }">
								<option value="">{{ $t("Age") }}</option>
								<option v-for="(i, index) in ageValues" :key="index" :value="i">{{ i }}</option>
							</select>
						</div>
					</div>

					<v-ons-list>
						<v-ons-list-item tappable>
							<label class="left">
								<v-ons-checkbox input-id="checkbox-opt1" v-model="form.privacy_level_1"></v-ons-checkbox>
							</label>
							<label class="center for-checkbox" for="checkbox-opt1">
								{{ $t("term_1") }}
							</label>
						</v-ons-list-item>
						<v-ons-list-item tappable>
							<label class="left">
								<v-ons-checkbox input-id="checkbox-opt2" v-model="form.privacy_level_2"></v-ons-checkbox>
							</label>
							<label class="center for-checkbox" for="checkbox-opt2">
								{{ $t("term_2") }}
							</label>
						</v-ons-list-item>
						<v-ons-list-item tappable>
							<label class="left">
								<v-ons-checkbox input-id="checkbox-opt3" v-model="form.privacy_level_3"></v-ons-checkbox>
							</label>
							<label class="center for-checkbox" for="checkbox-opt3">
								{{ $t("term_3") }}
							</label>
						</v-ons-list-item>
						<v-ons-list-item tappable>
							<label class="left">
								<v-ons-checkbox input-id="checkbox-cookies" v-model="form.privacy_cookies"></v-ons-checkbox>
							</label>
							<label class="center for-checkbox" for="checkbox-cookies">
								{{ $t("term_cookies") }}
							</label>
						</v-ons-list-item>
					</v-ons-list>

					<div class="form-item submit-box">
						<button @click="checkSend" :disabled="!submitValid" aria-label="save">{{ $t("Done") }}!</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import api from "../api"
import it_optin from "../locale/it/optin-alert"
import en_optin from "../locale/en/optin-alert"

export default {
	name: "EditUser",
	data() {
		return {
			ageValues: ["0 - 13", "14 - 19", "20 - 29", "30 - 39", "40 - 49", "50 - 59", "60 - 69", "70 - 79", "80+"],
			genderValues: [
				{ label: "Male", value: "m" },
				{ label: "Female", value: "f" },
				{ label: "Prefer not to say", value: "n" }
			],
			form: {
				firstName: "",
				lastName: "",
				gender: "",
				city: "",
				state: "",
				age: "",
				privacy_level_1: false,
				privacy_level_2: false,
				privacy_level_3: false,
				privacy_cookies: false
			}
		}
	},
	computed: {
		submitValid() {
			let ctrl = false

			if (
				this.form.firstName &&
				this.form.lastName &&
				//this.form.gender &&
				//this.form.city &&
				//this.form.state &&
				this.form.age &&
				this.form.age !== "0 - 13"
			) {
				ctrl = true
			}

			return ctrl
		}
	},
	methods: {
		closeModal() {
			this.$emit("close")
		},
		getUserInfo() {
			api.auth.userinfo("short").then(data => {
				if (data && data.status && data.status === 200 && data.body) {
					this.form.firstName = data.body.firstName
					this.form.lastName = data.body.lastName
					this.form.gender = data.body.gender
					this.form.city = data.body.city
					this.form.state = data.body.state
					this.form.age = data.body.age
					this.form.privacy_level_1 = data.body.privacy_level_1
					this.form.privacy_level_2 = data.body.privacy_level_2
					this.form.privacy_level_3 = data.body.privacy_level_3
				}
			})

			this.$set(this.form, "privacy_cookies", localStorage.getItem("accept-cookies") === "yes")
		},
		checkSend() {
			if (this.submitValid) {
				if (!this.form.privacy_level_1) {
					this.viewOptinAlert(() => {
						this.send()
					})
				} else {
					this.send()
				}
			}
		},
		send() {
			if (this.form.privacy_cookies) {
				window.gtag("js", new Date())
				window.gtag("config", "UA-164743859-2", { anonymize_ip: false })
				localStorage.setItem("accept-cookies", "yes")
			} else {
				this.delete_cookie("_ga")
				this.delete_cookie("_gid")
				this.delete_cookie("_gat_gtag_UA_164743859_2")
				localStorage.setItem("accept-cookies", "no")
			}

			api.auth
				.edituser(this.form)
				.then(data => {
					this.$store.commit("setUser", {
						firstName: this.form.firstName,
						lastName: this.form.lastName,
						gender: this.form.gender,
						privacy_level_1: this.form.privacy_level_1,
						privacy_level_2: this.form.privacy_level_2,
						privacy_level_3: this.form.privacy_level_3
					})
					window.location.reload()
					//this.closeModal()
					//this.$ons.notification.toast(this.$t('Profile updated'), { timeout: 1000, animation: 'fall' })
				})
				.catch(err => {
					this.$ons.notification.toast(this.$t("Save error"), { timeout: 1000, animation: "fall" })
				})
		},
		delete_cookie(name) {
			document.cookie = name + "=" + ";expires=Thu, 01 Jan 1970 00:00:01 GMT"
		},
		viewOptinAlert(cb) {
			let message = it_optin

			switch (this.$i18n.locale) {
				case "it":
					message = it_optin
					break
				case "en":
					message = en_optin
				default:
					break
			}
			this.$ons.notification
				.alert(message, {
					title: this.$t("Caution")
				})
				.then(data => {
					if (cb && typeof cb === "function") {
						cb()
					}
				})
		}
	},
	mounted() {
		this.getUserInfo()
	},
	components: {}
}
</script>

<style lang="scss" scoped>
.edit-user {
	height: 100%;
	width: 100%;
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	box-sizing: border-box;
	overflow: hidden;
	background: #fff;
	max-width: 640px;
	margin: 0 auto;
	color: #000;

	.page__background {
		background: #fff;
	}

	.page-wrapper {
		padding: 20px;
		text-align: left;
		box-sizing: border-box;
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		padding-top: 65px;
	}

	.form-wrap {
		box-sizing: border-box;
		max-width: 400px;
		margin: 0 auto;

		.form-item {
			margin: 24px 14px;
			box-sizing: border-box;
			display: block;
			clear: both;

			.label {
				font-size: 14px;
				opacity: 0.7;
				margin-bottom: 12px;
			}
			.field {
				input,
				textarea,
				select {
					border-radius: 4px;
					padding: 3px;
					background: #fff;
					box-shadow: 0px 0px 3px 0px rgba(26, 101, 255, 0.2);
					border: none;
					outline: none;
					padding: 7px 10px;
					width: 100%;
					box-sizing: border-box;
					-webkit-appearance: none;
					-moz-appearance: none;
				}

				&.select-field {
					position: relative;

					&:after {
						content: "\25BE";
						position: absolute;
						right: 10px;
						top: calc(50% - 2px);
						display: block;
						transform: translateY(-50%);
						line-height: 1;
						opacity: 0.5;
						color: #1c61ff;
					}

					select {
						cursor: pointer;
					}
				}

				textarea {
					resize: vertical;
					min-height: 100px;
				}
			}

			&.submit-box {
				button {
					background: #1d84ff;
					padding: 10px 0;
					width: 100%;
					margin: 0 auto;
					display: block;
					border: none;
					border-radius: 4px;
					color: #fff;
					margin-top: 20px;
					cursor: pointer;

					&[disabled] {
						cursor: not-allowed;
						opacity: 0.5;
					}
				}
			}
		}

		label {
			&.for-checkbox {
				white-space: break-spaces;
				font-size: 14px;
			}
		}
	}
}
</style>

<style lang="scss">
.edit-user {
	.toolbar {
		.toolbar__title {
			color: #000;
		}
		.toolbar-button {
			color: #000;
			opacity: 0.5;
		}
	}
}
</style>
