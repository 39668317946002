<template>
  <div />
</template>

<script>
import { optionsMerger, propsBinder, findRealParent } from 'vue2-leaflet/src/utils/utils.js';
import TileLayerMixin from 'vue2-leaflet/src/mixins/TileLayer.js';
import Options from 'vue2-leaflet/src/mixins/Options.js';
import { tileLayer, DomEvent } from 'leaflet';

/**
 * Load tiles from a map server and display them accordingly to map zoom, center and size
 */
export default {
  name: 'LTileLayer',
  mixins: [TileLayerMixin, Options],
  props: {
    url: {
      type: String,
      default: null,
    },
    tileLayerClass: {
      type: Function,
      default: tileLayer,
	},
	maxNativeZoom: {
		type: Number,
		default: 1
	},
	maxZoom: {
		type: Number,
		default: 1
	},
  },
  mounted() {
	const options = optionsMerger(this.tileLayerOptions, this);
	options.maxNativeZoom = this.maxNativeZoom
	options.maxZoom = this.maxZoom
    this.mapObject = this.tileLayerClass(this.url, options);
    DomEvent.on(this.mapObject, this.$listeners);
    propsBinder(this, this.mapObject, this.$options.props);
    this.parentContainer = findRealParent(this.$parent);
    this.parentContainer.addLayer(this, !this.visible);
    this.$nextTick(() => {
      /**
       * Triggers when the component is ready
       * @type {object}
       * @property {object} mapObject - reference to leaflet map object
       */
      this.$emit('ready', this.mapObject);
    });
  },
};
</script>
