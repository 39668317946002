export default `
<div class="wpb_wrapper">
		<p><span style="font-weight: 400;">Stendhapp srl – Startup Innovativa a Vocazione Sociale (“Stendhapp”, “</span><b>la Società</b><span style="font-weight: 400;">“) offre un servizio online senza scopo di lucro che fornisce agli utenti (“</span><b>utenti</b><span style="font-weight: 400;">“, “</span><b>utente</b><span style="font-weight: 400;">“) consigli per scoprire il patrimonio artistico e culturale e molto altro.&nbsp; Fornisce inoltre agli operatori del settore (“</span><b>fornitori</b><span style="font-weight: 400;">”, “</span><b>fornitore</b><span style="font-weight: 400;">”) informazioni sui visitatori del loro territorio e potenziali. I componenti per dispositivi mobili e le relative applicazioni e il sito web (insieme indicati come “</span><b>Sito/Siti</b><span style="font-weight: 400;">“) sono di proprietà di e gestiti da Stendhapp srl.</span></p>
<p><span style="font-weight: 400;">Accedendo al Sito e utilizzando i servizi della Società, l’utente dichiara di aver letto e compreso la presente Normativa sulla privacy e le modalità di raccolta e trattamento dei dati qui descritte.</span></p>
<p><span style="font-weight: 400;">Ultimo aggiornamento della Normativa sulla privacy: 2 maggio 2020. Stendhapp si riserva il diritto di modificare periodicamente la Normativa sulla privacy, si consiglia pertanto di prenderne regolarmente visione per essere al corrente degli aggiornamenti.</span></p>
<p><b>Informazioni generali</b></p>
<p><span style="font-weight: 400;">La presente Normativa sulla privacy descrive in dettaglio le prassi e le politiche applicabili alla raccolta, al trattamento e alla divulgazione dei dati personali degli utenti da parte della Società.</span></p>
<p><span style="font-weight: 400;">Stendhapp è consapevole del fatto che fornire le proprie informazioni online comporta un elevato livello di fiducia da parte degli utenti. A tale fiducia Stendhapp riserva la massima serietà e attribuisce alta priorità alla garanzia di sicurezza e riservatezza dei dati personali forniti dagli utenti attraverso l’uso del Sito o dei servizi.</span></p>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">Raccolta dei dati personali</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Trattamento dei dati personali</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Condivisione dei dati personali</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Tempi di conservazione dei dati personali</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Cookie e altre tecnologie web</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Diritti dell’utente</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Tutela dei dati personali</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Informazioni relative ai minori</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Link esterni</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Modifiche alla Normativa sulla privacy</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Contatti</span></li>
</ol>
<ol>
<li><b> Raccolta dei dati personali</b></li>
</ol>
<p><span style="font-weight: 400;">Per dati personali si intende qualsiasi informazione che consenta l’identificazione di una persona, ad esempio indirizzo di posta elettronica, data di nascita, password e dati di pagamento. Non sono inclusi i dati da cui l’identità sia stata rimossa o altrimenti resi anonimi (dati anonimi).</span></p>
<p><span style="font-weight: 400;">Nel corso dell’attività, la Società raccoglie ed elabora dati personali in diversi modi. I dati possono essere raccolti perché forniti direttamente dall’utente o tramite la registrazione delle modalità di interazione tra l’utente e i servizi, ad esempio con l’uso di cookie sui Siti della Società. La Società potrebbe inoltre ricevere informazioni da partner di terze parti (seguono informazioni più precise).</span></p>
<p><span style="font-weight: 400;">I dati personali raccolti sull’utente possono includere, in via esemplificativa: nome, indirizzo email, indirizzo postale, numero di telefono, dati di fatturazione, risposte a sondaggi, dati di prenotazione, log di spostamento e altre informazioni che l’utente fornisce sui Siti, l’indirizzo IP e il software del browser web.&nbsp;</span></p>
<p><span style="font-weight: 400;">L’utente ha facoltà di scegliere di non fornire i propri dati personali alla Società. Tuttavia, di norma, alcune informazioni dell’utente sono necessarie per utilizzare molte funzionalità dei Siti.&nbsp;&nbsp; Ad esempio, solo gli utenti geolocalizzati possono ricevere le informazioni su luoghi e eventi intorno a loro, segnalare a loro volta luoghi, eventi e accessibilità degli stessi e partecipare a sondaggi, estrazioni e concorsi a premi.</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Informazioni fornite dall’utente: come descritto di seguito Stendhapp riceve e archivia le informazioni inserite dagli utenti attraverso l’applicazione o altro mezzo.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Informazioni provenienti da altre fonti: Stendhapp potrebbe ottenere periodicamente informazioni sugli utenti da parte di soggetti affiliati, partner aziendali o altre fonti indipendenti di terze parti e aggiungerle agli altri dati archiviati sugli utenti. Ad esempio, se un utente visita Stendhapp reindirizzato da un sito gestito da uno dei partner commerciali della Società e si è registrato presso tale partner, i dati forniti a quest’ultimo, come contatti e dati demografici, potrebbero essere condivisi con Stendhapp. Analogamente, se un utente accede a un servizio di terze parti, ad esempio un servizio offerto da un social media, attraverso i Siti o prima di visitare i Siti, potrebbero essere raccolte informazioni quali nome utente e altri dati resi disponibili a Stendhapp tramite tali servizi in conformità alla Normativa sulla privacy del soggetto di terze parti. Stendhapp collabora con partner esistenti e futuri al fine di migliorare e personalizzare l’uso dei Siti in conformità alle prassi descritte nella presente Normativa sulla privacy.</span></li>
</ul>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Raccolta automatica dei dati: Stendhapp raccoglie automaticamente alcune informazioni dal computer o dal dispositivo utilizzato per visitare Stendhapp. Ad esempio, i dati della sessione, tra cui l’indirizzo IP, il software di navigazione utilizzato e il sito di provenienza. Potrebbero inoltre essere raccolti dati sull’attività online, ad esempio i contenuti visualizzati, le pagine visitate e le ricerche effettuate. Queste informazioni vengono raccolte automaticamente per meglio comprendere gli interessi e le preferenze degli utenti, per personalizzare ulteriormente la loro esperienza e per creare segmenti di pubblico (suddividendo gli utenti in gruppi basati su criteri, come utilizzo del sito e dati demografici). La Società raccoglie inoltre dati personali al fine di proteggere il Sito e gli altri utenti da usi impropri.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Informazioni sulla posizione e altre informazioni ottenute dai dispositivi: quando si utilizzano i Siti su un dispositivo, tramite browser o app mobile, la Società raccoglie e tratta i dati dell’utente come descritto sopra. In particolare, potrebbero essere raccolte alcune informazioni sulla posizione, se l’utente ha configurato il dispositivo per l’invio di tali informazioni all’applicazione nelle impostazioni. I dati sulla posizione consentono, tra le altre cose, di mostrare all’utente le informazioni sulle bellezze e gli eventi nelle vicinanze durante gli spostamenti. È possibile modificare le impostazioni sulla privacy del dispositivo in uso in qualsiasi momento e disattivare quindi la condivisione delle informazioni con l’applicazione. Nota: la disattivazione della condivisione delle informazioni sulla località potrebbe influire su funzionalità importanti dell’applicazione. In caso di domande relative alle impostazioni sulla privacy del dispositivo, si consiglia di contattare il produttore o il gestore dei servizi di telefonia mobile.</span></li>
</ul>
<ol start="2">
<li><b> Trattamento dei dati personali</b></li>
</ol>
<p><span style="font-weight: 400;">La Società usa i dati personali dell’utente per scopi specifici. Questi includono, in via esemplificativa, offrire servizi per consentire la gestione della registrazione e dell’account, tra cui accesso e uso dei Siti e pubblicazione di informazioni, punteggi e foto, fornire assistenza, inviare notifiche in caso di modifiche dei termini o della Normativa sulla privacy, partecipare a sondaggi, gestire e migliorare i Siti e i servizi, personalizzare l’esperienza utente sui Siti, misurare l’interesse e migliorare i Siti e i servizi, inviare notifiche su promozioni e offerte speciali, agevolare la partecipazione a estrazioni a premi, concorsi o sondaggi, proteggere da, indagare su e scoraggiare attività fraudolente, illegali, non autorizzate o illecite, gestire e tutelare l’attività della Società e risolvere dispute o problemi.</span></p>
<p><span style="font-weight: 400;">La Società utilizza i dati personali degli utenti solo nei casi consentiti dalla legge. Più comunemente, la Società utilizza i dati personali nelle circostanze seguenti:</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Per fornire i suoi servizi all’utente.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">In caso di necessità per tutelare i legittimi interessi della Società (o di terze parti); gli interessi e i diritti fondamentali dell’utente non prevalgono su tali interessi.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Con il consenso dell’utente.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">A scopo di conformità a obblighi di legge.</span></li>
</ul>
<p><span style="font-weight: 400;"><br>
</span><b>Marketing</b></p>
<p><span style="font-weight: 400;">La Società si adopera per semplificare l’uso del Sito e per consentire a tutti gli utenti di scoprire la bellezza dei territori intorno a loro. A tal fine, la Società invia messaggi email che contengono informazioni sugli interessi degli utenti. Ad esempio, se l’utente è interessato alla musica, potrebbe ricevere un messaggio email su un concerto che si terrà nelle vicinanze del ultimo luogo visitato. La Società ritiene che tali messaggi email offrano utili informazioni agli utenti sugli eventi disponibili attraverso i suoi Siti.</span></p>
<p><span style="font-weight: 400;">Gli utenti riceveranno i messaggi di marketing dalla Società solo se hanno fornito il loro consenso e se hanno scelto di non revocare l’adesione alla ricezione di comunicazioni di marketing.</span></p>
<p><span style="font-weight: 400;">Ogni volta che si riceve un messaggio di questo tipo, è possibile scegliere di non ricevere ulteriori comunicazioni, rifiutare le notifiche push nell’applicazione o disabilitare le comunicazioni di marketing nelle impostazioni del dispositivo mobile. È possibile annullare l’iscrizione a ciascuna comunicazione di marketing in qualsiasi momento, facendo clic sul link apposito disponibile in calce a ogni comunicazione ricevuta da Stendhapp o inviando una mail a servizioclienti@stendhapp.com.</span></p>
<p><span style="font-weight: 400;">Stendhapp si riserva di inviare comunque comunicazioni di servizio, ad esempio messaggi amministrativi e di servizio relativi all’account o alle sue segnalazioni di luoghi e/o eventi o di accessibilità. Non è possibile annullare l’iscrizione a tali comunicazioni di servizio.</span></p>
<ol start="3">
<li><b> Condivisione dei dati personali</b></li>
</ol>
<p><span style="font-weight: 400;">Stendhapp </span><b>non condividerà</b><span style="font-weight: 400;"> i dati degli utenti a terzi, ad eccezione delle seguenti situazioni:</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">A scopo di conformità a citazioni in giudizio, sentenze di tribunale o altri procedimenti legali legittimi e applicabili; al fine di stabilire o esercitare i propri diritti; al fine di difendersi in caso di dispute; per altri scopi richiesti dalla legge. In tali casi, la Società si riserva il diritto di sollevare o rinunciare a qualsiasi obiezione legale o diritto.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">A scopo di indagine, prevenzione o azione rispetto ad attività illecite o potenzialmente illecite; al fine di proteggere e difendere i diritti, la proprietà o la sicurezza di Stendhapp, dei sui clienti o di altri; in conformità ai Termini di utilizzo&nbsp;e ad altri accordi.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">In connessione a transazioni commerciali, ad esempio cessioni, fusioni, consolidamenti, vendite di beni o nell’eventualità di bancarotta.&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Fornitori di servizi di terze parti incaricati da e operanti per conto di&nbsp; Stendhapp a fornire servizi utili all’attività della stessa (vedi seguito)</span></li>
</ul>
<p><span style="font-weight: 400;">Se l’utente sceglie di creare un profilo su Stendhapp, le informazioni del profilo non saranno pubblicamente visualizzabili ed identificabili attraverso l’applicazione. Inoltre, se l’utente sceglie di condividere un’immagine di un luogo visitato grazie a Stendhapp, la stessa sarà visibile solo sui suoi social media e dai contatti dell’utente secondo le sue impostazioni specifiche e non dagli altri utenti di Stendhapp.&nbsp; Stendhapp non condivide gli indirizzi email con altri utenti né li rende pubblici con altri mezzi. Se invece l’utente decide di mettere a disposizione dei contenuti da lui generati, i testi e le foto verranno pubblicati su Stendhapp a vantaggio di tutti gli utenti della piattaforma e verrà visualizzato il nome dell’utente che li ha forniti. Nel mettere a disposizione i contenuti, l’utente ne cede automaticamente tutti i diritti d’uso a Stendhapp.</span></p>
<p><span style="font-weight: 400;">Fornitori</span><span style="font-weight: 400;">, tra cui, in via esemplificativa, fornitori di attività, gestori o proprietari di musei, teatri, eventi, guide turistiche, consorzi ed attività che offrono servizi negli ambiti artistico-culturali oggetto dell’applicazione. Sul Sito Stendhapp e sui siti affiliati, tutti i prodotti e i servizi offerti da fornitori di terze parti sono descritti come tali. Le informazioni condivise con i fornitori vengono trattate in conformità alle normative sulla privacy di tali fornitori, ad eccezione dei dati di pagamento. Stendhapp condivide i dati di pagamento esclusivamente in caso di necessità al fine di agevolare le prenotazioni effettuate su o attraverso Stendhapp; l’uso di tali dati di pagamento da parte dei fornitori viene limitato esclusivamente a tali scopi. In alcuni casi, Stendhapp si riserva di inviare richieste di informazioni a siti di terze parti o di trasmetterle in modo sicuro al fornitore della prenotazione al fine di elaborare la transazione. Nota: se non diversamente specificato, è il fornitore a elaborare il pagamento, non Stendhapp. L’utente accetta di prendere visione e di essere vincolato dalle procedure e dalle normative dei fornitori prima di effettuare prenotazioni tramite Stendhapp. Stendhapp incoraggia gli utenti a prendere visione delle normative sulla privacy, dei termini di utilizzo e di altre politiche dei fornitori, i cui prodotti o servizi vengono acquistati attraverso Stendhapp. Nota: i fornitori potrebbero inoltre contattare gli utenti, se necessario, per richiedere informazioni aggiuntive sugli utenti per agevolare la prenotazione o per altri scopi conformi alle prassi descritte nei rispettivi termini di utilizzo e normative sulla privacy.</span></p>
<p><span style="font-weight: 400;">Fornitori di servizi di terze parti</span><span style="font-weight: 400;">&nbsp;che forniscono servizi o funzioni per conto della Società, tra cui analisi aziendali, elaborazione dei pagamenti, servizio clienti, marketing, pubbliche relazioni, somministrazione di sondaggi o partecipazione a concorsi a premi e prevenzione di attività fraudolente. Stendhapp potrebbe autorizzare fornitori di terze parti a raccogliere informazioni per proprio conto, tra cui, in via esemplificativa, dati necessari per gestire le funzionalità dei Siti o per agevolare la pubblicazione di pubblicità online personalizzata in base agli interessi degli utenti. I fornitori di servizi di terze parti hanno accesso a e potrebbero raccogliere informazioni necessarie per l’espletamento delle loro funzioni; essi non sono autorizzati a condividere né a trattare tali informazioni per altri scopi.</span></p>
<p><span style="font-weight: 400;">La Società si riserva di condividere informazioni aggregate o anonime con terzi, tra cui inserzionisti o investitori. Ad esempio, Stendhapp potrebbe comunicare agli inserzionisti il numero di visite ricevute dai Siti o le destinazioni visitate. Tali informazioni non contengono alcun dato personale e vengono utilizzate per sviluppare contenuti e servizi di interesse per gli utenti.</span></p>
<ol start="4">
<li><b> Tempi di conservazione dei dati personali</b></li>
</ol>
<p><span style="font-weight: 400;">Stendhapp conserva i dati personali degli utenti per il periodo necessario per adempiere agli scopi di raccolta, tra cui per soddisfare requisiti di legge, normativi, di contabilità e di rendicontazione.</span></p>
<p><span style="font-weight: 400;">Per determinare il periodo di conservazione appropriato per i dati personali, Stendhapp tiene conto della quantità, della natura e della sensibilità dei dati, del rischio potenziale di danno derivante da trattamento o divulgazione non autorizzati, degli scopi per cui vengono trattati, della possibilità di raggiungere gli stessi scopi con altri mezzi, nonché dei requisiti legali.</span></p>
<p><span style="font-weight: 400;">Stendhapp si riserva di conservare per un massimo di 10 anni determinate informazioni a seguito della chiusura dell’account utente, ad esempio se ciò fosse necessario per adempiere a obblighi di legge o per tutelare, prevenire frodi o abusi e difendere o esercitare i propri diritti.&nbsp;</span></p>
<ol start="5">
<li><b> Cookie e altre tecnologie web</b></li>
</ol>
<p><span style="font-weight: 400;">La Società raccoglie informazioni attraverso cookie e altre tecnologie analoghe (ad esempio web beacon). I cookie sono piccoli file di testo automaticamente memorizzati sul computer o sul dispositivo mobile ogni volta che si visita un qualsiasi sito web. Essi vengono archiviati dal browser e contengono informazioni di base sull’uso di Internet da parte degli utenti. A ogni visita, il browser invia i cookie al Sito in modo che il computer o il dispositivo mobile utilizzato vengano riconosciuti, migliorando e personalizzando l’esperienza.</span></p>
<p><span style="font-weight: 400;">Ulteriori dettagli sui cookie e sulle tecnologie analoghe (come i web beacon) utilizzati, sono disponibili nella Normativa sull’uso dei cookie.</span></p>
<p><span style="font-weight: 400;">Per ulteriori informazioni sui cookie in generale, su come visualizzare i cookie installati sul proprio dispositivo (mobile) e come gestirli ed eliminarli, si rimanda a siti come www.allaboutcookies.org e www.youronlinechoices.com/it/</span></p>
<ol start="6">
<li><b> Diritti dell’utente</b></li>
</ol>
<p><span style="font-weight: 400;">L’utente ha il diritto di verificare, integrare e aggiornare le informazioni fornite accedendo alle impostazioni del proprio account. È inoltre possibile contattare Stendhapp, utilizzando i recapiti forniti di seguito, per richiedere la chiusura del proprio account.</span></p>
<p><span style="font-weight: 400;">Se si decide di chiudere il proprio account, questo verrà disattivato e i dati personali dell’utente verranno rimossi. Una volta chiuso l’account, non sarà più possibile accedere ai propri dati personali. È possibile, tuttavia, aprire un nuovo account in qualsiasi momento.</span></p>
<p><span style="font-weight: 400;">È possibile modificare le proprie preferenze per il marketing in qualsiasi momento fintanto che l’account resta attivo, alla pagina Profilo.</span></p>
<p><span style="font-weight: 400;">In conformità alle leggi vigenti, l’utente dispone del diritto di accedere a, di rettificare, di cancellare, di limitare il trattamento dei propri dati, nonché il diritto alla portabilità dei dati e il diritto di obiezione. Di seguito sono disponibili ulteriori dettagli e informazioni su come e quando è possibile esercitare i propri diritti. Le richieste riceveranno risposta entro un mese. Tuttavia, la Società si riserva il diritto di prorogare tale periodo a due mesi.</span></p>
<p><span style="font-weight: 400;">In conformità alla legge vigente, l’utente dispone dei seguenti diritti in merito ai propri dati personali:</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Diritto di richiedere l’accesso ai propri dati personali. Questo consente di ricevere una copia dei propri dati personali archiviati dalla Società e di verificare che il trattamento sia lecito.&nbsp;</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Diritto di richiedere la correzione dei propri dati se errati. L’utente ha inoltre la facoltà di integrare eventuali dati personali incompleti registrati, tenendo conto degli scopi del trattamento.</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Diritto di richiedere l’eliminazione dei propri dati personali se:</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">&nbsp;</span><p></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">non sono più necessari per gli scopi per cui sono stati raccolti o trattati;</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">l’utente revoca il consenso, ove il trattamento dei dati personali sia soggetto al consenso e non esistono ulteriori basi giuridiche;</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">l’utente si oppone al trattamento dei propri dati personali e la società non ha motivi legittimi sovrastanti per proseguire il trattamento;</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">i dati personali sono stati trattati in modo illecito;</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">i dati personali devono essere eliminati a scopo di conformità agli obblighi di legge.</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">&nbsp;</span></li>
</ul>
</li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Diritto di opporsi al trattamento dei propri dati personali. La Società si impegna ad agire nell’interesse degli utenti, a meno che non sussistano interessi legittimi sovrastanti per il trattamento dei dati o necessità di proseguire il trattamento a scopo di risoluzione, esercizio o difesa nell’ambito di un reclamo legale.</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Diritto di limitare il trattamento dei propri dati personali, se:</span>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">l’accuratezza dei dati viene contestata dall’utente, durante il periodo in cui la società è tenuta a verificarne l’accuratezza;</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">il trattamento è illegale e l’utente si oppone all’eliminazione dei propri dati personali richiedendo la limitazione;</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">la Società non necessita più dei dati personali dell’utente a scopo di trattamento, ma questi sono necessari all’utente per reclami legali;</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">l’utente si è opposto al trattamento, per il periodo in cui la Società è tenuta a verificare la presenza di basi legittime sovrastanti.</span></li>
</ul>
</li>
</ul>
<p>&nbsp;</p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Diritto alla portabilità dei dati. L’utente può chiedere di ricevere i dati personali su cui nutre dubbi. È anche possibile richiedere l’invio di tali dati personali a terze parti, ove possibile. Tale diritto è esercitabile solo se relativo ai dati personali forniti alla società, se il trattamento è soggetto a consenso o necessario per l’esecuzione di un contratto tra utente e società e se il trattamento viene effettuato attraverso mezzi automatici.</span></li>
</ul>
<p><span style="font-weight: 400;">L’utente può esercitare diversi diritti tramite la sezione relativa ai dati personali del proprio Profilo.&nbsp;</span></p>
<p><span style="font-weight: 400;">L’accesso ai propri dati personali (o l’esercizio di eventuali altri diritti descritti nella presente Normativa sulla privacy) è gratuito. Tuttavia, Stendhapp potrebbe applicare una tariffa ragionevole se la richiesta è chiaramente infondata, ripetitiva o eccessiva.</span></p>
<p><span style="font-weight: 400;">La Società si riserva di richiedere informazioni specifiche agli utenti per confermare la loro identità e il diritto di accedere ai dati personali (o di esercitare altri diritti). Si tratta di una misura di sicurezza atta a garantire che i dati personali non vengano divulgati a persone che non hanno diritto di riceverli. A scopo di accelerare i tempi di risposta, la Società potrebbe contattare l’utente per richiedere ulteriori informazioni relative alla richiesta.</span></p>
<ol start="7">
<li><b> Tutela dei dati personali</b></li>
</ol>
<p><span style="font-weight: 400;">Stendhapp desidera garantire agli utenti la massima serenità nell’uso del Sito e si impegna a tutelare i dati personali raccolti. Sebbene nessun sito web possa garantire la sicurezza assoluta, Stendhapp implementa e attua misure fisiche, amministrative, tecniche e organizzative atte a tutelare i dati personali forniti dagli utenti contro l’accesso illecito o non autorizzato, la divulgazione non consentita e la perdita accidentale, i danni, l’alterazione o la distruzione dei dati.</span></p>
<p><span style="font-weight: 400;">Ad esempio, solo i dipendenti autorizzati possono accedere ai dati personali ed esclusivamente per l’espletamento delle funzioni aziendali consentite. Inoltre, Stendhapp utilizza la crittografia per la trasmissione dei dati personali tra sistema dell’utente e sistema della Società. Stendhapp si serve altresì di firewall e sistemi di rilevamento delle intrusioni per impedire a persone non autorizzate di accedere ai dati personali degli utenti.</span></p>
<ol start="8">
<li><b> Informazioni relative ai minori</b></li>
</ol>
<p><span style="font-weight: 400;">Stendhapp è un Sito dedicato a un pubblico generale e non offre servizi per </span><span style="font-weight: 400;">bambini. La Società non raccoglie consapevolmente dati di minori al di sotto dei 14 anni. Se un utente notoriamente di età inferiore a 14 anni</span><span style="font-weight: 400;"> trasmette dati personali alla Società, le informazioni vengono eliminate o distrutte appena possibile.</span></p>
<ol start="9">
<li><b> Link esterni</b></li>
</ol>
<p><span style="font-weight: 400;">Il Sito potrebbe includere link a siti web, plug-in e applicazioni di terze parti. Se l’utente accede ad altri siti web dai link forniti sul Sito, gli operatori di tali siti web potrebbero raccogliere o condividere informazioni dell’utente. Tali informazioni verranno trattate secondo le normative sulla privacy di tali siti, che potrebbero differire dalla presente Normativa sulla privacy. Stendhapp non controlla i siti web di terze parti, si consiglia pertanto di prendere visione delle normative sulla privacy pubblicate su tali siti al fine di comprenderne le procedure di raccolta, trattamento e divulgazione dei dati personali.</span></p>
<ol start="10">
<li><b> Modifiche alla Normativa sulla privacy</b></li>
</ol>
<p><span style="font-weight: 400;">Stendhapp si riserva di modificare la presente Normativa sulla privacy di tanto in tanto. La data dell’ultima revisione verrà riportata sulla prima pagina della Normativa sulla privacy e le revisioni avranno effetto a decorrere dalla data di pubblicazione. La Società notificherà agli utenti le variazioni sostanziali della presente Normativa sulla privacy attraverso avvisi inoltrati all’indirizzo email specificato o pubblicando un annuncio sul Sito. Si consiglia di prendere regolarmente visione della presente Normativa sulla privacy in modo da essere sempre aggiornati alla versione più attuale.</span></p>
<ol start="11">
<li><b> Contatti</b></li>
</ol>
<p><span style="font-weight: 400;">In caso di ulteriori domande o reclami circa il trattamento dei propri dati personali da parte della Società,&nbsp;</span><a href="mailto:servizioclienti@stendhapp.com"><span style="font-weight: 400;">fare clic qui</span></a><span style="font-weight: 400;">. L’utente dispone anche del diritto di inoltrare reclami sul trattamento dei propri dati personali da parte della Società alle autorità competenti. La Società invita, tuttavia, gli utenti a darle la possibilità di affrontare eventuali dubbi contattandola prima di rivolgersi alle autorità competenti.</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">Contatti completi:</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">Stendhapp srl – Startup Innovativa A Vocazione Sociale</span></p>
<p><span style="font-weight: 400;">Via Settembrini, 52 – Milano – Italia</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">Att.: Responsabile della Privacy</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">Email: servizioclienti@stendhapp.com</span></p>
<p><span style="font-weight: 400;">Stendhapp utilizza anche dati aperti di proprietà della Pubblica Amministrazione il cui uso è regolato dalla licenza disponibile al seguente link </span><a href="https://www.dati.gov.it/content/italian-open-data-license-v20"><span style="font-weight: 400;">https://www.dati.gov.it/content/italian-open-data-license-v20</span></a></p>
	</div>
`