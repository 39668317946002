<template>
	<div class="profile-page flex column">
		<v-ons-toolbar modifier="noshadow transparent cover-content">
			<div class="left">
				&nbsp;
				<!--<v-ons-toolbar-button icon="md-settings" @click="openSettings"></v-ons-toolbar-button>-->
			</div>
			<div class="center">{{ $t("Profile") }}</div>
			<div class="right">
				&nbsp;
				<!--<v-ons-toolbar-button icon="md-search"></v-ons-toolbar-button>-->
			</div>
		</v-ons-toolbar>

		<div class="header">
			<div class="welcome">{{ $t("Hello") }} {{ username }}!</div>
			<div class="message">{{ $t("What would you like to see next?") }}</div>
		</div>

		<div class="search-box">
			<div class="input-wrap">
				<div class="input flex center">
					<div class="image-box"><img src="map-icons/ico-current.png" alt="geo-icon" /></div>
					<input class="box grow" :placeholder="$t('Enter here the name of a place')" v-model="mapSearch" />
					<div v-if="mapSearch" class="map-search mr-5" @click="resetMapSearch"><i class="mdi mdi-close-circle-outline"></i></div>
					<div v-if="mapSearch" class="map-search" @click="setMapSearch"><i class="mdi mdi-arrow-right-bold-circle-outline"></i></div>
				</div>
			</div>
		</div>

		<div class="labels-box flex justify-space-between">
			<div class="left">{{ $t("My favorite places") }}</div>
			<div class="right" v-if="liked.length" @click="openPlacesListPage('liked')">{{ $t("View All") }}</div>
		</div>
		<div class="liked-box">
			<div v-if="!liked.length" class="liked-item" :style="'background-image:url(' + getImage(null) + ')'" @click="openMap">
				<div class="title">{{ $t("Add a place to your favorites") }}</div>
			</div>
			<div
				class="liked-item"
				v-for="item in liked"
				:key="item._id"
				:style="'background-image:url(' + getImage(item) + ')'"
				@click="clickPoint(item)"
			>
				<div class="title">{{ item.name }}</div>
				<div class="img-box">
					<img :src="'map-icons/' + item.category + '.png'" alt="category-icon" />
				</div>
				<!--<div class="photo-count">
					{{item.photoCount}} {{$t("Pictures")}}
				</div>-->
				<div class="like-btn" :class="{ active: true }">
					<i class="mdi mdi-heart" v-if="true"></i>
					<i class="mdi mdi-heart-outline" v-if="!true"></i>
				</div>
			</div>
		</div>

		<div class="labels-box flex justify-space-between">
			<div class="left">{{ $t("My diary") }}</div>
			<div class="right" v-if="opened.length" @click="openPlacesListPage('opened')">{{ $t("View All") }}</div>
		</div>
		<div class="liked-box">
			<div v-if="!opened.length" class="liked-item" :style="'background-image:url(' + getImage(null) + ')'" @click="openMap">
				<div class="title">{{ $t("Open a place") }}</div>
			</div>
			<div
				class="liked-item"
				v-for="item in opened"
				:key="item._id"
				:style="'background-image:url(' + getImage(item) + ')'"
				@click="clickPoint(item)"
			>
				<div class="title">{{ item.name }}</div>
				<div class="img-box">
					<img :src="'map-icons/' + item.category + '.png'" alt="category-icon" />
				</div>
				<!--<div class="photo-count">
					{{item.photoCount}} {{$t("Pictures")}}
				</div>-->
			</div>
		</div>

		<div class="labels-box flex justify-space-between">
			<div class="left">{{ $t("The places I shared") }}</div>
			<div class="right" v-if="shared.length" @click="openPlacesListPage('shared')">{{ $t("View All") }}</div>
		</div>
		<div class="liked-box">
			<div v-if="!shared.length" class="liked-item" :style="'background-image:url(' + getImage(null) + ')'" @click="openMap">
				<div class="title">{{ $t("Share a place") }}</div>
			</div>
			<div
				class="liked-item"
				v-for="item in shared"
				:key="item._id"
				:style="'background-image:url(' + getImage(item) + ')'"
				@click="clickPoint(item)"
			>
				<div class="title">{{ item.name }}</div>
				<div class="img-box">
					<img :src="'map-icons/' + item.category + '.png'" alt="category-icon" />
				</div>
				<!--<div class="photo-count">
					{{item.photoCount}} {{$t("Pictures")}}
				</div>-->
			</div>
		</div>

		<!--
		<div class="labels-box flex justify-space-between">
			<div class="left">{{$t("The places I shared")}}</div>
			<div class="right" @click="openPlacesListPage('best-shared')">{{$t("View All")}}</div>				 
		</div>
		<div class="best-shared-box">
			<div class="a" style="background-image:url(images/demo/1.jpg)">
				<div class="title">Colosseum</div>
				<div class="img-box">
					<img src="map-icons/museums.png" alt="category-icon">
				</div>
			</div>
			<div class="b" style="background-image:url(images/demo/2.jpg)">
				<div class="title">Colosseum</div>
				<div class="img-box">
					<img src="map-icons/museums.png" alt="category-icon">
				</div>
			</div>
			<div class="c" style="background-image:url(images/demo/3.jpg)">
				<div class="title">Colosseum</div>
				<div class="img-box">
					<img src="map-icons/museums.png" alt="category-icon">
				</div>
			</div>
			<div class="d" style="background-image:url(images/demo/4.jpg)">
				<div class="title">+13</div>
			</div>
		</div>
		-->

		<!--<div class="labels-box flex justify-space-between">
			<div class="left">{{$t("My diary")}}</div>
			<div class="right" @click="openPlacesListPage('best-city')">{{$t("View All")}}</div>				 
		</div>
		<div class="experiences-box">
			<div class="experiences-item" v-for="(item, index) in experiences" :key="item.type">
				<div class="img-box" :style="'background-image:url('+'images/demo/'+(index+1)+'.jpg'+')'"></div>
				<div class="info-box">
					<div class="title">{{item.title}}</div>
					<div class="places">{{item.placesCount}} {{$t("Places")}}</div>
					<div class="description">{{item.description}}</div>
					<div class="media">{{item.postsCount}} {{$t("Posts")}} | {{item.photoCount}} {{$t("Pictures")}}</div>
				</div>
			</div>
		</div>-->

		<!--
		<div class="labels-box flex justify-space-between">
			<div class="left">{{$t("Suggested locations")}}</div>
			<div class="right" @click="openPlacesListPage('recommendations')">{{$t("View All")}}</div>				 
		</div>
		<div class="recommendations-box">
			<div class="recommendations-item" v-for="(item, index) in recommendations" :key="item.type" :style="'background-image:url('+'images/demo/'+(index+1)+'.jpg'+')'">
				<div class="title">{{item.title}}</div>
				<div class="img-box">
					<img :src="'map-icons/'+item.type+'.png'" alt="category-icon">
				</div>
			</div>
		</div>
		-->

		<div class="buttons-box">
			<v-ons-button @click="openSettings">{{ $t("Settings") }}</v-ons-button>
			<v-ons-button @click="openEditUser">{{ $t("Edit your data") }}</v-ons-button>
			<v-ons-button @click="openChangePassword">{{ $t("Edit password") }}</v-ons-button>
			<v-ons-button @click="logout">{{ $t("Logout") }}</v-ons-button>
			<!--
			<v-ons-button @click="viewPrivacyPolicy">{{$t('View Privacy Policy')}}</v-ons-button>
			-->
			<a
				v-if="language === 'it'"
				class="link"
				href="https://docs.google.com/forms/d/e/1FAIpQLSfOYMwFVOh4pVay-6-c4sRN10PnjtrMA_zAKs5MIA5Z7kRlaQ/viewform?usp=sf_link"
				target="_blank"
			>
				Hai 3 minuti?
			</a>
			<a
				class="link"
				:href="langIt ? `https://stendhapp.com/faq/` : `https://stendhapp.com/en/faq-en/`"
				target="_blank"
				rel="nofollow noopener noreferrer"
			>
				{{ $t("View FAQ") }}
			</a>
			<a
				class="link"
				:href="langIt ? `https://stendhapp.com/privacy-policy/` : `https://stendhapp.com/en/privacy-policy-en/`"
				target="_blank"
				rel="nofollow noopener noreferrer"
			>
				{{ $t("Privacy Policy") }}
			</a>
			<a
				class="link"
				:href="langIt ? `https://stendhapp.com/accordo-di-servizio/` : `https://stendhapp.com/en/service-agreement/`"
				target="_blank"
				rel="nofollow noopener noreferrer"
			>
				{{ $t("Service Agreement") }}
			</a>
		</div>

		<p class="copy-wrap">
			<small>{{ $t("footer_copy") }}</small
			><br />
			<small>©2019-{{ copyYear }} Stendhapp</small>
		</p>

		<v-ons-modal :visible="placesListPageModalVisible" animation="fade">
			<places-list-page @close="placesListPageModalVisible = false" :type="placesListType"></places-list-page>
		</v-ons-modal>
	</div>
</template>

<script>
import _ from "lodash"
import api from "../api"
import PlacesListPage from "./PlacesListPage"
import { EventBus } from "@/event-bus.js"
import { Const } from "../const"
import it_privacy from "../locale/it/privacy"
import en_privacy from "../locale/en/privacy"

export default {
	name: "profile-page",
	data() {
		return {
			ld: _,
			copyYear: new Date().getFullYear(),
			mapSearch: "",
			placesListPageModalVisible: false,
			placesListType: null,
			liked: [],
			shared: [],
			opened: [],
			experiences: [
				{
					type: "museums",
					title: "Milano",
					placesCount: 12,
					postsCount: 21,
					photoCount: 79,
					description: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet."
				},
				{
					type: "monuments",
					title: "Roma",
					placesCount: 8,
					postsCount: 23,
					photoCount: 43,
					description: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet."
				},
				{
					type: "church",
					title: "Venezia",
					placesCount: 15,
					postsCount: 43,
					photoCount: 32,
					description: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet."
				}
			],
			recommendations: [
				{ type: "museums", title: "Plaza" },
				{ type: "monuments", title: "Grand" },
				{ type: "church", title: "Grand" },
				{ type: "art", title: "Plaza" }
			],
			photodir: Const.photodir,
			tempdir: Const.tempdir,
			BASE_URL: api.getBaseUrl(),
			DOMAIN_URL: api.getDomainUrl()
		}
	},
	computed: {
		isLogged() {
			return this.$store.state.isLogged
		},
		username() {
			return this.$store.getters.username
		},
		language() {
			return this.$store.getters.language
		},
		langIt() {
			return this.$i18n.locale === "it"
		}
	},
	methods: {
		logout() {
			this.$store.commit("logout", null)
			window.location.reload()
		},
		openSettings() {
			this.$emit("openSettings")
		},
		openEditUser() {
			EventBus.$emit(`open:edituser`, {})
		},
		openChangePassword() {
			EventBus.$emit(`open:change-password`, {})
		},
		openPlacesListPage(type) {
			this.placesListPageModalVisible = true
			this.placesListType = type
		},
		getLikedPlaces() {
			api.pdi.getLikedPlaces().then(data => {
				console.log("getLikedPlaces", data)

				if (data && data.status && data.status === 200 && data.body) {
					this.liked = _.orderBy(data.body, p => p.likedDate, "desc")
				}
			})
		},
		getSharedPlaces() {
			api.pdi.getSharedPlaces().then(data => {
				console.log("getSharedPlaces", data)

				if (data && data.status && data.status === 200 && data.body) {
					this.shared = _.orderBy(data.body, p => p.sharedDate, "desc")
				}
			})
		},
		getOpenedPlaces() {
			api.pdi.getOpenedPlaces().then(data => {
				console.log("getOpenedPlaces", data)

				if (data && data.status && data.status === 200 && data.body) {
					this.opened = _.orderBy(data.body, p => p.openedDate, "desc")
				}
			})
		},
		clickPoint(item) {
			this.$emit("clickPoint", item)
		},
		getImage(item) {
			if (item && item.photo) return encodeURI(this.DOMAIN_URL + this.photodir + "/" + item.photo)
			else return "images/placeholder.jpg"
		},
		openMap() {
			this.$emit("openMap")
		},
		resetMapSearch() {
			this.mapSearch = ""
			this.$store.commit("setMapSearch", this.mapSearch)
		},
		setMapSearch() {
			this.$store.commit("setMapSearch", this.mapSearch)
			EventBus.$emit(`open:map`)
		},
		viewPrivacyPolicy() {
			switch (this.$i18n.locale) {
				case "it":
					this.$ons.notification.alert(it_privacy, {
						title: this.$t("Privacy Policy")
					})
					break
				case "en":
				default:
					this.$ons.notification.alert(en_privacy, {
						title: this.$t("Privacy Policy")
					})
					break
			}
		}
	},
	mounted() {
		if (this.isLogged) {
			this.getLikedPlaces()
			this.getSharedPlaces()
			this.getOpenedPlaces()
		}

		EventBus.$on("init:profile-page", () => {
			console.log("init:profile-page")
			this.getLikedPlaces()
			this.getSharedPlaces()
			this.getOpenedPlaces()
		})
	},
	components: {
		PlacesListPage
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.profile-page {
	background: white;
	box-sizing: border-box;

	.toolbar {
		.toolbar__title {
			color: #000;
		}
		.toolbar-button {
			color: #000;
			opacity: 0.5;
		}
		.toolbar--material__center {
			text-align: center;
		}
	}

	.header {
		background: #dbe8ff;
		background: -moz-linear-gradient(top, #ffffff 0%, #dbe8ff 100%);
		background: -webkit-linear-gradient(top, #ffffff 0%, #dbe8ff 100%);
		background: linear-gradient(to bottom, #ffffff 0%, #dbe8ff 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dbe8ff',GradientType=0 );
		padding: 10px;
		padding-top: 50px;
		padding-bottom: 35px;

		.welcome {
			color: #000;
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 10px;
		}
		.message {
			color: #000;
			opacity: 0.7;
		}
	}

	.search-box {
		margin: 14px;
		margin-top: -20px;
		background: #fff;
		box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
		border-radius: 4px;
		overflow: hidden;

		.input-wrap {
			padding: 0px 20px;

			.input {
				padding: 6px 0;

				.image-box {
					border: 1px dotted #979797;
					border-radius: 50%;
					width: 28px;
					height: 28px;
					text-align: center;
					line-height: 37px;

					img {
						width: 70%;
					}
				}

				input {
					font-weight: 600;
					font-size: 15px;
					margin: 0;
					border: none;
					outline: none;
					padding: 0 10px;
					color: #8677ad;
				}

				.map-search {
					color: #8677ad;
					font-size: 25px;
					cursor: pointer;
					margin-left: 5px;
				}
			}
		}
	}

	.labels-box {
		padding: 10px;
		font-size: 14px;

		.left {
			font-weight: 500;
		}
		.right {
			opacity: 0.5;
			cursor: pointer;
		}
	}

	.liked-box {
		overflow: hidden;
		overflow-x: scroll;
		white-space: nowrap;
		padding: 10px;
		padding-bottom: 20px;

		.liked-item {
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			width: 38%;
			padding-bottom: 50%;
			display: inline-block;
			margin-right: 15px;
			border-radius: 4px;
			position: relative;
			box-shadow: 0px 0px 40px 0px #000 inset, 0px 3px 10px 0px rgba(100, 100, 100, 0.4);
			cursor: pointer;

			.title {
				color: #fff;
				font-size: 14px;
				position: absolute;
				top: 10px;
				left: 10px;
				text-transform: capitalize;
				width: 50%;
				display: block;
				white-space: initial;
			}

			.img-box {
				width: 25%;
				padding-top: 3%;
				padding-bottom: 3%;
				box-sizing: border-box;
				background: #fff;
				border-radius: 4px;
				opacity: 0.9;
				text-align: center;
				display: block;
				position: absolute;
				top: 10px;
				right: 10px;

				img {
					width: 60%;
				}
			}

			.photo-count {
				color: #fff;
				font-size: 12px;
				position: absolute;
				bottom: 10px;
				left: 10px;
				text-transform: capitalize;
				width: 50%;
				display: block;
				white-space: initial;
				opacity: 0.7;
			}

			.like-btn {
				color: #fff;
				font-size: 20px;
				width: 25%;
				line-height: 1;
				box-sizing: border-box;
				opacity: 0.9;
				text-align: right;
				display: block;
				position: absolute;
				bottom: 10px;
				right: 10px;

				&.active {
					//color: #f3587f;
					color: #6234fd;
					text-shadow: 0px 0px 2px #fff, 0px 0px 2px #fff, 0px 0px 2px #fff, 0px 0px 2px #fff, 0px 0px 2px #fff;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.best-shared-box {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		grid-template-areas: "a a a b b b b b" "a a a c c c d d";
		height: 200px;
		grid-column-gap: 15px;
		grid-row-gap: 15px;
		padding: 10px;
		padding-bottom: 20px;

		& > div {
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			border-radius: 4px;
			position: relative;
			box-shadow: 0px 0px 40px 0px #000 inset, 0px 3px 10px 0px rgba(100, 100, 100, 0.4);

			.title {
				color: #fff;
				font-size: 14px;
				position: absolute;
				top: 10px;
				left: 10px;
				text-transform: capitalize;
				width: 50%;
				display: block;
				white-space: initial;
			}

			.img-box {
				width: 20px;
				padding-top: 2px;
				padding-bottom: 2px;
				box-sizing: border-box;
				background: #fff;
				border-radius: 4px;
				opacity: 0.9;
				text-align: center;
				display: block;
				position: absolute;
				top: 10px;
				right: 10px;
				line-height: 14px;

				img {
					width: 60%;
				}
			}
		}

		.a {
			grid-area: a;
		}

		.b {
			grid-area: b;
		}

		.c {
			grid-area: c;
		}

		.d {
			grid-area: d;
		}
	}

	.recommendations-box {
		overflow: hidden;
		overflow-x: scroll;
		white-space: nowrap;
		padding: 10px;
		padding-bottom: 20px;

		.recommendations-item {
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			width: 38%;
			padding-bottom: 50%;
			display: inline-block;
			margin-right: 15px;
			border-radius: 4px;
			position: relative;
			box-shadow: 0px 0px 40px 0px #000 inset, 0px 3px 10px 0px rgba(100, 100, 100, 0.4);

			.title {
				color: #fff;
				font-size: 14px;
				position: absolute;
				top: 10px;
				left: 10px;
				text-transform: capitalize;
				width: 50%;
				display: block;
				white-space: initial;
			}

			.img-box {
				width: 25%;
				padding-top: 3%;
				padding-bottom: 3%;
				box-sizing: border-box;
				background: #fff;
				border-radius: 4px;
				opacity: 0.9;
				text-align: center;
				display: block;
				position: absolute;
				top: 10px;
				right: 10px;

				img {
					width: 60%;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.experiences-box {
		overflow: hidden;
		overflow-x: scroll;
		white-space: nowrap;
		padding: 10px;
		padding-bottom: 20px;

		.experiences-item {
			width: 85%;
			padding-bottom: 50%;
			display: inline-block;
			margin-right: 15px;
			border-radius: 4px;
			position: relative;
			overflow: hidden;
			box-shadow: 0px 0px 40px 0px #000 inset, 0px 3px 10px 0px rgba(100, 100, 100, 0.4);

			.img-box {
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;
				width: 33%;
				display: block;
				position: absolute;
				top: 0px;
				left: 0px;
				bottom: 0px;
			}

			.info-box {
				background: #fff;
				width: 67%;
				display: block;
				position: absolute;
				top: 0px;
				right: 0px;
				bottom: 0px;
				color: #000;

				.title {
					font-size: 14px;
					position: absolute;
					top: 10px;
					left: 10px;
					text-transform: capitalize;
					width: 50%;
					display: block;
					white-space: initial;
					font-weight: 500;
				}
				.places {
					font-size: 12px;
					position: absolute;
					top: 10px;
					right: 10px;
					text-transform: capitalize;
					width: 50%;
					display: block;
					white-space: initial;
					text-align: right;
				}
				.description {
					font-size: 12px;
					position: absolute;
					top: 30px;
					left: 0px;
					text-transform: capitalize;
					width: 100%;
					padding: 10px;
					display: block;
					white-space: initial;
					box-sizing: border-box;
					opacity: 0.5;
				}
				.media {
					font-size: 12px;
					position: absolute;
					bottom: 0px;
					left: 0px;
					text-transform: capitalize;
					width: 100%;
					padding: 10px;
					display: block;
					white-space: initial;
					color: #6440f9;
					box-sizing: border-box;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.buttons-box {
		//padding: 10px;
		box-sizing: border-box;

		.button {
			margin: 10px;
		}

		.link {
			margin: 10px;
			display: inline-block;
			cursor: pointer;
			color: #6234fd;
			text-decoration: underline;
			display: block;
		}
	}

	.copy-wrap {
		padding: 20px;
		opacity: 0.5;
		text-align: center;
	}
}
</style>
