export default `
<div class="wpb_wrapper">
		<p><span style="font-weight: 400;">Stendhapp srl (“Stendhapp”, “the Company” “we”, “our” or “us”) provides a non profit online service to its users (“User(s)” or “you”) with a mobile application platform and web services providing suggestions to people that wish to discover the artistic and cultural heritage of Italy and much more. It also offers to the operators of the cultural sector (“supplier(s)”) information about visitors and potential visitors of their area. The constituents of mobile apps, web apps and website (“app(s)”, “site(s)”, “website(s)”) are a property of and are managed by Stendhapp srl.</span></p>
<p><span style="font-weight: 400;">By Accessing or using our service you declare to have read and understood this Privacy Notice, accepting the practices described in it.&nbsp;</span></p>
<p><span style="font-weight: 400;">Last update of this Privacy Notice is May 2nd, 2020. Stendhapp reserves the right to change this notice at any time, so we encourage you to re-visit this page frequently.</span></p>
<p><b>General information</b></p>
<p><span style="font-weight: 400;">Stendhapp is committed to your privacy. This Privacy Notice is intended to inform you of our collection, use, disclosure, retention, and protection of your personal information when using our Services. Stendhapp knows that providing personal data online implies a high level of trust from the Users. That’s why we take your privacy very seriously and we give high priority to the security of the personal data you provide us by using our Sites and Services.</span></p>
<ol>
<li style="font-weight: 400;"><span style="font-weight: 400;">Personal data collection</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Personal data processing</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Sharing of personal data</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Personal data storage</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Cookies and similar web technologies</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">User’s rights</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Personal data protection</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Information on minors</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">External links</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Privacy Notice modifications</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Contact us</span></li>
</ol>
<p>&nbsp;</p>
<ol>
<li><b> Personal data collection</b></li>
</ol>
<p><span style="font-weight: 400;">Personal information is individually identifiable information about a person, e.g. email, birth date, password or payment details. Personal information does not include information that has been anonymized or aggregated so that it can no longer be used to identify a specific natural person (anonymous data).</span></p>
<p><span style="font-weight: 400;">During our activities we collect and process personal information from you and from any devices (including mobile devices) you use when you install or have the app installed on your device, or when you use or access our Services, register for an account with us, provide us with information such as your favourites, run searches on our Services, update or add information to your account, or through correspondence you conduct with us or with the usage of cookies on our Sites. We may also receive information from third party partners (more details will follow).</span></p>
<p><span style="font-weight: 400;">Personal data may include, for example: name, email, address, phone number, invoicing data, survey responses, booking data, location information and other information the users is sharing on our Sites, the IP address and the browser used.</span></p>
<p><span style="font-weight: 400;">You may choose not to provide your personal data to the Company. However, certain information is necessary to use some of the features of the Sites. For instance, only geolocalized users will be able to receive information about places and events around them, to provide information on locations, events and accessibility and to participate to polls, sweepstakes and promotions.</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Information you actively give us: as described below, Stendhapp receives and stores any information, data or content you actively and voluntarily create, upload, or provide us through our Sites or any other means.&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Personal information collected from other sources: Stendhapp may also collect personal information concerning you from third parties, business partners or independent sources and add them to the users data in storage. For example, if a user gets redirected to Stendhapp.com by a website of a commercial partner of the Company and previously had shared information, as contacts or demographics, with that site, this information may be shared with Stendhapp as well. Equally, if a user accesses a third party service, for example a service offered by a social media, through the Sites or before visiting the Sites, some information collected by Stendhapp, as username and other data, may be made available according to the privacy notice of the third party. Stendhapp collaborates with existing and future partners to improve and customize the usage of the Sites, in line with what described within this Privacy Notice.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Personal information we collect automatically when you use our Services from your computer or other devices: your IP address, information regarding your clicks, views and engagement with the Services, the browser used, information concerning your traffic to and from the Services, your referral URL, our communications with you, your historical searches on the Services, information about your use and your interaction with our Services, including the duration of such use; your in-app settings, pages and searches conducted. This information is collected to better understand the Users’ interests and references, to further customize their experience and to create audience segments (segmenting them based on criteria as demographics and usage profile). The Company also collects personal data to protect the Sites and other Users for improper use.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Geo-Location Information and other information obtained from devices: when you use our Sites on a device, with a browser or mobile app, Stendhapp automatically collects data as described above. In particular, we may collect location information such as your geo-location if the user has configured the device to send this information to Stendhapp. The information on your position allows, among other things, to display to you the information about beautiful places and events nearby while you are moving. In any moment it is possible to change the privacy settings of the device and stop sharing information with the Sites. Please note: disabling the location information sharing may affect important features of the app. In case of questions on the privacy settings of your device we suggest to contact the phone manufacturer or the service provider.</span></li>
</ul>
<ol start="2">
<li><b> Personal data processing</b></li>
</ol>
<p><span style="font-weight: 400;">We use information that we collect about you for specific purposes. Those include, for example, to provide you with and to operate the Service, as registration of the account, access and usage of the Sites, sharing of information, scores and pictures, technical assistance and support, send push notifications in case of changes in the terms of use of the privacy policy, participate to polls, manage and improve the performance of the Sites and the Services, customize the user experience on Sites, measure the engagement and improve Sites and Services, send notifications on promotions and special offers, facilitate the participation to sweepstakes, promotions and polls, protect from, investigate on, discourage fraudulent, illegal, not authorized, illegitimate&nbsp; activities, manage and protect the Company’s business and resolve disputes or problems.</span></p>
<p><span style="font-weight: 400;">The Company uses the personal data of the Users only in the cases permitted by the law. Most commonly, the Company uses personal data in the following circumstances:</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">To offer its Services to the Users</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">In case of need to protect the legitimate interests of the Company (or third parties); the interests and fundamental rights of the user do not take precedence over those interests</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">With the consent of the User</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">To comply with legal requirements.</span></li>
</ul>
<p><b>Marketing</b></p>
<p><span style="font-weight: 400;">The Company is committed to simplify the user experience of the Sites and to allow all the Users to discover the beauty of the territory around them. With this purpose Stendhapp sends email messages with information in line with the interests of the Users. For example, if the User is interested in music, may receive an email about a concert in the area of last area he had visited. The Company believes that those emails provide to the Users valuable information about the events displayed on its Sites.</span></p>
<p><span style="font-weight: 400;">The Users will receive marketing messages from the Company only if they have provided their consent and they have chosen not to revoke their consent to receive marketing messages.</span></p>
<p><span style="font-weight: 400;">Every time you receive a marketing message it’s possible to unsubscribe from receiving emails, stop receiving push notifications and marketing communication from the app, by changing the settings of your device or of the app. You can unsubscribe from marketing communication at any time, clicking on the link included in every communication received by Stendhapp or sending a message to servizioclienti@stendhapp.com.</span></p>
<p><span style="font-weight: 400;">Anyway, Stendhapp may contact you with information regarding our Services, or your use thereof. For example, we may notify you (through any of the means available to us) of changes or updates to our Services, administrative and&nbsp; service maintenance messages, messages related to your account and to feedback received from you about locations, events and accessibility. You will not be able to opt-out of receiving such service messages.</span></p>
<ol start="3">
<li><b> Sharing of personal data</b></li>
</ol>
<p><span style="font-weight: 400;">Stendhapp </span><b>will not share</b><span style="font-weight: 400;"> the data of the users, with the exception of the following cases:</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">We may disclose or otherwise allow access to your personal information pursuant to a legal request, such as a subpoena, search warrant or court order, or in compliance with applicable laws; to help establish and protect our rights; in order to defend ourselves in case of disputes; in other cases permitted by law. In such instances, the Company reserves the right to raise or waive any legal objections.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">in connection with efforts to investigate, prevent, or take action regarding actual or suspected illegal activity; if we believe that this will help protect the rights, property or personal safety of Stendhapp, any of our Users, or any member of the general public; in accordance with the Terms of Use and other agreements.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">should Stendhapp undergo any change in control, including by means of merger, acquisition or purchase of substantially all of its assets or in case of default.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">third-party service providers appointed by and acting on behalf of Stendhapp to complement our services (see below)</span></li>
</ul>
<p><span style="font-weight: 400;">When the User creates a profile on Stendhapp, the information in the profile will not be publicly visible nor accessible through the app. Moreover, if the User shares on social media a picture of a place visited thanks to Stendhapp, the picture will be visible only on the User’s social media pages and only by the User’s contacts, according to the preferences of the User and it will not be visible by other Users of Stendhapp. Stendhapp does not share email addresses with other Users and does not disclose them through other media. If the User decides to provide to the Company user generated content, the texts and the photos will be published on Stendhapp for the benefit of all Users of the platform and the name of User that has provided it will be displayed. When a User voluntarily provides content to Stendhapp, Stendhapp automatically obtains all the usage rights for that content.</span></p>
<p><span style="font-weight: 400;">Suppliers,</span><span style="font-weight: 400;"> as for example, suppliers of activities, operators or owners of museums, theatres, events, tour guides, associations and entities that offer services in the art and culture sector covered by the app. On Stendhapp Site and partner sites the services offered by third-party suppliers are described as such. The information shared with suppliers are treated in line with the privacy policies of the supplier, with exception of payment details. Stendhapp shares payment details only if they are needed to allow booking made on or through Stendhapp: the usage of those payment details by the suppliers is limited exclusively to these purposes. In some cases Stendhapp may send requests of information to third-party sites or to safely transmit them to the supplier of the service being booked to perform the transaction. Note: unless otherwise specified, it’s the supplier to perform the transaction, not Stendhapp. The User accepts to check and be linked to the supplier’s procedures and policies before booking through Stendhapp. Stendhapp encourages the Users to check the privacy notice, the terms of use and other policies of the suppliers, whose product and services gets purchased through Stendhapp.</span></p>
<p><span style="font-weight: 400;">Third Party Services</span><span style="font-weight: 400;"> whose services and solutions complement, facilitate and enhance our own. These include data analytics services, payment processors, customer service, marketing, external relations, polls and market research, promotions and cyber security services. Such Third Party Services may receive or otherwise have access to our Users’ personal information, as for example data analytics for usability and functionality monitoring of our Sites or to improve the performance of online communication according to the User’s preferences, and only use it for such purposes.</span></p>
<p><span style="font-weight: 400;">The Company may transfer, share or otherwise use anonymized, aggregated or non-personal information with third-party as investors and advertisers. For example, Stendhapp may communicate to advertisers the number of visits received by the Sites or the locations visited. This information does not contain any personal data and is used to develop content and services of interest to users.</span></p>
<ol start="4">
<li><b> Personal data storage</b></li>
</ol>
<p><span style="font-weight: 400;">Stendhapp will retain your personal information for as long as you maintain an account or as otherwise necessary to provide you the Services. We will also retain your personal information as necessary to comply with our legal obligations, to resolve disputes, to prevent fraud and abuse, to enforce our agreements and/or protect our legitimate interests.</span></p>
<p><span style="font-weight: 400;">To determine the appropriate retention period for personal data, Stendhapp takes into account the quantity, the nature and the sensitivity of data, the potential risk of damage from non authorized processing or disclosure, the purpose of the processing, the possibility to obtain the same result with different means, and the legal requirements.&nbsp;</span></p>
<p><span style="font-weight: 400;">Stendhapp may retain some information for a maximum of 10 years after the User terminates the account if, for instance, it’s necessary for legal reasons or to prevent frauds or misuse and to defend and exercise its own rights.&nbsp;</span></p>
<ol start="5">
<li><b>Cookies and similar web technologies</b></li>
</ol>
<p><span style="font-weight: 400;">The Company collects information through cookies and similar technologies (for example web beacons). Cookies are small text files that download automatically on computers and mobile devices every time you visit a website. They are filed by the browser and contain basic information on the usage of internet made by the Users. Every time a visit to Sites is made, the browser sends cookies to the Site so the computer or the mobile device of the User gets recognized, improving and customizing the experience.</span></p>
<p><span style="font-weight: 400;">Further details on cookies and similar technologies used (as web beacons) are available within the Regulatory about the use of cookies.</span></p>
<p><span style="font-weight: 400;">For more information on cookies in general, on how to visualize the cookies installed on own (mobile) device and how to manage and clear them, please refer to websites as&nbsp; www.allaboutcookies.org and www.youronlinechoices.com.</span></p>
<ol start="6">
<li><b> User’s rights</b></li>
</ol>
<p><span style="font-weight: 400;">The User has the right to access, validate, correct and update the information given to us by accessing the account settings. It’s also possible to have the account deleted by contacting Stendhapp at the address reported below.</span></p>
<p><span style="font-weight: 400;">When you decide to delete your account, it will be disabled and the User’s personal data will be removed. Once the account has been disabled, it will not be possible anymore to access personal data. However, it’s possible to open a new account at any time.</span></p>
<p><span style="font-weight: 400;">As soon as the account is active, it’s possible to change marketing preferences at any time in the Profile page.&nbsp;&nbsp;</span></p>
<p><span style="font-weight: 400;">In accordance with the current legislation, the User has the right to access, to make corrections, to object to any form of processing, or to have such personal information deleted or exported in a portable copy. Below we provide more details and information on when and how it’s possible exercise those rights. Normally we will respond to this type of requests within one month. However, the Company reserves the right to extend that period to two months if needed.</span></p>
<p><span style="font-weight: 400;">In accordance with the current legislation, the User has the following rights on own personal data:</span></p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Right to access own personal data. This allows to receive a copy of own personal data stored by the Company and to verify that the processing is legitimate.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Right to ask the correction of own data if incorrect. The User has also the possibility to integrate the information that is not complete, taking into account the scope of the processing.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Right to ask the deletion of own personal data if:&nbsp;</span>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">they are not anymore needed for the purposes for which they have been collected and processed</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">the User denies the consent, when the processing of personal data is subject to consent, and there aren’t any other legal reasons for it</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">the User objects the processing of own personal data and the Company has no legitimate reasons to keep them&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">the personal data processing has been illegally managed</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">the personal data need to be deleted to comply with legal requirements.</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">&nbsp;</span></li>
</ul>
</li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Right to object to processing of own personal data. The Company is committed to act in the interest of the Users, unless exist compelling&nbsp; legitimate reasons for the data processing or need to continue the processing for the purpose of resolution, exercise or defence in the context of a legal claim.</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Right to limit the processing of own personal data if:</span>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">the accuracy of data is questioned by the User, during the period when the Company is required to verify the data accuracy;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">the processing is illegal and the User opposes the cancellation of own personal data, requesting the limitation;&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">the Company has no more need to process the personal data of the User, but the User still needs them for legal reasons;&nbsp;</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">The User opposes the processing for a period of time when the Company is required to validate the existence legitimate grounds.</span></li>
</ul>
</li>
</ul>
<p>&nbsp;</p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Right to export data in a portable copy. The User can ask to receive the personal data on which there are doubts. It’s also possible to request to send the data to third-party, when possible. This right is exercisable only if it refers to personal data provided to the Company, if the processing is subject to consent or needed for the execution of a contract between User and Company and if the processing is performed automatically.</span></li>
</ul>
<p><span style="font-weight: 400;">The User can exercise his rights in the personal data section contained in the Profile page.</span></p>
<p><span style="font-weight: 400;">The access to own personal data (or the exercise of other rights described in this Privacy notice) is free. However, Stendhapp may apply a reasonable fee in case the request is clearly unfounded, repetitive or excessive.</span></p>
<p><span style="font-weight: 400;">The Company may request specific information to the Users to validate their identity and their right to access personal data (or to exercise other rights). It’s a security measure to guarantee that personal data do not get disclosed to people that have no rights to receive them. With the aim of accelerating the response timing, the Company may contact the User asking for more information in relation to the request of accessing the data.</span></p>
<ol start="7">
<li><b> Personal data protection</b></li>
</ol>
<p><span style="font-weight: 400;">Stendhapp is committed to the User’s peace of mind when using the Sites and to protect the personal data collected. Despite no site could grant total security, Stendhapp implements and puts in place physical, administrative, technical and organizational measures to protect the personal data provided by the Users from illegal or not authorized access, unlicensed disclosure and&nbsp; accidental loss, damage, alteration or data deletion.&nbsp;</span></p>
<p><span style="font-weight: 400;">For example only authorized associates are allowed to access the personal data and only for the allowed Company procedures. Moreover, Stendhapp uses cryptography for personal data transmission from the User’s system to the Company system. Stendhapp is also using firewalls and intrusion detection systems to prevent unauthorized people to access to Users’ personal data.</span></p>
<ol start="8">
<li><b></b> <b>Information on minors</b></li>
</ol>
<p><span style="font-weight: 400;">Stendhapp is a Site dedicated to general public and does not offer services to children. The Company doesn’t knowingly collect personal information from minors under the age of fourteen (14). If a User clearly of age below 14 is sending personal data to the Company, the information is cancelled or destroyed as quickly as possible.&nbsp;</span></p>
<ol start="9">
<li><b> External links</b></li>
</ol>
<p><span style="font-weight: 400;">The Site could embed links to websites, plug-in and third-party applications. If the User accesses other websites from the links provided by the Site, the operators of those websites could collect information on the User. Those information will be processed according to the Privacy policy of the sites visited and could differ from this Privacy notice. Stendhapp does not have control on third-party’s websites, we suggest you to check the privacy notice published on those sites to understand the procedures of collection, processing and sharing of personal data.&nbsp;</span></p>
<ol start="10">
<li><b> Privacy Notice modifications</b></li>
</ol>
<p><span style="font-weight: 400;">Stendhapp reserves the right to change this Privacy notice from time to time. The date of last revision is available on the first page of the Privacy notice and the revisions will take effect from the date of publication. The Company will notify to the Users about any substantial changes to this Privacy notice by sending email messages to the addresses in their profiles or by posting on the Site. We suggest to regularly check the Privacy notice to be always up to date on the most recent version.</span></p>
<ol start="11">
<li><b> Contact us</b></li>
</ol>
<p><span style="font-weight: 400;">In case of questions or complaints about the processing of own personal data by the Company, </span><span style="font-weight: 400;"><a href="mailto:servizioclienti@stendhapp.com">please click here</a>. </span><span style="font-weight: 400;">The User has also the right to file complaints to the competent authorities on the processing of own personal data by the Stendhapp. However, the Company encourages the Users to allow to resolve any doubts by contacting us before contacting the competent authorities.</span></p>
<p><span style="font-weight: 400;">Our Contacts:</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">Stendhapp srl – Startup Innovativa A Vocazione Sociale</span></p>
<p><span style="font-weight: 400;">Via Settembrini, 52 – Milano – Italia</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">Att.: Responsabile della Privacy</span><span style="font-weight: 400;"><br>
</span><span style="font-weight: 400;">Email: servizioclienti@stendhapp.com</span></p>
<p><span style="font-weight: 400;">Stendhapp uses also open data owned by the Public Administration. The use of open data is regulated by the license available at the following link </span><a href="https://www.dati.gov.it/content/italian-open-data-license-v20"><span style="font-weight: 400;">https://www.dati.gov.it/content/italian-open-data-license-v20</span></a></p>
	</div>
`