<template>
	<v-ons-page class="main-page">

		<!--<v-ons-toolbar modifier="noshadow transparent cover-content" :class="{['tab-index-'+tabbarIndex]:true}">
			<div class="left">
				<v-ons-toolbar-button icon="md-settings" @click="settingsModalVisible = true"></v-ons-toolbar-button>
			</div>
			<div class="center">Stendhapp</div>
			<div class="right">
				<v-ons-toolbar-button icon="md-search"></v-ons-toolbar-button>
			</div>
		</v-ons-toolbar>-->

		<v-ons-tabbar
			:index.sync="tabbarIndex"
			@prechange="changeTab"
			:tab-border="false" id="tabbar" class="flex">

			<template slot="pages">
				<v-ons-page>
					<discover-page @openSettings="settingsModalVisible = true" @openMap="tabbarIndex = 1"></discover-page>
					<!--<discover-page @openSettings="settingsModalVisible = true" @openMap="mapModalVisible = true; asyncComponent = 'map-page'"></discover-page>-->
				</v-ons-page>
				<v-ons-page>
					<map-page @clickPoint="openDetails" @close="mapModalVisible = false" @openSettings="settingsModalVisible = true"></map-page>
				</v-ons-page>
				<v-ons-page>
					<add-beauty-form></add-beauty-form>
				</v-ons-page>
				<v-ons-page>
					<profile-page @openSettings="settingsModalVisible = true" @clickPoint="openDetails" @openMap="tabbarIndex = 1"></profile-page>
				</v-ons-page>
			</template>

			<v-ons-tab :icon="'fa-home'"></v-ons-tab>
			<v-ons-tab :icon="'fa-map'"></v-ons-tab>
			<v-ons-tab :icon="'fa-plus'"></v-ons-tab>
			<v-ons-tab :icon="'fa-user'"></v-ons-tab>
		</v-ons-tabbar>

		<v-ons-modal :visible="mapModalVisible" class="map-modal" animation="fade">
			<component :is="asyncComponent" @clickPoint="openDetails" @close="mapModalVisible = false" @openSettings="settingsModalVisible = true"></component>
		</v-ons-modal>

		<v-ons-modal :visible="settingsModalVisible" animation="fade">
			<settings @close="settingsModalVisible = false"></settings>
		</v-ons-modal>

		<v-ons-modal :visible="detailsModalVisible" animation="lift">
			<details-page @close="detailsModalVisible = false" :item="itemDetail" @openShareModal="openShare"></details-page>
		</v-ons-modal>

		<v-ons-modal :visible="shareModalVisible" animation="lift">
			<share-page @close="shareModalVisible = false" :item="itemDetail"></share-page>
		</v-ons-modal>

		<v-ons-modal :visible="editUserModalVisible" animation="lift">
			<edit-user @close="editUserModalVisible = false" v-if="editUserModalVisible"></edit-user>
		</v-ons-modal>

		<v-ons-modal :visible="changePasswordModalVisible" animation="lift">
			<change-password @close="changePasswordModalVisible = false" v-if="changePasswordModalVisible"></change-password>
		</v-ons-modal>


	</v-ons-page>
</template>

<script>
import _ from 'lodash';
import MapPage from './MapPage';
import AddBeautyForm from './AddBeautyForm';
import DiscoverPage from './DiscoverPage';
import Settings from './Settings';
import DetailsPage from './DetailsPage';
import ProfilePage from './ProfilePage';
import SharePage from './SharePage';
import EditUser from './EditUser';
import ChangePassword from './ChangePassword';
import { EventBus } from '@/event-bus.js';

export default {
	name: 'home',
	data () {
		return {
			tabbarIndex: 0,
			settingsModalVisible: false,
			detailsModalVisible: false,
			shareModalVisible: false,
			editUserModalVisible: false,
			changePasswordModalVisible: false,
			mapModalVisible: false,
			mapCategory: '',
			asyncComponent: null,
			itemDetail: null
		}
	},
	computed: {
	},
	methods: {
		openDetails(item) {
			this.itemDetail = item
			this.detailsModalVisible = true
		},
		openShare(item) {
			this.itemDetail = item
			this.shareModalVisible = true
		},
		changeTab(e) {
			//history.pushState({}, 'Stendhapp®', '#')
			
			let page = ""
			switch(e.activeIndex) {
				case 0:
					page = "discover-page"
					break;
				case 1:
					page = "map-page"
					break;
				case 2:
					page = "add-beauty-form"
					break;
				case 3:
					page = "profile-page"
					break;
			}

			if (page)
				EventBus.$emit(`init:${page}`)
			
			if (page !== "map-page")
				EventBus.$emit(`map:restore-category`)
		}
	},
	mounted() {
		EventBus.$on('open:pdi', item => {
			console.log('open:pdi')
			this.openDetails(item)
		});
		EventBus.$on('open:map', (data) => {
			console.log('open:map')
			this.tabbarIndex = 1
			if (data && data.category) {
				this.mapCategory = data.category
			}
		});
		EventBus.$on('map:set-route', (data) => {
			console.log('map:set-route')
			this.tabbarIndex = 1
		});
		EventBus.$on('open:edituser', () => {
			console.log('open:edituser')
			this.editUserModalVisible = true
		});
		EventBus.$on('open:change-password', () => {
			console.log('open:change-password')
			this.changePasswordModalVisible = true
		});
	},
	components: {
		MapPage,
		AddBeautyForm,
		DiscoverPage,
		Settings,
		DetailsPage,
		ProfilePage,
		SharePage,
		EditUser,
		ChangePassword,
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.page, .page--material__content, .toolbar__center, .toolbar--material__center, input, textarea, select, button, .button, .modal, .leaflet-tooltip {
	font-family: 'Barlow', -apple-system, 'Helvetica Neue', 'Helvetica', 'Arial', 'Lucida Grande', sans-serif;
}
.page__background {
	background: white;
}
.tabbar {
	background: #1D84FF;
	overflow: hidden;

	.tabbar__button {
		color: #fff;
		opacity: .5;
	}

	.tabbar__item {
		&.active {
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
			background: #469aff;

			.tabbar__button {
				opacity: 1;
			}
		}
	}

	.tabbar--material__border {
		display: none !important;
	}
}

.modal.map-modal {
	background: #ebebeb;
} 

ons-toast {
	z-index: 10001 !important;
}
</style>
