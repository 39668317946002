<template>
	<div class="details-page flex column" :class="{ sponsor: item && item.sponsor }">
		<v-ons-toolbar modifier="noshadow transparent">
			<div class="left">
				<v-ons-toolbar-button icon="md-arrow-left" @click="closeModal"></v-ons-toolbar-button>
			</div>
			<div class="center">
				<span v-if="item && !item.isEvent">{{ $t("Place details") }}</span>
				<span v-if="item && item.isEvent">{{ $t("Event details") }}</span>
			</div>
			<div class="right"></div>
		</v-ons-toolbar>

		<div class="page-wrapper box grow">
			<div class="page-content" v-show="itemFull">
				<div class="image-box" :style="'background-image:url(' + itemPhoto + ')'" @click="openPhotoFeedback(itemFull)">
					<div class="like-btn" :class="{ active: likeActive }" @click="toggleLikedPlace($event)" v-if="item && !item.isEvent">
						<i class="mdi mdi-heart" v-if="likeActive"></i>
						<i class="mdi mdi-heart-outline" v-if="!likeActive"></i>
					</div>
					<div class="nav-text" @click="saveTargetPos($event, itemFull)" v-if="canGeolocation">
						{{ $t("set this place as a target") }}
					</div>
					<div class="nav-btn" @click="saveTargetPos($event, itemFull)" v-if="canGeolocation">
						<img src="images/nav-btn.png" alt="nav-btn" />
					</div>
				</div>
				<div class="title" v-if="itemFull && getName(itemFull)">{{ getName(itemFull) }}</div>
				<!--<div class="rate-box">
					<i class="mdi mdi-star active"></i>
					<i class="mdi mdi-star active"></i>
					<i class="mdi mdi-star active"></i>
					<i class="mdi mdi-star active"></i>
					<i class="mdi mdi-star"></i>
				</div>-->
				<div
					class="description"
					:class="{ less: !descOpened }"
					v-if="itemFull && getDescription(itemFull)"
					v-html="getDescription(itemFull)"
				></div>
				<div class="description-btn" @click="toggleDescription" v-if="itemFull && getDescription(itemFull)">
					<span v-if="!descOpened">{{ $t("View More") }} <i class="mdi mdi-plus-box-outline"></i></span>
					<span v-if="descOpened">{{ $t("View Less") }} <i class="mdi mdi-minus-box-outline"></i></span>
				</div>

				<div class="blog-url-box" v-if="itemFull && itemFull.blogUrl">
					<a :href="itemFull.blogUrl" target="_blank">{{ $t("More on this topic") }} <i class="mdi mdi-arrow-right"></i></a>
				</div>

				<div class="info-box">
					<div class="info-row flex">
						<div class="grow box flex">
							<div class="info-icon">
								<i class="mdi mdi-ticket-confirmation"></i>
							</div>
							<div class="info-text grow box">
								<div class="key">{{ $t("tickets") }}</div>
								<div class="value">{{ itemFull && itemFull.ticketCost ? itemFull.ticketCost : "-" }}</div>
							</div>
						</div>
						<div class="grow box flex">
							<div class="info-icon">
								<i class="mdi mdi-clock"></i>
							</div>
							<div class="info-text grow box">
								<div class="key">{{ $t("timetable") }}</div>
								<div class="value" v-if="itemFull && itemFull.ticketHours">{{ itemFull.ticketHours || "-" }}</div>
								<div class="value" v-else-if="itemFull && itemFull.hours">{{ itemFull.hours || "-" }}</div>
								<div class="value" v-else>-</div>
							</div>
						</div>
					</div>
					<div class="info-row flex">
						<div class="grow box flex">
							<div class="info-icon">
								<i class="mdi mdi-map-marker"></i>
							</div>
							<div class="info-text grow box">
								<div class="key">{{ $t("address") }}</div>
								<div class="value">{{ itemFull && itemFull.addressText ? itemFull.addressText : $t("info not available") }}</div>
							</div>
						</div>
						<div class="grow box flex">
							<div class="info-icon">
								<i class="mdi mdi-phone"></i>
							</div>
							<div class="info-text grow box">
								<div class="key">{{ $t("telephone") }}</div>
								<div class="value">
									{{ itemFull && (itemFull.phone || itemFull.ticketPhone) ? itemFull.phone || itemFull.ticketPhone : "-" }}
								</div>
							</div>
						</div>
					</div>
					<div class="info-row flex">
						<div class="grow box flex">
							<div class="info-icon">
								<i class="mdi mdi-earth"></i>
							</div>
							<div class="info-text grow box">
								<div class="key">{{ $t("Website link") }}</div>
								<div class="value" v-if="itemFull && itemFull.website">
									<a :href="itemFull.website" target="_blank">{{ itemFull.website }}</a>
								</div>
								<div class="value" v-else>-</div>
							</div>
						</div>
						<div class="grow box flex" @click="openAccessibilityFeedback(itemFull)">
							<div class="info-icon">
								<i class="mdi mdi-wheelchair-accessibility"></i>
							</div>
							<div class="info-text grow box">
								<div class="key">{{ $t("accessibility") }}</div>
								<div class="value">
									{{ itemFull && itemFull.accessibility ? itemFull.accessibility : $t("info not available, please report") }}
									<span v-if="itemFull && itemFull.accessibilityInfo"> - {{ itemFull.accessibilityInfo }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="info-row flex" @click="openFeedbackDialog">
						<div class="grow box flex">
							<div class="info-icon">
								<i class="mdi mdi-message-alert-outline"></i>
							</div>
							<div class="info-text grow box flex align-center">
								<div class="value" style="text-decoration: underline">{{ $t("Feedback") }}</div>
							</div>
						</div>
						<div class="grow box flex">
							<div class="info-icon">
								<i class="mdi mdi-calendar"></i>
							</div>
							<div class="info-text grow box">
								<div class="key">{{ $t("Dates") }}</div>
								<div class="value">{{ itemFull && itemFull.ticketDates ? itemFull.ticketDates : "-" }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="copy-box flex justify-space-between">
					<div class="box">
						<div class="label">{{ $t("Content provided by") }}</div>
						<div class="title">{{ itemFull && itemFull.visibleCopy ? itemFull.visibleCopy : "-" }}</div>
					</div>
					<div class="box">
						<div class="image" v-if="itemFull && itemFull.visibleCopyImage">
							<img :src="itemFull.visibleCopyImage" />
						</div>
					</div>
				</div>

				<div class="share-btn-box">
					<button @click="shareModal" aria-label="share">{{ $t("Do you want to share this place with everyone else?") }}</button>
				</div>
			</div>

			<div v-if="loading" class="loading-layer">
				<v-ons-progress-circular indeterminate></v-ons-progress-circular>
			</div>
		</div>

		<v-ons-dialog cancelable :visible.sync="feedbackDialogVisible" class="feedback-dialog">
			<p style="text-align: center">{{ $t("Feedback") }}</p>
			<!--<textarea :placeholder="$t('Send a feedback')" v-model="feedbackMsg"></textarea>-->
			<p>
				<v-ons-select v-model="feedbackMsg" :placeholder="$t('Send a feedback')">
					<option :value="''">{{ $t("Please send us a feedback") }}</option>
					<option v-for="item in feedbackList" :key="item" :value="item">{{ item }}</option>
				</v-ons-select>
			</p>
			<div>
				<div v-if="isPhotoFeedback" class="photo-box">
					<div class="input-box">
						<button class="photo-btn" @click="launchFilePicker()" aria-label="add photo">
							<img src="images/placeholder-photo.png" v-if="!feedbackImage" alt="beauty-photo" />
							<img :src="feedbackImage" v-if="feedbackImage" alt="beauty-photo" />
						</button>
						<input
							type="file"
							accept="image/*"
							ref="file"
							name="file"
							@change="onFileChange($event.target.name, $event.target.files)"
							style="display: none"
						/>
					</div>
					<div class="checkbox-wrap">
						<v-ons-checkbox v-model="photoCheck">
							{{ $t("I authorize Stendhapp to use the photo") }}
						</v-ons-checkbox>
					</div>
				</div>
			</div>
			<p class="user-copy">{{ $t("sent by") }} {{ fullName }}</p>
			<button @click="sendFeedback" :disabled="!feedbackEnabled" aria-label="send feedback">{{ $t("Send") }}</button>
		</v-ons-dialog>

		<v-ons-action-sheet :visible.sync="socialActionSheetVisible" cancelable v-if="itemFull && itemFull.name">
			<v-ons-action-sheet-button @click="socialActionSheetVisible = false">
				<a :href="getShareUrl('facebook')" target="_blank" class="share-link" @click="saveSharePoint('facebook')">Facebook</a>
			</v-ons-action-sheet-button>
			<v-ons-action-sheet-button @click="socialActionSheetVisible = false">
				<a :href="getShareUrl('twitter')" target="_blank" class="share-link" @click="saveSharePoint('twitter')">Twitter</a>
			</v-ons-action-sheet-button>
			<v-ons-action-sheet-button @click="socialActionSheetVisible = false">
				<a :href="getShareUrl('pinterest')" target="_blank" class="share-link" @click="saveSharePoint('pinterest')">Pinterest</a>
			</v-ons-action-sheet-button>
			<v-ons-action-sheet-button @click="socialActionSheetVisible = false">
				<a :href="getShareUrl('whatsapp')" target="_blank" class="share-link" @click="saveSharePoint('whatsapp')">WhatsApp</a>
			</v-ons-action-sheet-button>
			<v-ons-action-sheet-button @click="socialActionSheetVisible = false">
				<a :href="getShareUrl('telegram')" target="_blank" class="share-link" @click="saveSharePoint('telegram')">Telegram</a>
			</v-ons-action-sheet-button>
			<v-ons-action-sheet-button @click="socialActionSheetVisible = false">Chiudi</v-ons-action-sheet-button>
		</v-ons-action-sheet>
	</div>
</template>

<script>
import api from "../api"
import _ from "lodash"
import undefsafe from "undefsafe"
import { Const } from "../const"
import { EventBus } from "@/event-bus.js"
import moment from "moment-timezone"

export default {
	name: "details-page",
	props: ["item"],
	data() {
		return {
			itemFull: {},
			descOpened: false,
			likeActive: false,
			feedbackDialogVisible: false,
			feedbackMsg: "",
			feedbackList: [],
			photoCheck: false,
			photodir: Const.photodir,
			tempdir: Const.tempdir,
			BASE_URL: api.getBaseUrl(),
			DOMAIN_URL: api.getDomainUrl(),
			feedbackImage: "",
			maxWidth: 800,
			maxHeight: 800,
			socialActionSheetVisible: false,
			loading: false
		}
	},
	computed: {
		fullName() {
			return this.$store.getters.fullName
		},
		isPhotoFeedback() {
			return this.feedbackMsg === this.$t("missing photo") || this.feedbackMsg === this.$t("I have a better photo")
		},
		feedbackEnabled() {
			if (this.isPhotoFeedback) {
				if (this.feedbackMsg && this.photoCheck && this.feedbackImage) {
					return true
				}
			} else {
				if (this.feedbackMsg) {
					return true
				}
			}
			return false
		},
		itemPhoto() {
			if (!_.get(this.itemFull, "photo")) return this.getImagePlaceholder(_.get(this.itemFull, "category"))
			else return this.getImage(_.get(this.itemFull, "photo"))
		},
		canGeolocation() {
			return this.$store.getters.canGeolocation
		}
	},
	watch: {
		item: {
			handler: function (val, oldVal) {
				if (undefsafe(val, "_id") !== undefsafe(oldVal, "_id")) {
					this.itemFull = {}
					this.getItem()
					this.addOpenedPlace()

					if (!this.item.isEvent) {
						this.isLikedPlace()
					}
				}
			},
			deep: true
		}
	},
	methods: {
		closeModal() {
			this.$emit("close")
		},
		shareModal() {
			//this.$emit('openShareModal', this.itemFull)
			this.socialActionSheetVisible = true
		},
		toggleDescription() {
			this.descOpened = !this.descOpened
		},
		saveTargetPos(event, i) {
			event.stopPropagation()
			event.preventDefault()

			this.$store.commit("setTargetPos", {
				coords: [i.latitude, i.longitude],
				location: i.name,
				category: i.category,
				id: i._id,
				lat: i.latitude,
				lon: i.longitude,
				time: this.$moment().format("x")
			})

			this.$store.commit("setRouteDetail", i)

			EventBus.$emit("map:set-route")

			this.closeModal()
		},
		sendFeedback() {
			if (this.feedbackMsg) {
				const photo = this.isPhotoFeedback ? this.feedbackImage : ""
				api.pdi
					.sendFeedback(this.feedbackMsg, photo, this.itemFull._id)
					.then(data => {
						console.log(data)
						this.feedbackMsg = ""
						this.feedbackImage = ""
						this.photoCheck = false
						this.feedbackDialogVisible = false
						this.$ons.notification.alert(this.$t("Feedback sent"))
					})
					.catch(err => {
						this.$ons.notification.alert(this.$t("Error sending Feedback"))
					})
			}
		},
		toggleLikedPlace(event) {
			event.stopPropagation()
			event.preventDefault()

			const method = this.likeActive ? "removeLikedPlace" : "addLikedPlace"

			api.pdi[method](this.itemFull._id).then(data => {
				if (data && data.status && data.status === 200) {
					this.likeActive = !this.likeActive

					EventBus.$emit("init:profile-page")
				}
			})
		},
		addOpenedPlace() {
			api.pdi.addOpenedPlace(this.item._id)
		},
		getItem() {
			this.loading = true

			if (!this.item.isEvent) {
				api.pdi.getAll({ id: this.item._id }).then(data => {
					if (data && data.status && data.status === 200 && data.body) {
						this.itemFull = data.body
						this.loading = false
					}
				})
			} else {
				api.event.getAll({ id: this.item._id }).then(data => {
					if (data && data.status && data.status === 200 && data.body) {
						this.itemFull = data.body
						let ticketDates = this.$moment(this.itemFull.startDate).format("DD/MM/YYYY") || ""

						if (this.itemFull.endDate) {
							if (ticketDates) ticketDates += " - "
							ticketDates += this.$moment(this.itemFull.endDate).format("DD/MM/YYYY")
						}

						this.itemFull.ticketDates = ticketDates

						this.loading = false
					}
				})
			}
		},
		isLikedPlace() {
			api.pdi.isLikedPlace(this.item._id).then(data => {
				if (data && data.status && data.status === 200 && data.body) {
					this.likeActive = data.body.isLiked
				}
			})
		},
		openPhotoFeedback(item) {
			this.setFeedbackList()

			if (!item || !item.photo) {
				this.feedbackDialogVisible = true
				this.feedbackMsg = this.$t("missing photo")
			}
		},
		openAccessibilityFeedback(item) {
			if (!item || !item.accessibility) {
				this.feedbackDialogVisible = true
				this.feedbackMsg = this.$t("wheelchair accessible")
			}
		},
		getImage(photo) {
			return encodeURI(this.DOMAIN_URL + this.photodir + "/" + photo) + "?_=" + moment().format("x")
		},
		getImagePlaceholder(category) {
			const l = this.$i18n.locale ? "-" + this.$i18n.locale : ""
			let photo = `images/placeholder${l}.jpg`

			if (category) {
				switch (category) {
					case "food":
						photo = `images/food${l}.jpg`
						break
					case "literature":
						photo = `images/literature${l}.jpg`
						break
					case "wine":
						photo = `images/wine${l}.jpg`
						break
					case "cinema":
						photo = `images/cinema${l}.jpg`
						break
					case "stores":
						photo = `images/stores${l}.jpg`
						break
				}
			}
			return photo
		},
		getName(item) {
			switch (this.$i18n.locale) {
				case "it":
					return item.name || item.nameEN
				case "en":
					return item.nameEN || item.name
				default:
					return ""
			}
		},
		getDescription(item) {
			if (item.description || item.descriptionEN) {
				switch (this.$i18n.locale) {
					case "it":
						return item.description.replace(/\n/g, "<br>") || item.descriptionEN.replace(/\n/g, "<br>") || ""
					case "en":
						return item.descriptionEN.replace(/\n/g, "<br>") || ""
					default:
						return ""
				}
			}
			return ""
		},
		launchFilePicker() {
			this.$refs.file.click()
		},
		onFileChange(fieldName, file) {
			//const { maxSize } = this
			let imageFile = file[0]

			//check if user actually selected a file
			if (file.length > 0) {
				const reader = new FileReader()
				reader.readAsDataURL(imageFile)
				reader.onload = () => {
					//console.log(this.feedbackImage);
					const img = new Image()

					img.onload = () => {
						const canvas = document.createElement("canvas")
						let ctx = canvas.getContext("2d")
						ctx.drawImage(img, 0, 0)

						const MAX_WIDTH = this.maxWidth
						const MAX_HEIGHT = this.maxHeight
						let width = img.width
						let height = img.height

						if (width > height) {
							if (width > MAX_WIDTH) {
								height *= MAX_WIDTH / width
								width = MAX_WIDTH
							}
						} else {
							if (height > MAX_HEIGHT) {
								width *= MAX_HEIGHT / height
								height = MAX_HEIGHT
							}
						}
						canvas.width = width
						canvas.height = height
						ctx = canvas.getContext("2d")
						ctx.drawImage(img, 0, 0, width, height)

						//console.log(canvas)

						const dataurl = canvas.toDataURL("image/png")

						canvas.toBlob(binary => {
							api.common.uploadfile(binary).then(res => {
								console.log(res)
								this.feedbackImage = this.DOMAIN_URL + this.tempdir + "/" + res.body.filename
							})
						})

						//this.feedbackImage = dataurl;
					}
					img.src = reader.result
				}
			}
		},
		setFeedbackList() {
			this.feedbackList = [
				this.$t("missing photo"),
				this.$t("I have a better photo"),
				this.$t("wheelchair accessible"),
				this.$t("partially accessible to the disabled"),
				this.$t("not accessible for disabled people"),
				this.$t("incorrect ticket costs"),
				this.$t("incorrect time"),
				this.$t("location on the map incorrect"),
				this.$t("missing or incorrect address"),
				this.$t("non-existent place"),
				this.$t("point present several times on the map"),
				this.$t("missing or incorrect description"),
				this.$t("incorrect website"),
				this.$t("incorrect telephone number")
			]
		},
		openFeedbackDialog() {
			this.setFeedbackList()
			this.feedbackMsg = ""
			this.feedbackImage = ""
			this.photoCheck = false
			this.feedbackDialogVisible = true
		},
		getSocialShareLinks(args) {
			const validargs = [
				"url",
				"title",
				"image",
				"desc",
				"appid",
				"redirecturl",
				"via",
				"hashtags",
				"provider",
				"language",
				"userid",
				"category",
				"phonenumber",
				"emailaddress",
				"cemailaddress",
				"bccemailaddress"
			]

			for (var i = 0; i < validargs.length; i++) {
				const validarg = validargs[i]
				if (!args[validarg]) {
					args[validarg] = ""
				}
			}

			const url = this.fixedEncodeURIComponent(args.url)
			const title = this.fixedEncodeURIComponent(args.title)
			const image = this.fixedEncodeURIComponent(args.image)
			const desc = this.fixedEncodeURIComponent(args.desc)
			const app_id = this.fixedEncodeURIComponent(args.appid)
			const redirect_url = this.fixedEncodeURIComponent(args.redirecturl)
			const via = this.fixedEncodeURIComponent(args.via)
			const hash_tags = this.fixedEncodeURIComponent(args.hashtags)
			const provider = this.fixedEncodeURIComponent(args.provider)
			const language = this.fixedEncodeURIComponent(args.language)
			const user_id = this.fixedEncodeURIComponent(args.userid)
			const category = this.fixedEncodeURIComponent(args.category)
			const phone_number = this.fixedEncodeURIComponent(args.phonenumber)
			const email_address = this.fixedEncodeURIComponent(args.emailaddress)
			const cc_email_address = this.fixedEncodeURIComponent(args.ccemailaddress)
			const bcc_email_address = this.fixedEncodeURIComponent(args.bccemailaddress)

			var text = title

			if (desc) {
				text += "%20%3A%20" // This is just this, " : "
				text += desc
			}

			return {
				"add.this": "http://www.addthis.com/bookmark.php?url=" + url,
				blogger: "https://www.blogger.com/blog-this.g?u=" + url + "&n=" + title + "&t=" + desc,
				buffer: "https://buffer.com/add?text=" + text + "&url=" + url,
				diaspora: "https://share.diasporafoundation.org/?title=" + title + "&url=" + url,
				digg: "http://digg.com/submit?url=" + url + "&title=" + text,
				douban: "http://www.douban.com/recommend/?url=" + url + "&title=" + text,
				email: "mailto:" + email_address + "?subject=" + title + "&body=" + desc,
				evernote: "http://www.evernote.com/clip.action?url=" + url + "&title=" + text,
				getpocket: "https://getpocket.com/edit?url=" + url,
				facebook: "http://www.facebook.com/sharer.php?u=" + url + "&quote=" + desc + "&hashtag=%23" + hash_tags,
				flattr:
					"https://flattr.com/submit/auto?user_id=" +
					user_id +
					"&url=" +
					url +
					"&title=" +
					title +
					"&description=" +
					text +
					"&language=" +
					language +
					"&tags=" +
					hash_tags +
					"&hidden=HIDDEN&category=" +
					category,
				flipboard: "https://share.flipboard.com/bookmarklet/popout?v=2&title=" + text + "&url=" + url,
				gmail:
					"https://mail.google.com/mail/?view=cm&to=" +
					email_address +
					"&su=" +
					title +
					"&body=" +
					url +
					"&bcc=" +
					bcc_email_address +
					"&cc=" +
					cc_email_address,
				"google.bookmarks":
					"https://www.google.com/bookmarks/mark?op=edit&bkmk=" +
					url +
					"&title=" +
					title +
					"&annotation=" +
					text +
					"&labels=" +
					hash_tags +
					"",
				instapaper: "http://www.instapaper.com/edit?url=" + url + "&title=" + title + "&description=" + desc,
				"line.me": "https://lineit.line.me/share/ui?url=" + url + "&text=" + text,
				linkedin:
					"https://www.linkedin.com/shareArticle?mini=true&url=" + url + "&title=" + title + "&summary=" + text + "&source=" + provider,
				livejournal: "http://www.livejournal.com/update.bml?subject=" + text + "&event=" + url,
				"hacker.news": "https://news.ycombinator.com/submitlink?u=" + url + "&t=" + title,
				"ok.ru": "https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=" + url,
				pinterest: "http://pinterest.com/pin/create/button/?url=" + url + "&description=" + text,
				qzone: "http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" + url,
				reddit: "https://reddit.com/submit?url=" + url + "&title=" + title,
				renren: "http://widget.renren.com/dialog/share?resourceUrl=" + url + "&srcUrl=" + url + "&title=" + text + "&description=" + desc,
				skype: "https://web.skype.com/share?url=" + url + "&text=" + text,
				sms: "sms:" + phone_number + "?body=" + text,
				"surfingbird.ru": "http://surfingbird.ru/share?url=" + url + "&description=" + desc + "&screenshot=" + image + "&title=" + title,
				telegram: "https://t.me/share/url?url=" + url + "&text=" + text + "&to=" + phone_number,
				threema: "threema://compose?text=" + text + "&id=" + user_id,
				tumblr:
					"https://www.tumblr.com/widgets/share/tool?canonicalUrl=" + url + "&title=" + title + "&caption=" + desc + "&tags=" + hash_tags,
				twitter: "https://twitter.com/intent/tweet?url=" + url + "&text=" + text + "&via=" + via + "&hashtags=" + hash_tags,
				vk: "http://vk.com/share.php?url=" + url + "&title=" + title + "&comment=" + desc,
				weibo: "http://service.weibo.com/share/share.php?url=" + url + "&appkey=&title=" + title + "&pic=&ralateUid=",
				whatsapp: "whatsapp://send?text=" + url + " " + desc,
				xing: "https://www.xing.com/spi/shares/new?url=" + url,
				yahoo: "http://compose.mail.yahoo.com/?to=" + email_address + "&subject=" + title + "&body=" + text
			}
		},
		fixedEncodeURIComponent(str) {
			return encodeURIComponent(str || "").replace(/[!'()*]/g, function (c) {
				return "%" + c.charCodeAt(0).toString(16)
			})
		},
		getShareUrl(social) {
			const params = {
				url: `https://stendhapp.com/share/beauty/${_.words(this.itemFull.name || this.itemFull.nameEN).join("+") || "-"}/${
					this.itemFull._id
				}`,
				title: _.words(`${this.itemFull.name || this.itemFull.nameEN || ""}`).join(" "),
				hashtags: "bellezzaovunque",
				via: "stendhapp",
				desc: "Guarda cosa ho visitato grazie a Stendhapp. Scopri anche tu nuova Bellezza su www.stendhapp.com"
			}
			return this.getSocialShareLinks(params)[social]
		},
		saveSharePoint(social) {
			api.pdi.addSharedPlace(this.itemFull._id).then(data => {
				if (data && data.status && data.status === 200) {
					EventBus.$emit("init:profile-page")
				}
			})
		}
	},
	mounted() {
		if (undefsafe(this.item, "_id")) {
			this.descOpened = false
			this.itemFull = {}
			this.getItem()
			this.addOpenedPlace()

			if (!this.item.isEvent) {
				this.isLikedPlace()
			}
		}
	},
	components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.details-page {
	height: 100%;
	width: 100%;
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	box-sizing: border-box;
	overflow: hidden;
	background: #fff;
	max-width: 640px;
	margin: 0 auto;
	color: #000;

	.toolbar {
		background: white;

		.toolbar__title {
			color: #000;
			text-align: center;
		}
		.toolbar-button {
			color: #000;
			opacity: 0.5;
		}
	}

	.page__background {
		background: #fff;
	}

	.page-wrapper {
		padding: 20px;
		text-align: left;
		box-sizing: border-box;
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		padding-top: 65px;

		.image-box {
			background-color: #1a65ff;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			height: 300px;
			border-radius: 14px;
			text-align: right;
			position: relative;

			.like-btn {
				color: #fff;
				font-size: 25px;
				padding: 7px 10px;
				cursor: pointer;
				text-shadow: 0px 1px 3px #633ef7;

				&.active {
					//color: #f3587f;
					color: #6234fd;
					text-shadow: 0px 0px 2px #fff, 0px 0px 2px #fff, 0px 0px 2px #fff, 0px 0px 2px #fff, 0px 0px 2px #fff;
				}
			}
			.nav-btn {
				position: absolute;
				bottom: -40px;
				right: 0;
				cursor: pointer;

				img {
					width: 80px;
				}
			}

			.nav-text {
				position: absolute;
				bottom: -9px;
				right: 55px;
				cursor: pointer;
				background: rgba(101, 59, 247, 0.9);
				padding: 4px 8px;
				padding-right: 30px;
				font-size: 11px;
				border-radius: 4px;
				color: #fff;
			}
		}

		.title {
			color: #000;
			font-weight: bold;
			margin: 15px 0;
			font-size: 20px;
		}

		.rate-box {
			i {
				color: #d7d7d7;
				font-size: 22px;
				margin-right: 5px;

				&.active {
					color: #ffa600;
				}
			}
		}

		.description {
			font-size: 14px;
			margin-top: 10px;
			color: #a2a2a2;
			text-align: justify;
			overflow: hidden;
			position: relative;

			&.less {
				max-height: 70px;

				&::after {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					width: 100%;
					height: 20px;
					box-shadow: 0px -20px 15px -10px #fff inset;
				}
			}
		}

		.description-btn {
			cursor: pointer;
			margin-top: 10px;
			color: #1a65ff;
		}

		.blog-url-box {
			margin: 30px 0;

			a {
				color: white;
				background: #1d84ff;
				border: 2px solid rgba(255, 255, 255, 0.5);
				border-radius: 6px;
				padding: 8px 16px;
				text-decoration: none;
				font-size: 14px;

				i {
					margin-left: 10px;
				}
			}
		}

		.info-box {
			.info-row {
				margin-top: 20px;

				.info-icon {
					i {
						background: white;
						color: #c1c1c1;
						border-radius: 4px;
						padding: 10px;
						text-align: center;
						line-height: 35px;
						box-shadow: 0px 3px 10px 0px rgba(115, 115, 115, 0.2);
					}
				}
				.info-text {
					padding: 0 10px;

					.key {
						font-size: 12px;
						color: grey;
						margin-bottom: 5px;
						text-transform: capitalize;
					}
					.value {
						font-size: 14px;
						color: #5a4f77;
						word-break: break-word;
					}
				}
			}
		}

		.copy-box {
			margin: 30px 0;

			.label {
				font-size: 10px;
				color: grey;
			}

			.title {
				color: #5a4f77;
				font-size: 14px;
				margin: 5px 0;
			}

			.link {
				cursor: pointer;
				color: #155cec;
				text-decoration: underline;
				font-size: 13px;
			}

			.image {
				margin-left: 20px;
				img {
					width: 100%;
					max-height: 50px;
					display: block;
				}
			}
		}

		.share-btn-box {
			button {
				background: #1d84ff;
				padding: 10px 0;
				width: 100%;
				margin: 0 auto;
				display: block;
				border: none;
				border-radius: 4px;
				color: #fff;
				margin-top: 20px;
				cursor: pointer;
			}
		}
	}

	.loading-layer {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, 0.8);

		ons-progress-circular {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -16px;
		}
	}

	&.sponsor {
		.page-wrapper {
			.page-content {
				.image-box {
					box-shadow: 0px 0px 0px 5px rgba(255, 204, 51, 0.52) inset, 0px 0px 20px 10px rgba(255, 204, 51, 0.4) inset;

					.nav-text {
						background: #e8b82a;
					}
				}

				& > .title {
					color: rgb(255, 204, 51);
				}
			}

			.description-btn {
				color: rgb(255, 204, 51);
			}
		}
	}
}

.feedback-dialog {
	z-index: 10002 !important;

	textarea {
		margin: 0px;
		height: 90px;
		border: none;
		resize: none;
		width: 100%;
		padding: 10px;
		background: white;
	}

	ons-select {
		width: 100%;
		padding: 0px 20px;
		box-sizing: border-box;
	}

	.user-copy {
		font-size: 12px;
		opacity: 0.5;
		padding: 0 20px;
		text-align: center;
	}

	.photo-box {
		padding: 5px 20px;
		text-align: center;

		.photo-btn {
			background: #fff;
		}

		.input-box {
			padding: 0;
			margin: 0;
			border: 1px solid #e8e8e8;
			background: #fff;
			outline: none;
			border-radius: 4px;
			cursor: pointer;
			overflow: hidden;
			width: 100%;
			text-align: center;

			img {
				max-width: 150px;
			}
		}

		.checkbox-wrap {
			margin-top: 10px;
			font-size: 12px;
		}
	}

	button {
		background: #1d84ff;
		padding: 10px 0;
		width: calc(100% - 20px);
		margin: 10px;
		display: block;
		border: none;
		border-radius: 4px;
		color: #fff;
		cursor: pointer;

		&:disabled {
			cursor: no-drop;
			opacity: 0.7;
		}
	}
}

.share-link {
	width: 100%;
	display: block;
	text-decoration: none;
	color: #653bf7;
}
</style>
