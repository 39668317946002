<template>
	<div class="places-list-page flex column">
		<v-ons-toolbar>
			<div class="left">
				<v-ons-toolbar-button icon="md-arrow-left" @click="closeModal"></v-ons-toolbar-button>
			</div>
			<div class="center">
				<span v-if="type">{{ $t(types[type].title) }}</span>
			</div>
			<div class="right">&nbsp;</div>
		</v-ons-toolbar>

		<div class="page-wrapper box grow">
			<div class="page-content">
				<!--<div class="labels-box flex justify-space-between">
					<div class="left">{{$t("Trips")}}</div>
				</div>
				<div class="trips-box">
					<div class="trips-item" v-for="(item, index) in trips" :key="index" :style="'background-image:url('+'images/demo/'+(index+1)+'.jpg'+')'">
						<div class="title-box">
							<div class="title">{{$t('Trip to')}} {{item.title}}</div>
							<div class="sub-title">{{$t('a journey into the past')}}</div>
						</div>
					</div>
				</div>-->

				<div class="labels-box flex justify-space-between">
					<div class="left">{{ $t("List of all places") }} ({{ places.length }})</div>
				</div>
				<div class="places-box">
					<div class="places-item" v-for="item in places" :key="item._id" @click="openItem(item)">
						<div class="img-box" :style="'background-image:url(' + getImage(item) + ')'">
							<div class="cat-icon-box">
								<img :src="'map-icons/' + item.category + '.png'" alt="category-icon" />
							</div>
						</div>
						<div class="info-box">
							<div class="title">{{ item.name }}</div>
							<div class="description">{{ (item.description || "") | truncate(100) }}</div>
							<div class="media">{{ getDate(item) | moment("dd MMM YYYY") }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from "lodash"
import api from "../api"
import { Const } from "../const"
import { EventBus } from "@/event-bus.js"

export default {
	name: "places-list-page",
	props: ["type"],
	data() {
		return {
			ld: _,
			types: {
				liked: { title: "My favorite places" },
				opened: { title: "My diary" },
				shared: { title: "The places I shared" },
				"best-city": { title: "Best City Experiences" },
				recommendations: { title: "Recommendations for you" }
			},
			trips: [{ title: "Roma" }, { title: "Firenze" }, { title: "Venezia" }, { title: "Milano" }],
			places: [],
			photodir: Const.photodir,
			tempdir: Const.tempdir,
			BASE_URL: api.getBaseUrl(),
			DOMAIN_URL: api.getDomainUrl()
		}
	},
	watch: {
		type: {
			handler: function (val, oldVal) {
				this.init()
			}
		}
	},
	computed: {},
	methods: {
		closeModal() {
			this.$emit("close")
		},
		getLikedPlaces() {
			api.pdi.getLikedPlaces().then(data => {
				console.log("getLikedPlaces places-list-page", data.body || data)

				if (data && data.status && data.status === 200 && data.body) {
					this.places = data.body
				}
			})
		},
		getOpenedPlaces() {
			api.pdi.getOpenedPlaces().then(data => {
				console.log("getOpenedPlaces places-list-page", data.body || data)

				if (data && data.status && data.status === 200 && data.body) {
					this.places = data.body
				}
			})
		},
		getSharedPlaces() {
			api.pdi.getSharedPlaces().then(data => {
				console.log("getSharedPlaces places-list-page", data.body || data)

				if (data && data.status && data.status === 200 && data.body) {
					this.places = data.body
				}
			})
		},
		getImage(item) {
			if (item && item.photo) return encodeURI(this.DOMAIN_URL + this.photodir + "/" + item.photo)
			else return "images/placeholder.jpg"
		},
		init() {
			console.log("places-list-page", this.type)
			switch (this.type) {
				case "shared":
					this.getSharedPlaces()
					break
				case "liked":
					this.getLikedPlaces()
					break
				case "opened":
					this.getOpenedPlaces()
					break
			}
		},
		openItem(item) {
			EventBus.$emit(`open:pdi`, item)
		},
		getDate(item) {
			switch (this.type) {
				case "shared":
					return item.sharedDate || ""
				case "liked":
					return item.likedDate || ""
				case "opened":
					return item.openedDate || ""
			}
		}
	},
	mounted() {
		this.init()

		EventBus.$on("init:profile-page", () => {
			console.log("init:profile-page")
			this.init()
		})
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.places-list-page {
	height: 100%;
	width: 100%;
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	box-sizing: border-box;
	overflow: hidden;
	background: #fff;
	max-width: 640px;
	margin: 0 auto;
	color: #000;

	.toolbar {
		.toolbar__title {
			color: #000;
		}
		.toolbar-button {
			color: #000;
			opacity: 0.5;
		}
		.toolbar--material__center {
			text-align: center;
		}
	}

	.page__background {
		background: #fff;
	}

	.page-wrapper {
		padding: 20px;
		text-align: left;
		box-sizing: border-box;
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		padding-top: 65px;
	}

	.labels-box {
		padding: 10px;
		font-size: 14px;

		.left {
			font-weight: 500;
		}
		.right {
			opacity: 0.5;
			cursor: pointer;
		}
	}

	.trips-box {
		overflow: hidden;
		overflow-x: scroll;
		white-space: nowrap;
		padding: 10px;
		padding-bottom: 20px;

		.trips-item {
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			width: 80%;
			padding-bottom: 50%;
			display: inline-block;
			margin-right: 15px;
			border-radius: 4px;
			position: relative;
			box-shadow: 0px 0px 40px 0px #000 inset, 0px 3px 10px 0px rgba(100, 100, 100, 0.4);

			.title-box {
				color: #fff;
				font-size: 14px;
				position: absolute;
				top: 50%;
				left: 50%;
				text-transform: capitalize;
				display: block;
				white-space: initial;
				transform: translateX(-50%) translateY(-50%);
				text-shadow: 0px 1px 2px #000;
				width: 100%;

				.title {
					font-size: 20px;
					font-weight: 500;
					margin-bottom: 10px;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.places-box {
		padding: 10px;
		padding-bottom: 20px;

		.places-item {
			width: 100%;
			padding-bottom: 35%;
			display: inline-block;
			border-radius: 4px;
			position: relative;
			overflow: hidden;
			cursor: pointer;
			margin-bottom: 15px;
			box-shadow: 0px 0px 40px 0px #000 inset, 0px 3px 10px 0px rgba(100, 100, 100, 0.4);

			.img-box {
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;
				width: 33%;
				display: block;
				position: absolute;
				top: 0px;
				left: 0px;
				bottom: 0px;

				.cat-icon-box {
					width: 25%;
					padding-top: 3%;
					padding-bottom: 3%;
					box-sizing: border-box;
					background: #fff;
					border-radius: 4px;
					opacity: 0.9;
					text-align: center;
					display: block;
					position: absolute;
					top: 10px;
					left: 10px;

					img {
						width: 60%;
					}
				}
			}

			.info-box {
				background: #fff;
				width: 67%;
				display: block;
				position: absolute;
				top: 0px;
				right: 0px;
				bottom: 0px;
				color: #000;
				text-align: left;

				.title {
					font-size: 14px;
					position: absolute;
					top: 10px;
					left: 10px;
					text-transform: capitalize;
					width: 50%;
					display: block;
					white-space: initial;
					font-weight: 500;
				}
				.places {
					font-size: 12px;
					position: absolute;
					top: 10px;
					right: 10px;
					text-transform: capitalize;
					width: 50%;
					display: block;
					white-space: initial;
					text-align: right;
				}
				.description {
					font-size: 12px;
					position: absolute;
					top: 30px;
					left: 0px;
					text-transform: capitalize;
					width: 100%;
					padding: 10px;
					display: block;
					white-space: initial;
					box-sizing: border-box;
					opacity: 0.5;
				}
				.media {
					font-size: 12px;
					position: absolute;
					bottom: 0px;
					left: 0px;
					text-transform: capitalize;
					width: 100%;
					padding: 10px;
					display: block;
					white-space: initial;
					color: #6440f9;
					box-sizing: border-box;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}
</style>
