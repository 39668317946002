<template>
	<div class="map-page" :class="{ 'show-tooltip': zoom >= 17 }">
		<v-ons-toolbar modifier="noshadow transparent cover-content">
			<div class="left">
				<v-ons-toolbar-button icon="md-settings" @click="openSettings"></v-ons-toolbar-button>
			</div>
			<div class="center">{{ $t("Map") }}</div>
			<div class="right">
				&nbsp;
				<!--<v-ons-toolbar-button icon="md-close" @click="closeModal"></v-ons-toolbar-button>-->
			</div>
		</v-ons-toolbar>

		<component
			:is="asyncComponent"
			ref="map"
			:zoom.sync="zoom"
			:min-zoom="mapOptions.minZoom"
			:max-zoom="mapOptions.maxZoom"
			:prefer-canvas="mapOptions.preferCanvas"
			:center.sync="center"
			:options="mapOptions"
			@zoom="zoomChange"
			@drag="centerChange"
		>
			<v-tilelayer
				:url="urlMap"
				:option="tileOptions"
				:detect-retina="tileOptions.detectRetina"
				:max-native-zoom="tileOptions.maxNativeZoom"
				:max-zoom="tileOptions.maxZoom"
			></v-tilelayer>

			<v-marker v-if="currentPos.coords" :lat-lng="currentPos.coords" :options="{ zIndexOffset: 500 }" :icon="icons.current"></v-marker>

			<v-marker-cluster
				:options="{
					spiderfyOnMaxZoom: true,
					animate: true,
					removeOutsideVisibleBounds: true,
					spiderfyDistanceMultiplier: 4,
					spiderLegPolylineOptions: { weight: 1, color: '#6440F9', opacity: 0.3 },
					maxClusterRadius: 30
					//singleMarkerMode: true
				}"
			>
				<!--
				<v-marker
					v-if="currentPos.coords"
					:lat-lng="currentPos.coords"
					:options="{zIndexOffset:500}"
					:icon="icons.current"
				></v-marker>
				-->

				<!--
					v-for="i of itemsFiltered"
				-->
				<v-marker
					v-for="i of allItems"
					:key="i._id"
					:lat-lng="i.coords"
					:icon="getMarkerIcon(i)"
					:options="{ zIndexOffset: 200, alt: i.name }"
					@click="clickPoint(i)"
				>
					<!--<v-tooltip :content="i.name | truncate(25)" :options="{ permanent: true, direction: 'top' }" v-if="zoom >= 17"></v-tooltip>-->
				</v-marker>
			</v-marker-cluster>

			<!--
			<v-marker
				v-for="i of itemsSponsor"
				:key="i._id"
				:lat-lng="i.coords"
				:icon="getMarkerIcon(i)"
				:options="{ zIndexOffset: 200, alt: i.name }"
				@click="clickPoint(i)"
			>
				<v-tooltip :content="i.name" :options="{ permanent: true, direction: 'top' }" v-if="zoom >= 17"></v-tooltip>
			</v-marker>
			-->

			<v-geojson v-if="geoJSONLayer" :geojson="geoJSONLayer" :options="geoJSONOptions"></v-geojson>
		</component>

		<div class="map-toolbar">
			<div class="label map-label">{{ $t("How would you like to reach your destination") }} ?</div>
			<div class="route-type flex">
				<button @click="routeType = 'walking'" :class="{ active: routeType === 'walking' }" class="box grow" aria-label="route type walking">
					<i class="mdi mdi-walk"></i
					><!--{{$t("Walk")}}-->
				</button>
				<button @click="routeType = 'cycling'" :class="{ active: routeType === 'cycling' }" class="box grow" aria-label="route type cycling">
					<i class="mdi mdi-bike"></i
					><!--{{$t("Bike")}}-->
				</button>
				<button @click="routeType = 'driving'" :class="{ active: routeType === 'driving' }" class="box grow" aria-label="route type driving">
					<i class="mdi mdi-car"></i
					><!--{{$t("Drive")}}-->
				</button>
			</div>
			<div class="navigate-box">
				<div class="input-wrap">
					<div class="from flex center">
						<div class="image-box"><img src="map-icons/ico-current.png" alt="geo-icon" /></div>
						<input
							class="box grow"
							v-model="mapSearch"
							:placeholder="currentPos.location || $t('My location')"
							:disabled="routeInfo.isOpen"
							:readonly="routeInfo.isOpen"
							@focus="searchStatus = 'focus'"
							@blur="searchStatus = 'blur'"
						/>
						<div v-if="mapSearch" class="map-search" @click="resetMapSearch"><i class="mdi mdi-close-circle-outline"></i></div>
						<div v-if="mapSearch" class="map-search" @click="goMapSearch"><i class="mdi mdi-arrow-right-bold-circle-outline"></i></div>
					</div>
					<transition name="fade">
						<div class="to flex center" v-if="routeInfo.isOpen">
							<div class="image-box"><img :src="`map-icons/${targetPos.category}.png`" alt="category-icon" /></div>
							<input class="box grow" :value="targetPos.location" readonly />
							<!--<div class="distance" v-if="routeInfo.isOpen">{{routeInfoDistance}}</div>-->
						</div>
					</transition>
				</div>
				<!--<transition name="fade">
					<button v-if="routeInfo.isOpen" @click="launchNavigator">{{$t("Itinerary")}}</button>
				</transition>-->
			</div>

			<div class="current-pos-btn" @click="goToCurrentPos(false)" v-if="canGeolocation">
				<i class="mdi mdi-crosshairs-gps"></i>
			</div>
		</div>

		<transition name="slide-top">
			<div class="map-info-bar flex" v-if="routeInfo.isOpen">
				<div class="image-box flex column justify-center">
					<img src="../assets/discover-icon.png" alt="discover-icon" />
				</div>
				<div class="content box grow flex column justify-center">
					<!--<div class="title" @click="launchNavigator">{{$t("Start navigation")}}</div>-->
					<a class="title" :href="navigatorUrl" target="_blank">{{ $t("Start navigation") }}</a>
					<small class="sub-title">{{ $t("Stendhapp uses the navigation system of your device") }}</small>
					<div class="description" v-if="routeInfoDuration !== '0 sec' && routeInfoDistance !== '0 m'">
						{{ routeInfoDuration }} | {{ routeInfoDistance }}
					</div>
				</div>
				<div class="buttons-box flex column justify-center">
					<div>
						<button @click="clickPoint(routeDetail)" aria-label="info">
							<span class="icon">i</span>
							<span class="label">{{ $t("View info") }}</span>
						</button>
					</div>
					<div>
						<button @click="clearTargetPos" aria-label="close">
							<span class="icon">x</span>
							<span class="label">{{ $t("Close") }}</span>
						</button>
					</div>
				</div>
			</div>
		</transition>

		<v-ons-modal :visible="launchNavigatorModalVisible" @click="launchNavigatorModalVisible = false">
			<p class="launch-navigator-modal">
				{{ $t("Navigation will start soon on the navigation system on this device") }} <v-ons-icon icon="fa-spinner" spin></v-ons-icon>
			</p>
		</v-ons-modal>

		<div class="popup-sponsor" v-if="popupSponsor !== null">
			<div class="flex wrapper" @click="clickPoint(popupSponsor)">
				<div class="box image">
					<img :src="DOMAIN_URL + '/api/v1/photo/' + popupSponsor._id + '/color.jpeg'" />
				</div>
				<div class="box grow info flex column">
					<div class="name">
						{{ getName(popupSponsor) }}
					</div>
					<div class="address">
						{{ popupSponsor.addressText }}
					</div>
					<div class="description box grow">
						{{ getDescription(popupSponsor) }}
					</div>
					<div class="bottom flex">
						<div class="accessibility box grow">
							<i class="mdi mdi-wheelchair-accessibility"></i> <span>{{ popupSponsor.accessibility || "-" }}</span>
						</div>
						<div class="category box">
							<img :src="'map-icons/' + popupSponsor.category + '-sp.svg'" alt="category-icon" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import L from "leaflet"
import usafe from "undefsafe"
import _ from "lodash"
import moment from "moment-timezone"
import api from "../api"
import { EventBus } from "@/event-bus.js"
import * as geolib from "geolib"
/*
=== map tiles ===

light_all,
dark_all,
light_nolabels,
light_only_labels,
dark_nolabels,
dark_only_labels,
rastertiles/voyager,
rastertiles/voyager_nolabels,
rastertiles/voyager_only_labels,
rastertiles/voyager_labels_under
*/

const mapLatLngBoundsIntervalTime = 3000
const mapMaxZoom = 21
const bSouthWest = L.latLng(34.181436, 4.944304)
const bNorthEast = L.latLng(50.775867, 21.721338)
const maxBounds = L.latLngBounds(bSouthWest, bNorthEast)

export default {
	name: "map-page",
	data() {
		return {
			DOMAIN_URL: api.getDomainUrl(),
			searchStatus: "blur",
			console: console,
			navigator: navigator,
			asyncComponent: null,
			leafLatLng: L.latLng,
			zoom: 16,
			center: [45.463526, 9.188029],
			mapOptions: {
				zoomControl: false,
				minZoom: 5,
				maxZoom: mapMaxZoom,
				preferCanvas: true,
				detectRetina: true,
				maxBounds
			},
			tileOptions: {
				maxNativeZoom: 18,
				maxZoom: mapMaxZoom,
				detectRetina: false
			},
			geoJSONOptions: {
				style: feature => {
					return { color: "#2184fe", opacity: 0.8, dashArray: "5,5" }
				}
			},
			geoJSONLayer: null,
			routeType: "walking",
			trackingPos: false,
			currentPos: {
				coords: null,
				accuracy: null,
				location: null,
				lat: null,
				lon: null
			},
			routeInfo: {
				distance: null,
				duration: null,
				isOpen: false
			},
			mapLatLngBounds: null,
			watchPositionIntervalTime: 5000,
			routeDistanceLimit: 15000,
			launchNavigatorModalVisible: false,
			launchNavigatorModalTimeout: 0,
			mapSearch: "",
			mapCategory: null,

			popupSponsorInterval: null,
			popupSponsor: null,

			urlMap: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}@2x.png",
			urlMapRaster: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}@2x.png",
			urlMapNoLabels: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_nolabels/{z}/{x}/{y}@2x.png",
			urlLabels: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_only_labels/{z}/{x}/{y}@2x.png"
		}
	},
	computed: {
		objCenter() {
			return this.leafLatLng(...this.center)
		},
		filter() {
			return filter => {
				return this.$store.getters.filter(filter)
			}
		},
		lastMapSearch() {
			return this.$store.getters.lastMapSearch
		},
		popupSponsors() {
			return this.$store.getters.popupSponsors
		},
		items() {
			return this.$store.getters.pdiListNoSponsor
		},
		filtersActive() {
			return this.$store.getters.filtersActive
		},
		onlyCivicPlaces() {
			return this.filtersActive && this.filtersActive.length === 1 && this.filtersActive[0] === "civic_places"
		},
		itemsSponsor() {
			//return this.$store.getters.pdiListOnlySponsor
			const list = this.$store.getters.pdiListOnlySponsor
			return list.filter(obj => {
				if (this.mapCategory) {
					return obj.category === this.mapCategory
				}
				if (this.onlyCivicPlaces) {
					return this.filter(obj.category)
				} else {
					return obj.category === "infopoint" || this.filter(obj.category)
				}
			})
		},
		itemsFiltered() {
			return this.items.filter(obj => {
				if (this.mapCategory) {
					return obj.category === this.mapCategory
				}
				if (this.onlyCivicPlaces) {
					return this.filter(obj.category)
				} else {
					return obj.category === "infopoint" || this.filter(obj.category)
				}
			}) /*.filter(obj => {
				if (this.mapLatLngBounds) {
					return this.mapLatLngBounds.contains({lat:obj.latitude, lng:obj.longitude})
				}
				return false
			})*/
		},
		allItems() {
			return [...this.itemsSponsor, ...this.itemsFiltered]
		},
		icons() {
			return this.$store.state.mapIcons
		},
		mapboxKey() {
			return this.$store.state.mapboxKey
		},
		routeInfoDistance() {
			return this.routeInfo.distance < 1000
				? this.fltr_round(this.routeInfo.distance, 2) + " m"
				: this.fltr_round(this.routeInfo.distance / 1000, 2) + " km"
		},
		routeInfoDuration() {
			return this.routeInfo.duration < 60
				? this.fltr_round(this.routeInfo.duration) + " sec"
				: this.fltr_round(this.routeInfo.duration / 60) + " min"
		},
		targetPos() {
			return this.$store.state.targetPos
		},
		routeDetail() {
			return this.$store.state.routeDetail
		},
		navigatorUrl() {
			let travelmode = ""
			let ios_travelmode = ""
			switch (this.routeType) {
				case "walking":
					travelmode = "walking"
					ios_travelmode = "w"
					break
				case "cycling":
					travelmode = "bicycling"
					ios_travelmode = "w"
					break
				case "driving":
					travelmode = "driving"
					ios_travelmode = "d"
					break
			}

			if (
				/* if we're on iOS, open in Apple Maps */
				navigator.platform.indexOf("iPhone") != -1 ||
				navigator.platform.indexOf("iPod") != -1 ||
				navigator.platform.indexOf("iPad") != -1
			)
				return `https://maps.apple.com/maps?daddr=${this.targetPos.lat}+${this.targetPos.lon}&dirflg=${ios_travelmode}`
			/* else use Google */ else
				return `https://www.google.com/maps/dir/?api=1&destination=${this.targetPos.lat},${this.targetPos.lon}&travelmode=${travelmode}&dir_action=navigate`
		},
		language() {
			return this.$store.getters.language
		},
		filtersParsed() {
			return this.$store.getters.filtersParsed
		},
		isLogged() {
			return this.$store.state.isLogged
		},
		canGeolocation() {
			return this.$store.getters.canGeolocation
		}
	},
	methods: {
		zoomChange(map) {
			//this.layersVisible = false
			//this.zoom = map.target.getZoom()
			this.getMapBounds()
		},
		centerChange(map) {
			//console.log(map)
			//this.center = [map.target.getCenter().lat, map.target.getCenter().lng]
			//this.layersVisible = false
			this.getMapBounds()
		},
		clickPoint(item) {
			this.$emit("clickPoint", item)
		},
		closeModal() {
			this.$emit("close")
		},
		openSettings() {
			this.$emit("openSettings")
		},
		getCurrentPosition() {
			return new Promise((resolve, reject) => {
				if (this.canGeolocation) {
					this.navigator.geolocation.getCurrentPosition(
						position => {
							resolve(position)
						},
						error => {
							reject(error)
						},
						{ maximumAge: 3000, timeout: 10000, enableHighAccuracy: true }
					)
				} else {
					reject({ ack: false })
				}
			})
		},
		watchPosition() {
			console.log("watchPosition")

			this.getCurrentPosition()
				.then(({ coords }) => {
					//console.log(coords, this.currentPos)

					let goToSetCurrentPos = true

					if (
						parseFloat(coords.latitude).toFixed(5) === parseFloat(this.currentPos.lat).toFixed(5) &&
						parseFloat(coords.longitude).toFixed(5) === parseFloat(this.currentPos.lon).toFixed(5)
					) {
						goToSetCurrentPos = false
					}

					if (!this.currentPos.coords) {
						this.zoom = 18
						this.setCenter(coords.latitude, coords.longitude)
					}

					if (this.trackingPos) {
						this.setCenter(coords.latitude, coords.longitude)
					}

					if (goToSetCurrentPos) {
						this.setCurrentPos(coords)
					}

					setTimeout(() => {
						this.watchPosition()
					}, this.watchPositionIntervalTime)
				})
				.catch(err => {
					setTimeout(() => {
						this.watchPosition()
					}, this.watchPositionIntervalTime)
				})
		},
		setCenter(lat, lon) {
			this.$nextTick(() => {
				console.log("setCenter", lat, lon, this.$refs.map)
				//				this.$refs.map.setCenter([lat, lon])
				this.center = [lat, lon]

				setTimeout(() => {
					console.log("re-setCenter")
					//					this.$refs.map.setCenter([lat, lon])
					this.center = [lat, lon]
				}, 1500)
			})
		},
		forwardGeocoding(address) {
			return api.common.forwardGeocoding(address)
		},
		getAddress(lat, lon) {
			//return this.$http.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`)
			//return this.$http.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lon}%2C${lat}.json?access_token=${this.mapboxKey}&types=address`)
			return api.common.reverseGeocoding(lat, lon)
		},
		getRoute(start, end) {
			//return this.$http.get(`https://api.mapbox.com/directions/v5/mapbox/${this.routeType}/${start.lon}%2C${start.lat}%3B${end.lon}%2C${end.lat}.json?access_token=${this.mapboxKey}&overview=full&geometries=geojson`)
			return api.common.directions(this.routeType, start.lon, start.lat, end.lon, end.lat)
		},
		setRoute(route) {
			if (route.distance) this.routeInfo.distance = route.distance
			if (route.duration) this.routeInfo.duration = route.duration

			if (this.routeInfo.distance || this.routeInfo.duration) this.routeInfo.isOpen = true
			else this.routeInfo.isOpen = false

			if (route.geometry) {
				this.geoJSONLayer = {
					type: "Feature",
					properties: {
						name: "Route"
					},
					geometry: route.geometry
				}
			}
		},
		clearRoute() {
			this.routeInfo.distance = null
			this.routeInfo.duration = null
			this.geoJSONLayer = null
		},
		getPointsDistance(x1, y1, x2, y2) {
			const a = x1 - x2
			const b = y1 - y2

			return Math.sqrt(a * a + b * b)
		},
		getCoordsDistance(lat1, lng1, lat2, lng2) {
			const toRad = decDegrees => (decDegrees * Math.PI) / 180
			const EARTH_RADIUS = 6371000
			let a,
				c,
				dLat = toRad(lat2 - lat1),
				dLng = toRad(lng2 - lng1)

			a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(toRad(lat1)) * Math.cos(toRad(lat2))
			c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
			return c * EARTH_RADIUS
		},
		setCurrentPos(coords) {
			//const oldLat = this.currentPos.coords[0]
			//const oldLng = this.currentPos.coords[1]

			this.currentPos.coords = [coords.latitude, coords.longitude]
			//this.currentPos.coords = L.latLng(coords.latitude, coords.longitude)
			this.currentPos.accuracy = coords.accuracy

			const coordsDistance = this.getCoordsDistance(this.currentPos.lat, this.currentPos.lon, coords.latitude, coords.longitude)

			console.log("setCurrentPos", coordsDistance, coords)

			//if(coordsDistance > 2) { //metri
			console.log("setCurrentPos get Address start")

			this.currentPos.lat = coords.latitude
			this.currentPos.lon = coords.longitude

			//if(oldLat !== coords.latitude || oldLng !== coords.longitude){
			this.getAddress(coords.latitude, coords.longitude).then(res => {
				if (res && res.status === 200 && usafe(res, "body.address")) this.currentPos.location = res.body.address
			})
			//}

			this.initSetRoute()

			//}

			this.$store.commit("setCurrentPos", {
				coords: this.currentPos.coords,
				location: this.currentPos.location,
				lat: this.currentPos.coords[0],
				lon: this.currentPos.coords[1],
				accuracy: this.currentPos.accuracy,
				time: this.$moment().format("x")
			})
		},
		initSetRoute(force) {
			if (
				this.currentPos.coords &&
				this.targetPos.coords &&
				this.getCoordsDistance(this.currentPos.lat, this.currentPos.lon, this.targetPos.lat, this.targetPos.lon) < this.routeDistanceLimit
			) {
				console.log("setCurrentPos get Route start")

				this.getRoute(this.currentPos, this.targetPos).then(res => {
					if (res && res.status === 200 && usafe(res, "body.routes.0")) this.setRoute(res.body.routes[0])
				})
			} else {
				if (force) {
					this.routeInfo.isOpen = true
				}
				this.clearRoute()
			}

			if (force) {
				this.resetMapSearch()
				this.goToCurrentPos(true)
			}
		},
		showLaunchNavigatorModal() {
			this.launchNavigatorModalVisible = true
			clearTimeout(this.launchNavigatorModalTimeout)
			this.launchNavigatorModalTimeout = setTimeout(() => (this.launchNavigatorModalVisible = false), 3000)
		},
		launchNavigator() {
			this.showLaunchNavigatorModal()

			let travelmode = ""
			let ios_travelmode = ""
			switch (this.routeType) {
				case "walking":
					travelmode = "walking"
					ios_travelmode = "w"
					break
				case "cycling":
					travelmode = "bicycling"
					ios_travelmode = "w"
					break
				case "driving":
					travelmode = "driving"
					ios_travelmode = "d"
					break
			}
			setTimeout(() => {
				//console.log(navigator.platform)
				if (
					/* if we're on iOS, open in Apple Maps */
					navigator.platform.indexOf("iPhone") != -1 ||
					navigator.platform.indexOf("iPod") != -1 ||
					navigator.platform.indexOf("iPad") != -1
				)
					window.open(`https://maps.apple.com/maps?daddr=${this.targetPos.lat}+${this.targetPos.lon}&dirflg=${ios_travelmode}`)
				/* else use Google */ else
					window.open(
						`https://www.google.com/maps/dir/?api=1&destination=${this.targetPos.lat},${this.targetPos.lon}&travelmode=${travelmode}&dir_action=navigate`
					)

				//window.open(`https://www.google.com/maps/dir/?api=1&destination=${this.targetPos.lat},${this.targetPos.lon}&travelmode=${travelmode}&dir_action=navigate`)
				//window.open(`https://maps.google.com/maps/dir/?api=1&destination=${this.targetPos.lat},${this.targetPos.lon}&travelmode=${travelmode}&dir_action=navigate`)
				//window.open(`maps://maps.google.com/maps/dir/?api=1&destination=${this.targetPos.lat},${this.targetPos.lon}&travelmode=${travelmode}&dir_action=navigate`)
			}, 3000)
		},
		toggleTracking() {
			this.trackingPos = !this.trackingPos
			if (this.trackingPos) {
				this.setCenter(this.currentPos.lat, this.currentPos.lon)
			}
		},
		flyToTargetPos() {
			this.centerChange()
			// this.layersVisible = true
			this.setCenter(this.targetPos.lat, this.targetPos.lon)
		},
		saveTargetPos(i) {
			this.$store.commit("setTargetPos", {
				coords: i.coords,
				location: i.name,
				category: i.category,
				id: i._id,
				lat: i.coords[0],
				lon: i.coords[1],
				time: this.$moment().format("x")
			})

			this.getCurrentPosition().then(({ coords }) => {
				console.log("saveTargetPos coords", coords)
				this.setCurrentPos(coords)
			})
		},
		clearTargetPos() {
			this.closeTargetPos()
			this.clearRoute()
			this.$store.commit("resetTargetPos")
		},
		closeTargetPos() {
			this.routeInfo.isOpen = false
		},
		resetMapSearch() {
			this.mapSearch = ""
			this.$store.commit("setMapSearch", this.mapSearch)
		},
		goMapSearch() {
			this.$store.commit("setMapSearch", this.mapSearch)
			if (this.mapSearch) {
				const pattern = new RegExp(/civic\s?(place)?/gim)
				if (pattern.test(this.mapSearch)) {
					this.$store.dispatch("setOneFilter", "civic_places")
					this.zoom = 6
				} else {
					this.forwardGeocoding(this.mapSearch).then(res => {
						if (res && res.status && res.status === 200 && res.body) {
							const coords = res.body
							this.zoom = 18

							//const suffix = "000"+moment().format("X").toString().split('').reverse().join('')
							//const lat = parseFloat(coords.lat.toString() + suffix)
							//const lon = parseFloat(coords.lon.toString() + suffix)
							this.setCenter(coords.lat, coords.lon)
						}
					})
				}
			}
		},
		goToCurrentPos(setZoom = false) {
			const rNum = this.getRandomIntInclusive(1, 9)
			this.resetMapSearch()

			if (this.currentPos.coords && this.currentPos.coords.length) {
				if (setZoom) {
					this.zoom = 13
				}
				//const suffix = rNum.toString()
				const suffix = moment().format("X").toString().split("").reverse().join("")
				const lat = parseFloat(this.currentPos.coords[0].toString() + suffix)
				const lon = parseFloat(this.currentPos.coords[1].toString() + suffix)
				this.setCenter(lat, lon)
			}
		},
		getMapBounds: _.throttle(function () {
			if (this.$refs.map) {
				let refresh = false
				const newBounds = this.$refs.map.mapObject.getBounds()

				if (this.mapLatLngBounds) {
					if (
						newBounds._northEast.lat === this.mapLatLngBounds._northEast.lat &&
						newBounds._northEast.lng === this.mapLatLngBounds._northEast.lng &&
						newBounds._southWest.lat === this.mapLatLngBounds._southWest.lat &&
						newBounds._southWest.lng === this.mapLatLngBounds._southWest.lng
					) {
						//noop
					} else {
						refresh = true
					}
				} else {
					refresh = true
				}

				if (refresh) {
					this.mapLatLngBounds = this.$refs.map.mapObject.getBounds()

					if (
						this.mapLatLngBounds._northEast.lat &&
						this.mapLatLngBounds._northEast.lng &&
						this.mapLatLngBounds._southWest.lat &&
						this.mapLatLngBounds._southWest.lng
					) {
						if (this.isLogged) {
							// +/- 0.3 to increase area
							const ne_lat = _.toNumber(this.mapLatLngBounds._northEast.lat) + 0.3
							const ne_lng = _.toNumber(this.mapLatLngBounds._northEast.lng) + 0.3
							const sw_lat = _.toNumber(this.mapLatLngBounds._southWest.lat) - 0.3
							const sw_lng = _.toNumber(this.mapLatLngBounds._southWest.lng) - 0.3
							this.$store.dispatch("getPDI", {
								currentZoom: this.zoom,
								maxZoom: this.mapOptions.maxZoom,
								ne: ne_lat + ";" + ne_lng,
								sw: sw_lat + ";" + sw_lng
							})
						}
					}
				}
			}
			console.log("getMapBounds", this.mapLatLngBounds)
		}, mapLatLngBoundsIntervalTime),
		getRandomIntInclusive(vmin, vmax) {
			const min = Math.ceil(vmin)
			const max = Math.floor(vmax)
			return Math.floor(Math.random() * (max - min + 1)) + min
		},
		getName(item) {
			switch (this.$i18n.locale) {
				case "it":
					return item.name || item.nameEN
				case "en":
					return item.nameEN || item.name
				default:
					return ""
			}
		},
		getMarkerIcon(i) {
			/*let icon = i.category

			if (i.isEvent) {
				icon += '_event'
			}
			if (i.sponsor) {
				icon += '_sponsor'
			}*/

			let url = i.category
			let classes = ""

			if (i.isEvent) {
				url += "-ev.svg"
			} else {
				url += ".png"
			}

			if (i.sponsor && i.isEvent) {
				classes = "event-sponsor"
			} else {
				if (i.sponsor) {
					classes = "sponsor"
				}

				if (i.isEvent) {
					classes = "event"
				}
			}

			// return this.icons[icon]

			return L.divIcon({
				html: `<div class="label"><span>${this.getName(i)}</span></div><img src="map-icons/${url}"></img>`,
				iconSize: [24, 24],
				//iconAnchor: [12, 12],
				className: `category-map-icon ${classes}`
			})
		},
		fetchPopupSponsor() {
			const lat = this.center.lat || this.center[0]
			const lon = this.center.lng || this.center[1]

			const sponsors = _.take(geolib.orderByDistance({ latitude: lat, longitude: lon }, this.popupSponsors), 1)
			console.log("check sponsors:", sponsors.length)
			if (sponsors.length && this.searchStatus !== "focus") {
				const item = sponsors[0]

				if (!item.isEvent) {
					api.pdi.getAll({ id: item._id }).then(data => {
						if (data && data.status && data.status === 200 && data.body) {
							this.openPopupSponsor(data.body)
						}
					})
				} else {
					api.event.getAll({ id: item._id }).then(data => {
						if (data && data.status && data.status === 200 && data.body) {
							this.openPopupSponsor(data.body)
						}
					})
				}
			}
		},
		openPopupSponsor(item) {
			console.log("open sponsors:", item)
			this.popupSponsor = item
			this.$store.commit("setSponsorShown", item._id)

			setTimeout(() => {
				this.closePopupSponsor()
			}, 8000)
		},
		closePopupSponsor() {
			this.popupSponsor = null
		},
		getName(item) {
			switch (this.$i18n.locale) {
				case "it":
					return item.name || item.nameEN
				case "en":
					return item.nameEN || item.name
				default:
					return ""
			}
		},
		getDescription(item) {
			if (item.description || item.descriptionEN) {
				switch (this.$i18n.locale) {
					case "it":
						return item.description || item.descriptionEN || ""
					case "en":
						return item.descriptionEN || ""
					default:
						return ""
				}
			}
			return ""
		}
	},
	mounted() {
		console.log("mounted v-map")
		setTimeout(() => {
			this.asyncComponent = "v-map"
			console.log("setTimeout 2000 v-map")
		}, 1000)
		setTimeout(() => {
			this.getMapBounds()
		}, 2000)

		this.watchPosition()

		//this.$store.dispatch('getPDI')

		EventBus.$on("open:map", data => {
			console.log("open:map")
			if (data && data.category) {
				this.mapCategory = data.category
			}
			this.mapSearch = this.$store.getters.lastMapSearch
			this.goMapSearch()
		})

		EventBus.$on("map:restore-category", () => {
			console.log("map:restore-category")
			this.mapCategory = null
			clearInterval(this.popupSponsorInterval)
			this.popupSponsorInterval = null
		})

		EventBus.$on("init:map-page", () => {
			console.log("init:map-page")

			if (!this.popupSponsorInterval) {
				console.log("%c init sponsor timeout: 13s", "background: #5051f0; color: #bada55")
				setTimeout(() => {
					this.fetchPopupSponsor()

					console.log("%c init sponsor interval: 21s", "background: #5051f0; color: #fff")
					this.popupSponsorInterval = setInterval(() => {
						this.fetchPopupSponsor()
					}, 21000)
				}, 13000)
			}
		})

		EventBus.$on("map:set-route", () => {
			console.log("map:set-route")
			this.initSetRoute(true)
		})

		setInterval(() => {
			this.getMapBounds()
			//console.log("aaaa", this.itemsFiltered.length)
		}, 10000)
		//L.LayerGroup({}).collision({ margin: 5 })
	},
	components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.map-page {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;

	.toolbar {
		box-shadow: 0px 140px 30px -30px rgba(255, 255, 255, 0.8) inset;
		padding-bottom: 100px;
		box-sizing: content-box;
		z-index: 401;

		.toolbar-button {
			color: #000;
			opacity: 0.5;
		}

		.toolbar--material__center {
			text-align: center;
		}
	}

	.leaflet-container {
		.leaflet-control-attribution {
			display: none;
		}

		.leaflet-pane {
			.map-point {
				.point {
					width: 18px;
					height: 18px;
					margin-left: -3px;
					margin-top: -3px;
					border: 4px solid rgba(0, 153, 187, 0.7);
					border-radius: 50%;
					box-sizing: border-box;
					background-color: rgba(255, 255, 255, 0.4);
				}
			}
		}

		.leaflet-popup-content-wrapper {
			box-shadow: 0 3px 14px rgba(0, 0, 0, 0.1);
			border-radius: 5px;
			min-width: 300px;
		}
		.leaflet-popup-tip {
			box-shadow: 0 3px 14px rgba(0, 0, 0, 0.1);
		}

		.leaflet-tooltip-pane {
			z-index: 590;
			opacity: 0.7;
		}
		.leaflet-tooltip {
			margin-top: -18px;
			padding: 0 5px;
		}

		.category-map-icon {
			border: 1px solid #1d84ff;
			border-radius: 50%;
			padding: 2px;

			&.sponsor {
				border: 3px solid rgb(220, 192, 0);
				background: rgba(220, 192, 0, 0.3);
			}

			&.event {
				border: none;
				padding: 0;
				animation: eventPulse 1s linear infinite alternate;
			}

			&.event-sponsor {
				border: none;
				padding: 0;
				animation: eventSponsorPulse 1s linear infinite alternate;
			}

			div.label {
				display: none;
				position: absolute;
				bottom: 36px;
				left: 12px;
				transform: translateX(-50%);
				text-align: center;
				max-width: 160px;
				min-width: 110px;
				padding: 2px 6px;
				border-radius: 4px;
				text-overflow: ellipsis;
				font-family: Barlow, -apple-system, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
				opacity: 0.9;
				background-color: #fff;
				color: #222;
				user-select: none;
				box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);

				&:before {
					position: absolute;
					pointer-events: none;
					border: 6px solid transparent;
					border-top-color: #fff;
					background: transparent;
					content: "";
					bottom: -12px;
					left: 50%;
					margin-left: -5px;
				}

				/*span {
					width: 100%;
					text-overflow: ellipsis;
					overflow: hidden;
					display: block;
				}*/
			}

			img {
				width: 100%;
			}
		}

		.marker-cluster-small {
			// background-color: rgba(101, 64, 249, 0.2);
			background-color: rgba(0, 201, 164, 0.2);
			width: 36px !important;
			height: 36px !important;
			margin-left: -18px !important;
			margin-top: -18px !important;
			border-radius: 50% !important;
			//animation: fadein .5s;

			div {
				// background-color: rgba(101, 64, 249, 0.3);
				background-color: rgba(0, 201, 164, 0.3);
				width: 24px;
				height: 24px;
				margin-left: 6px;
				margin-top: 6px;
				border-radius: 50% !important;

				span {
					opacity: 0;
				}
			}
		}

		.marker-cluster-medium {
			// background-color: rgba(101, 64, 249, 0.4);
			background-color: rgba(0, 201, 164, 0.4);
			width: 50px !important;
			height: 50px !important;
			margin-left: -25px !important;
			margin-right: -25px !important;
			border-radius: 50% !important;

			div {
				// background-color: rgba(101, 64, 249, 0.3);
				background-color: rgba(0, 201, 164, 0.3);
				width: 30px;
				height: 30px;
				margin-left: 10px;
				margin-top: 10px;
				border-radius: 50% !important;

				span {
					opacity: 0;
				}
			}
		}

		.marker-cluster-large {
			// background-color: rgba(101, 64, 249, 0.6);
			background-color: rgba(0, 201, 164, 0.6);
			width: 60px !important;
			height: 60px !important;
			margin-left: -30px !important;
			margin-right: -30px !important;
			border-radius: 50% !important;

			div {
				// background-color: rgba(101, 64, 249, 0.3);
				background-color: rgba(0, 201, 164, 0.3);
				width: 40px;
				height: 40px;
				margin-left: 10px;
				margin-top: 10px;
				border-radius: 50% !important;

				span {
					opacity: 0;
				}
			}
		}
	}

	&.show-tooltip {
		.category-map-icon .label {
			display: block !important;
		}
	}

	.vue2leaflet-map {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;

		&.blur {
			filter: blur(2px);
		}
	}

	.map-toolbar {
		position: absolute;
		top: 40px;
		left: 0;
		right: 0;
		width: 100%;
		z-index: 402;

		.label {
			margin: 14px;
			font-size: 14px;
			color: #665e79;
		}

		.route-type {
			width: 100%;
			height: 30px;
			padding: 0 7px;
			box-sizing: border-box;

			button {
				background: #ededf0;
				margin: 0px 7px;
				border: none;
				border-radius: 4px;
				color: #a8a8ac;
				font-size: 14px;
				font-weight: 500;
				cursor: pointer;
				outline: none;
				transition: all 0.5s;

				i {
					margin-right: 10px;
				}

				&.active {
					background: #fff;
					color: #6440f9;
					box-shadow: 0px 5px 20px 0px rgba(74, 88, 244, 0.3);
				}
			}
		}

		.navigate-box {
			margin: 14px;
			background: #fff;
			box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
			border-radius: 4px;
			overflow: hidden;

			.input-wrap {
				padding: 0px 20px;

				.from,
				.to {
					padding: 6px 0;

					.image-box {
						border: 1px dotted #979797;
						border-radius: 50%;
						width: 28px;
						height: 28px;
						text-align: center;
					}

					input {
						font-weight: 600;
						font-size: 15px;
						margin: 0;
						border: none;
						outline: none;
						padding: 0 10px;
					}

					.map-search {
						color: #8677ad;
						font-size: 25px;
						cursor: pointer;
						margin-left: 5px;
					}

					.distance {
						background: #ececf0;
						color: #7f7f84;
						border-radius: 2px;
						padding: 6px;
						font-size: 11px;
						font-weight: 500;
						height: 10px;
						line-height: 10px;
					}
				}
				.from {
					.image-box {
						line-height: 37px;

						img {
							width: 70%;
						}
					}

					input {
						color: #8677ad;
					}
				}
				.to {
					border-top: 1px solid #efecf5;

					.image-box {
						line-height: 37px;

						img {
							width: 70%;
						}
					}

					input {
						color: #5a4f77;
					}
				}
			}

			& > button {
				color: #fff;
				background: #544df1;
				background: -moz-linear-gradient(left, #544df1 0%, #2184fe 100%);
				background: -webkit-linear-gradient(left, #544df1 0%, #2184fe 100%);
				background: linear-gradient(to right, #544df1 0%, #2184fe 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#544df1', endColorstr='#2184fe',GradientType=1 );
				border: none;
				width: 100%;
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
				padding: 7px;
				font-size: 12px;
				font-weight: 600;
				cursor: pointer;
			}
		}

		.current-pos-btn {
			margin: 14px;
			margin-top: 0;
			background: #fff;
			box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
			border-radius: 4px;
			width: 25px;
			height: 25px;
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			font-size: 20px;
			padding: 4px;
			color: #5051f0;
		}
	}

	.map-info-bar {
		position: absolute;
		bottom: 14px;
		right: 14px;
		left: 14px;
		height: 70px;
		z-index: 401;
		overflow: hidden;
		border-radius: 4px;
		box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
		background: #fff;

		.image-box {
			width: 70px;
			padding: 15px;
			box-sizing: border-box;

			img {
				width: 100%;
			}
		}

		.content {
			font-weight: 500;

			.title {
				color: #5151f1;
				cursor: pointer;
			}
			.sub-title {
				opacity: 0.5;
				font-size: 10px;
				margin-top: 2px;
			}
			.description {
				color: #5151f1;
				font-size: 13px;
				margin-top: 4px;
				font-weight: 400;
			}
		}

		.buttons-box {
			padding: 14px;

			div > button {
				background: transparent;
				cursor: pointer;
				border: none;
				outline: none;
				margin: 0;
				padding: 0;
				font-weight: 500;

				.icon {
					background: #366ef9;
					color: white;
					margin-right: 7px;
					width: 15px;
					height: 15px;
					display: inline-block;
					line-height: 14px;
					border-radius: 4px;
				}
				.label {
					color: #898e95;
				}
			}
		}
	}

	.launch-navigator-modal {
		padding: 30px;
		text-align: center;
	}

	.popup-sponsor {
		background: #fff;
		color: #182a4a;
		position: absolute;
		overflow: hidden;
		top: 12px;
		left: 12px;
		right: 12px;
		height: 160px;
		z-index: 999;
		border-radius: 4px;
		box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
		animation: 0.38s ease-in-out fadeSlideInDown;

		.wrapper {
			height: 100%;
			width: 100%;
			overflow: hidden;
			cursor: pointer;

			.image {
				height: 160px;
				width: 160px;

				img {
					display: block;
					height: 100%;
					width: 100%;
					object-fit: cover;
					object-position: center center;
				}
			}

			.info {
				padding: 10px 15px;
				height: 160px;
				overflow: hidden;
				box-sizing: border-box;

				.name {
					font-weight: 600;
					font-size: 16px;
					margin-bottom: 6px;
					color: #182a4a;
				}
				.address {
					font-weight: 500;
					font-size: 10px;
					margin-bottom: 6px;
					color: #3f4e68;
				}
				.description {
					font-weight: 400;
					font-size: 10px;
					margin-bottom: 6px;
					color: #82878f;
					overflow: hidden;
					position: relative;

					&::after {
						content: "";
						display: block;
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						width: 100%;
						height: 20px;
						background: linear-gradient(180deg, rgba(255, 255, 255, 0.5396533613445378) 0%, rgba(255, 255, 255, 1) 100%);
					}
				}
				.bottom {
					.accessibility {
						i {
							color: rgb(220, 192, 0);
							font-size: 12px;
						}
						span {
							font-size: 10px;
							color: #33435f;
							font-weight: 600;
						}
					}
					.category {
						img {
							display: block;
							width: 25px;
							height: 25px;
						}
					}
				}
			}
		}
	}
}

@keyframes fadeSlideInDown {
	from {
		opacity: 0;
		transform: translateY(-50%);
	}
	to {
		opacity: 1;
	}
}

@media (max-height: 400px) {
	.map-page {
		.toolbar {
			.center {
				visibility: hidden;
			}
		}

		.map-toolbar {
			top: 10px;
			left: 40px;
			right: 40px;
			width: auto;

			.map-label {
				display: none;
			}

			.navigate-box > button {
				display: none;
			}
		}

		.map-info-bar {
			left: 54px;
			right: 54px;
		}
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

.slide-top-enter-active,
.slide-top-leave-active {
	transition: all 0.5s;
}
.slide-top-enter, .slide-top-leave-to /* .slide-top-leave-active below version 2.1.8 */ {
	transform: translateY(100px);
	opacity: 0;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes eventPulse {
	from {
		box-shadow: 0px 0px 0px 0px #fff, 0px 0px 0px 0px #2a72fe;
	}
	to {
		box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.7), 0px 0px 0px 6px rgba(42, 113, 254, 0.8);
	}
}

@keyframes eventSponsorPulse {
	from {
		box-shadow: 0px 0px 0px 0px #fff, 0px 0px 0px 0px #2a72fe;
	}
	to {
		box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.7), 0px 0px 0px 6px rgba(220, 192, 0, 1);
	}
}
</style>
