<template>
	<div class="edit-user flex column">
		<v-ons-toolbar>
			<div class="left">
			</div>
			<div class="center">{{$t("Edit password")}}</div>
			<div class="right">
				<v-ons-toolbar-button icon="md-close" @click="closeModal"></v-ons-toolbar-button>
			</div>
		</v-ons-toolbar>

		<div class="page-wrapper box grow">
			<div class="page-content">
		
				<div class="form-wrap">
					<div class="form-item">
						<div class="label">
							{{$t("Old password")}}
						</div>
						<div class="field">
							<input id="o_password" type="password" :placeholder="$t('Old password')" v-model="form.o_password">
						</div>
					</div>

					<div class="form-item">
						<div class="label">
							{{$t("New password")}}
						</div>
						<div class="field">
							<input id="n_password" type="password" :placeholder="$t('New password')" v-model="form.n_password">
						</div>
					</div>
					
					<div class="form-item" v-if="form.n_password && !isValidPassword">
						<div class="hint">
							{{$t("Password not strong")}}
						</div>
					</div>

					<div class="form-item">
						<div class="label">
							{{$t("Confirm password")}}
						</div>
						<div class="field">
							<input id="c_password" type="password" :placeholder="$t('Confirm password')" v-model="form.c_password">
						</div>
					</div>

					<div class="form-item submit-box">
						<button @click="send" :disabled="!submitValid" aria-label="save">{{$t("Done")}}!</button>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import api from '../api'

export default {
	name: "ChangePassword",
	data() {
		return {
			form: {}
		}
	},
	computed: {
		isValidPassword() {
			const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/;
			return re.test(this.form.n_password);
		},
		submitValid() {
			let ctrl = false
			
			if(
				this.isValidPassword &&
				this.form.o_password && 
				this.form.n_password && 
				this.form.c_password && 
				this.form.n_password === this.form.c_password
			) {
				ctrl = true
			}
	
			return ctrl
		}
	},
	methods: {
		closeModal() {
			this.$emit('close')
		},
		send() {
			if(this.submitValid) {
				api.auth.changePassword(this.form.o_password, this.form.n_password).then(data => {
					this.closeModal()
					this.$ons.notification.toast(this.$t('Profile updated'), { timeout: 1000, animation: 'fall' })
				}).catch(err => { 
					this.$ons.notification.toast(this.$t('Save error'), { timeout: 1000, animation: 'fall' })
				})
			}
		}
	},
	mounted() {},
	components: {}
};
</script>

<style lang="scss" scoped>
.edit-user {
	height: 100%;
	width: 100%;
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	box-sizing: border-box;
	overflow: hidden;
	background: #fff;
	max-width: 640px;
	margin: 0 auto;
	color: #000;

	.page__background {
		background: #fff;
	}

	.page-wrapper {
		padding: 20px;
		text-align: left;
		box-sizing: border-box;
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		padding-top: 65px;
	}

	.form-wrap {
		box-sizing: border-box;
		max-width: 400px;
		margin: 0 auto;
	
		.form-item {
			margin: 24px 14px;
			box-sizing: border-box;
			display: block;
			clear: both;

			.label {
				font-size: 14px;
				opacity: .7;
				margin-bottom: 12px;
			}
			.field {
				input, textarea, select {
					border-radius: 4px;
					padding: 3px;
					background: #fff;
					box-shadow: 0px 0px 3px 0px rgba(26, 101, 255, 0.2);
					border: none;
					outline: none;
					padding: 7px 10px;
					width: 100%;
					box-sizing: border-box;
					-webkit-appearance: none;
					-moz-appearance: none;
				}

				&.select-field {
					position: relative;

					&:after {
						content: '\25BE';
						position: absolute;
						right: 10px;
						top: calc(50% - 2px);
						display: block;
						transform: translateY(-50%);
						line-height: 1;
						opacity: .5;
						color: #1c61ff;
					}

					select {
						cursor: pointer;
					}
				}

				textarea {
					resize: vertical;
					min-height: 100px;
				}
			}
			.hint {
				color: #cf0f3a;
				word-break: break-word;
				white-space: initial;
				font-size: 14px;
			}

			&.submit-box {
				button {
					background: #1D84FF;
					padding: 10px 0;
					width: 100%;
					margin: 0 auto;
					display: block;
					border: none;
					border-radius: 4px;
					color: #fff;
					margin-top: 20px;
					cursor: pointer;

					&[disabled] {
						cursor: not-allowed;
						opacity: .5;
					}
				}

			}
		}
	}

}
</style>

<style lang="scss">
.edit-user {
	.toolbar {
		.toolbar__title {
			color: #000;
		}
		.toolbar-button {
			color: #000;
			opacity: .5;
		}
	}

}
</style>
