import "onsenui/css/onsenui.css"
import "onsenui/css/onsen-css-components.css"
import "flex.box/src/flexbox.css"
import "leaflet/dist/leaflet.css"
//import '../node_modules/mdi/scss/materialdesignicons.scss'
import "@mdi/font/css/materialdesignicons.min.css"

import Vue from "vue"
import VueOnsen from "vue-onsenui"
//import Vue2LeafletLTileLayer from './components/LTileLayer_v1'  // v1
import Vue2LeafletLTileLayer from "./components/LTileLayer_v2" // v2
//import Vue2Leaflet from 'vue2-leaflet'  // v1
//import Vue2Leaflet from 'vue2-leaflet/src/main'  // v1 - debug
import * as Vue2Leaflet from "vue2-leaflet" // v2
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster"
import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet.markercluster/dist/MarkerCluster.Default.css"

import VueMoment from "vue-moment"
import VueTruncate from "vue-truncate-filter"
import VueResource from "vue-resource"
import VueI18n from "vue-i18n"

import locale from "./locale.json"
import store from "./store/store"
import App from "./App.vue"

import moment from "moment-timezone"
//moment.locale('it')
//moment.tz.setDefault("Europe/Rome")

Vue.config.productionTip = false
Vue.config.performance = process.env.NODE_ENV === "development"

Vue.use(VueI18n)
Vue.use(VueOnsen)
Vue.use(VueMoment, { moment })
Vue.use(VueTruncate)
Vue.use(VueResource)
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
	iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
	iconUrl: require("leaflet/dist/images/marker-icon.png"),
	shadowUrl: require("leaflet/dist/images/marker-shadow.png")
})
Vue.component("l-map", Vue2Leaflet.LMap)
Vue.component("l-marker", Vue2Leaflet.LMarker)
Vue.component("v-layer-group", Vue2Leaflet.LLayerGroup)
Vue.component("l-tile-layer", Vue2Leaflet.LTileLayer)
Vue.component("v-map", Vue2Leaflet.LMap)
Vue.component("v-tilelayer", Vue2LeafletLTileLayer)
Vue.component("v-marker", Vue2Leaflet.LMarker)
Vue.component("v-circle", Vue2Leaflet.LCircle)
Vue.component("v-geojson", Vue2Leaflet.LGeoJson)
Vue.component("v-polyline", Vue2Leaflet.LPolyline)
Vue.component("v-popup", Vue2Leaflet.LPopup)
Vue.component("v-tooltip", Vue2Leaflet.LTooltip)
Vue.component("v-marker-cluster", Vue2LeafletMarkerCluster)

import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"

if (process.env.NODE_ENV !== "development") {
	Sentry.init({
		Vue,
		release: "stendhapp@" + process.env.VUE_APP_VERSION,
		dsn: "https://cf043d15cd274bcfaa6e3ade29a6a9d7@o383688.ingest.sentry.io/5213985",
		integrations: [
			new BrowserTracing({
				tracePropagationTargets: ["localhost", "app.stendhapp.com", "panel.stendhapp.com", /^\//]
			})
		],
		blacklistUrls: [
			// Chrome extensions
			/extensions\//i,
			/^chrome:\/\//i,
			// localhost
			/127\.0\.0\.1:8080\//i,
			/localhost:8080\//i
		]
	})
}

const fltr_round = (value, decimals) => {
	if (!value) {
		value = 0
	}

	if (!decimals) {
		decimals = 0
	}

	value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
	return value
}

Vue.mixin({
	methods: {
		fltr_round
	}
})
Vue.filter("round", fltr_round)

let localeLang = "en"
let storageLang = false
let localVuex = localStorage.getItem("vuex")
if (localVuex) {
	try {
		let parsedVuex = JSON.parse(localVuex)
		if (parsedVuex && parsedVuex.language) {
			localeLang = parsedVuex.language
			storageLang = true
		}
	} catch (err) {}
}
if (!storageLang) {
	if (window.navigator && window.navigator.language) {
		let w_language = window.navigator.language.toLocaleLowerCase()
		if (w_language === "it" || w_language === "it-it") {
			localeLang = "it"
		}
	}
}

const i18n = new VueI18n({
	locale: localeLang, // set locale
	messages: locale // set locale messages
})

new Vue({
	store,
	i18n,
	render: h => h(App)
}).$mount("#app")
