import { Http } from "vue-resource"
import usafe from "undefsafe"
import _ from "lodash"
import moment from "moment-timezone"
import utils from "../../utils"

Http.options.credentials = true
Http.options.xhr = { withCredentials: true }

export default {
	getAll({ id, currentZoom, maxZoom, ne, sw, lat, lon, filters }) {
		if (id === "short")
			return Http.get(
				`${utils.getUrl("points-visible-short")}?currentZoom=${currentZoom || 0}&maxZoom=${maxZoom || 0}&ne=${ne || 0}&sw=${
					sw || 0
				}&filters=${filters || ""}`
			)

		if (id === "nearest") return Http.get(`${utils.getUrl("points-visible-nearest")}?lat=${lat || 0}&lon=${lon || 0}&filters=${filters || ""}`)

		if (id === "discover") return Http.get(`${utils.getUrl("points-discover")}?lat=${lat || 0}&lon=${lon || 0}&filters=${filters || ""}`)

		if (id) return Http.get(`${utils.getUrl("points-visible")}/${id}`)

		return Http.get(`${utils.getUrl("points-visible")}`)
	},
	savePointUser(point) {
		return Http.post(`${utils.getUrl("savepointuser")}`, point)
	},
	sendFeedback(message, photo, pdiId) {
		return Http.post(`${utils.getUrl("savefeedback")}`, {
			message,
			photo,
			pdiId
		})
	},
	addLikedPlace(pdiId) {
		return Http.post(`${utils.getUrl("add-liked-place")}`, { _id: pdiId })
	},
	addSharedPlace(pdiId) {
		return Http.post(`${utils.getUrl("add-shared-place")}`, { _id: pdiId })
	},
	addOpenedPlace(pdiId) {
		return Http.post(`${utils.getUrl("add-opened-place")}`, { _id: pdiId })
	},
	removeLikedPlace(pdiId) {
		return Http.post(`${utils.getUrl("remove-liked-place")}`, { _id: pdiId })
	},
	getOpenedPlaces() {
		return Http.get(`${utils.getUrl("get-opened-places")}`)
	},
	getLikedPlaces() {
		return Http.get(`${utils.getUrl("get-liked-places")}`)
	},
	getSharedPlaces() {
		return Http.get(`${utils.getUrl("get-shared-places")}`)
	},
	isLikedPlace(pdiId) {
		return Http.get(`${utils.getUrl("is-liked-place")}/${pdiId}`)
	}
	/*get(IDSCHEDA) {
		return Http.post(...util.getParams('select/WSMonitorSchedaXML', {"PIDSCHEDA": IDSCHEDA})).then(res => {
			const obj =  _.fromPairs(res.body.filter(data => {
				return data.IDVECTOR && data.XML_FIELD
			}).map(type => {
				const params = x2jsParser.xml2js(type.XML_FIELD)
				params.record.DATETIME_ACTION = type.DATETIME_ACTION
				return [type.IDVECTOR, params.record || params || {}]
			}))

			//console.log(IDSCHEDA, obj)

			const idscheda = IDSCHEDA
			let info   = {}
			let analog = {}
			let flow   = {SE:{}, SO:{}, NE:{}, NO:{}}
			let nrel   = {}
			let motors = {T:{}, L:{}}

			if(usafe(obj, 'INFO_SCHEDA')){
				info = this.parseInfo(obj.INFO_SCHEDA)
			}
			if(usafe(obj, 'STAT_ANALOG')){
				analog = this.parseAnalog(obj.STAT_ANALOG)
			}
			if(usafe(obj, 'STAT_FLUSS_1') || usafe(obj, 'STAT_FLUSS_2') || usafe(obj, 'STAT_FLUSS_3') || usafe(obj, 'STAT_FLUSS_4')){
				flow = this.parseFlow([obj.STAT_FLUSS_1, obj.STAT_FLUSS_2, obj.STAT_FLUSS_3, obj.STAT_FLUSS_4])
			}
			if(usafe(obj, 'MOTOR_T') || usafe(obj, 'MOTOR_L')){
				motors = this.parseMotor(obj.MOTOR_T, obj.MOTOR_L)
			}
			if(usafe(obj, 'STAT_NREL')){
				nrel = this.parseNrel(obj.STAT_NREL)
			}

			return {
				idscheda,
				info,
				analog,
				flow,
				nrel,
				motors
			}
		}).catch(err => {
			throw new Error('GET_TRACKER_XML: ' + JSON.stringify(err.message || err))
		})
	},
	parseInfo(data) {
		const info = {
			emergencyInfo: parserCommon.report(data.emergency_info) || [],
			sunInfo: parserCommon.sunInfo(data.sun_info, data.brightnes_info) || {},
			state: this.parseInfoState(data.state_info),
			stateText: data.state_info,
			lastCommand: data.last_command || '',
			photo: util.config.API_URL +  '/image/' + data.IDScheda +  '/' + new Date().getTime() || "",
			timestamp: moment.utc(data.DATETIME_ACTION).local().format("x")
		}

		return info
	},
	parseAnalog(data) {
		const analog = {
			I1: data.i1_measure || 0,
			I2: data.i2_measure || 0,
			TDX1: data.tdx1_measure || 0,
			TDX2: data.tdx2_measure || 0,
			TSX1: data.tsx1_measure || 0,
			TSX2: data.tsx2_measure || 0,
			V1: data.v1_measure || 0,
			V2: data.v2_measure || 0,
			timestamp: data.timestamp_i2_measure // ?
		}

		return analog
	},
	parseFlow(data) {
		const flow = {SE:{}, SO:{}, NE:{}, NO:{}}

		for(let val of data) {
			flow[util.flowGPIO[val.gpiokey]].litersPerMinute = val.litri_al_minuto || 0
			flow[util.flowGPIO[val.gpiokey]].timestamp = val.timestamp_sampling || 0
		}

		return flow
	},
	parseMotor(T, L) {
		const motors = {T:{}, L:{}}
		const args = {T, L}

		for(let key in args) {
			let obj = args[key]
			const motor = {
				angle: 0,
				encoder: 0,
				nrel: 0,
				rawAngle: 0,
				electricCurrentZero: parseInt(obj.electric_current_zero) || 0,
				offset: 0, // ?
				remoteControl: parseInt(obj.remote_control) || 0,
				remoteStep: parseInt(obj.remote_step) || 0,
				coefficientPwm: parseInt(obj.coefficient_pwm) || 0,
				pwmLow: parseInt(obj.pwm_low) || 0,
				pwmMax: parseInt(obj.pwm_max) || 0,
				freqPwm: parseInt(obj.freq_pwm) || 0,
				state: obj.state || 0,
				stateOut: obj.state_out || 0,
				tetaT: obj.TetaT || 0,
				timestamp: obj.DATETIME_ACTION || ''
			}

			try {
				if(key === 'T' || key === 'L') {
					if(obj.RawEncoder && obj.RawEncoder.indexOf(';') !== -1) {
						let params = obj.RawEncoder.split(';')
						if(params[0].indexOf('=') !== -1) motor.angle = params[0].split('=')[1]
						if(params[1].indexOf('=') !== -1) motor.rawAngle = params[1].split('=')[1]
					}
					if(obj.NrelAngle && obj.NrelAngle.indexOf(';') !== -1) {
						let params = obj.NrelAngle.split(';')
						if(params[0].indexOf('=') !== -1) motor.nrel = params[0].split('=')[1]
						if(params[1].indexOf('=') !== -1) motor.encoder = params[1].split('=')[1]
					}
				}
			} catch(err) {}

			motors[key] = motor
		}

		return motors
	},
	parseNrel(data){
		const nrel = {
			encoderX: data.encoder_x || 0,
			encoderY: data.encoder_y || 0,
			nrelX: data.nrel_x || 0,
			nrelY: data.nrel_y || 0,
			timestamp: data.timestamp_sampling || 0
		}

		return nrel
	},
	parseInfoState(state) {
		for(let key in util.trackerState) {
			if(state === key) {
				return parseInt(util.trackerState[key])
			}
		}

		return 1
	},
	getConfiguration(idscheda) {
		return Http.post(...util.getParams('select/WSMonitorConfigSchedaXML', {"PIDSCHEDA": idscheda})).then(res => {
			if(res && res.status === 200 && res.body) {
				let config = []

				const obj =  _.fromPairs(res.body.filter(data => {
					return data.IDVECTOR && data.XML_FIELD
				}).map(type => {
					const params = x2jsParser.xml2js(type.XML_FIELD)
					return [type.IDVECTOR, params.record || params || {}]
				}))

				if(usafe(obj, 'CONFIG_SCHEDA') && usafe(obj, 'CONFIG_LABELS')){
					for(let key in obj.CONFIG_LABELS) {
						if(key !== 'IDVector'){
							config.push({
								DESCRIPTION : "",
								GROUP : "",
								IDScheda : idscheda,
								KEYFIELD : key,
								LABEL : obj.CONFIG_LABELS[key],
								NEWVAL : obj.CONFIG_SCHEDA[key],
								VALFIELD : obj.CONFIG_SCHEDA[key]
							})
						}
					}
				}

				return config
			} else {
				return []
			}
		}, res => {
			throw new Error('WSMonitorConfigSchedaXML: ' + JSON.stringify(res));
		})
	},
	parseCommands(commands){
		return commands
			.filter(({key, value}) => _.trim(key) && _.trim(value))
			.map(({key, value}) => _.trim(key)+'='+_.trim(value))
			.join(';')
	},
	setConfig(idscheda, params) {
		let idcommand = util.getRandomId()

		return Http.post(...util.getParams('action/WSManagerRecordConfig', {
			"PIDSCHEDA": idscheda,
			"PXML_FIELD_UPD": this.parseCommands(params)
		})).then(res => {
			if(res && res.status === 200) {
				return {idcommand:idcommand, ok:true}
			} else {
				return {idcommand:null, ok:false}
			}
			return res
		}, res => {
			throw new Error('WSManagerRecordConfig: ' + JSON.stringify(res));
		})
	},
	getLogAnomaly(idscheda) {
		const data = {
			"PIDTABLE": "",
			"PIDSCHEDA": idscheda,
			"PACTION": "SELECT",
			"PSTATE": "",
			"POFFSET": "0",
			"PROWS": "100"
		}

		return Http.post(...util.getParams('select/WSManagerLogAnomaly', data)).then(res => {
			if(res && res.status === 200 && res.body) {
				let logs = res.body

				if(logs.length) {
					logs = logs.map(obj => {
						let log = {}
						let stateText = ''
						let state = obj.STATE
						switch (obj.STATE) {
							case 0:
								stateText = 'open'
								break;
							case 1:
								state = 0
								stateText = 'try 1'
								break;
							case 2:
								state = 0
								stateText = 'try 2'
								break;
							case 3:
								state = 0
								stateText = 'try 3'
								break;
							case 5:
								stateText = 'processing'
								break;
							case 10:
								stateText = 'closed'
								break;
						}
						log.stateText = stateText
						log.state = state
						log.label = obj.TYPE
						log.id = obj.IDTABLE
						log.timestamp_action = moment.utc(obj.DATETIME_ACTION).local().format("x")
						log.timestamp_insert = moment.utc(obj.DATETIME_INSERT).local().format("x")
						return log
					})
					logs = _.orderBy(logs, 'timestamp_action', 'desc')
				}

				return logs
			} else {
				return []
			}
		}, res => {
			throw new Error('WSManagerLogAnomaly: ' + JSON.stringify(res));
		})
	},
	setLogAnomaly(idscheda, idtable, state) {
		const data = {
			"PIDTABLE": idtable,
			"PIDSCHEDA": idscheda,
			"PACTION": "UPDATE",
			"PSTATE": state,
			"POFFSET": "0",
			"PROWS": "0"
		}

		return Http.post(...util.getParams('select/WSManagerLogAnomaly', data)).then(res => {
			if(res && res.status === 200) {
				return {ok:true}
			} else {
				return {ok:false}
			}
		}, res => {
			throw new Error('WSManagerLogAnomaly: ' + JSON.stringify(res));
		})
	},
	setRemoteFirstAid(idscheda, motore, command, value) {
		let idcommand = util.getRandomId()

		if(!_.isNil(idscheda) && !_.isNil(motore) && !_.isNil(command) && !_.isNil(value)) {
			let PMOTORERC = 0
			if(motore === 'R')  PMOTORERC = 1
			if(motore === 'L')  PMOTORERC = 3
			if(motore === 'L3') PMOTORERC = 3
			if(motore === 'L2') PMOTORERC = 2

			const data = {
				"PIDCOMMAND": idcommand,
				"PIDSCHEDA": idscheda,
				"PMOTORERC": PMOTORERC,
				"PCOMMAND": command,
				"PVALUE": value
			}

			return Http.post(...util.getParams('action/WSMotorRemoteFirstAid', data)).then(res => {
				if(res && res.status === 200) {
					return {idcommand:idcommand, ok:true, labelcommand:`(${motore}) ${command}: ${value}`}
				} else {
					return {idcommand:null, ok:false, labelcommand:null}
				}
				return res
			}, res => {
				throw new Error('WSMotorRemoteFirstAid: ' + JSON.stringify(res));
			})
		} else {
			return Promise.reject(new Error('WSMotorRemoteFirstAid: id, motor, command and value are required'))
		}
	}*/
}
