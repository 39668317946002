import _ from 'lodash'
import moment from 'moment-timezone'
import CryptoJS from 'crypto-js'
const config = process.env

export default {
	getUrl(endpoint) {
		return `${config.VUE_APP_API_URL}/api/v${config.VUE_APP_API_VERSION}/${endpoint}`
	},
	getRandomId() {
		let rand = CryptoJS.lib.WordArray.random(16).toString() + moment().format('x')

		return CryptoJS.MD5(rand).toString() || ''
	},
	MD5(text) {
		return CryptoJS.MD5(text.toString()).toString() || ''
	}
}
