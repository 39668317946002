import { Http } from "vue-resource"
import usafe from "undefsafe"
import utils from "../../utils"

export default {
	login(email, password) {
		return Http.post(utils.getUrl("login"), {
			username: email,
			password: utils.MD5(password)
		}) /* 
		.then(res => {
			if (res && res.status === 200 && res.body && res.body.success && res.body.success === 'ok') {
				return { 'ok': true }
			} else {
				let error = ''
				if(usafe(res, 'body.error')) error = res.body.error

				return { 'ok': false, error }
			}
		}, res => {
			throw new Error('login: ' + JSON.stringify(res));
		})
		*/
	},
	verify(id) {
		return Http.get(utils.getUrl(`verify/${id || ""}`))
	},
	registration(
		email,
		password,
		firstName,
		lastName,
		gender,
		city,
		state,
		age,
		latitude,
		longitude,
		privacy_level_1,
		privacy_level_2,
		privacy_level_3
	) {
		return Http.post(utils.getUrl("registration"), {
			email,
			password: utils.MD5(password),
			firstName,
			lastName,
			gender,
			city,
			state,
			age,
			latitude,
			longitude,
			privacy_level_1,
			privacy_level_2,
			privacy_level_3,
			app_type: "web"
		})
	},
	userinfo(type) {
		return Http.get(utils.getUrl(`userinfo/${type || ""}`))
	},
	edituser({ firstName, lastName, gender, city, state, age, privacy_level_1, privacy_level_2, privacy_level_3 }) {
		return Http.post(utils.getUrl("edituser"), {
			firstName,
			lastName,
			gender,
			city,
			state,
			age,
			privacy_level_1,
			privacy_level_2,
			privacy_level_3
		})
	},
	changePassword(old_password, new_password) {
		return Http.post(utils.getUrl("change-password"), { old_password: utils.MD5(old_password), new_password: utils.MD5(new_password) })
	},
	updateFilters({ filters }) {
		return Http.post(utils.getUrl("update-settings"), { filters })
	},
	updateLanguage({ language }) {
		return Http.post(utils.getUrl("update-settings"), { language })
	},
	passwordRecovery(email) {
		return Http.post(utils.getUrl("password-recovery"), { email })
	},
	saveInteraction(action, { language, filters, points, latitude, longitude }) {
		return Http.post(utils.getUrl("save-interaction"), { action, language, filters, points, latitude, longitude })
	}
}
