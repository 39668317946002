import { Http } from 'vue-resource'
import usafe from 'undefsafe'
import _ from 'lodash'
import moment from 'moment-timezone'
import utils from '../../utils'

Http.options.credentials = true
Http.options.xhr = {withCredentials: true}

export default {
	getAll({id}) {
		if(id) 	
			return Http.get(`${utils.getUrl('events')}/${id}`)
		
		return Http.get(`${utils.getUrl('events')}`)
	},	
}
